import { IconProps } from '..';

export const IconNewFile = ({ width = 14, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 9.39571e-07C1.10218 9.39571e-07 0.720644 0.158036 0.43934 0.439341C0.158035 0.720645 0 1.10218 0 1.5V14.5C0 14.8978 0.158035 15.2794 0.43934 15.5607C0.720644 15.842 1.10218 16 1.5 16H12.5C12.8978 16 13.2794 15.842 13.5607 15.5607C13.842 15.2794 14 14.8978 14 14.5V5.621C13.9997 5.22331 13.8414 4.84204 13.56 4.561L9.44 0.439001C9.30052 0.299615 9.13494 0.189096 8.95271 0.113768C8.77048 0.0384399 8.57518 -0.000219753 8.378 9.39571e-07H1.5ZM7 6C7.19891 6 7.38968 6.07902 7.53033 6.21967C7.67098 6.36032 7.75 6.55109 7.75 6.75V8.25H9.25C9.44891 8.25 9.63968 8.32902 9.78033 8.46967C9.92098 8.61032 10 8.80109 10 9C10 9.19891 9.92098 9.38968 9.78033 9.53033C9.63968 9.67098 9.44891 9.75 9.25 9.75H7.75V11.25C7.75 11.4489 7.67098 11.6397 7.53033 11.7803C7.38968 11.921 7.19891 12 7 12C6.80109 12 6.61032 11.921 6.46967 11.7803C6.32902 11.6397 6.25 11.4489 6.25 11.25V9.75H4.75C4.55109 9.75 4.36032 9.67098 4.21967 9.53033C4.07902 9.38968 4 9.19891 4 9C4 8.80109 4.07902 8.61032 4.21967 8.46967C4.36032 8.32902 4.55109 8.25 4.75 8.25H6.25V6.75C6.25 6.55109 6.32902 6.36032 6.46967 6.21967C6.61032 6.07902 6.80109 6 7 6Z'
        fill={color}
      />
    </svg>
  );
};
