import { IconProps } from '..';

export const IconHome = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.64667 2.56083C7.74042 2.4672 7.8675 2.41461 8 2.41461C8.1325 2.41461 8.25958 2.4672 8.35334 2.56083L14.1467 8.35417C14.1928 8.4019 14.248 8.43997 14.309 8.46614C14.37 8.49232 14.4356 8.50608 14.502 8.50662C14.5684 8.50717 14.6343 8.49449 14.6957 8.46932C14.7571 8.44415 14.8129 8.407 14.8599 8.36003C14.9068 8.31306 14.9439 8.25722 14.969 8.19576C14.9941 8.1343 15.0067 8.06845 15.0061 8.00206C15.0055 7.93567 14.9917 7.87007 14.9655 7.80908C14.9392 7.74809 14.9011 7.69294 14.8533 7.64683L9.06067 1.8535C8.92138 1.71421 8.75602 1.60372 8.57403 1.52834C8.39204 1.45295 8.19699 1.41415 8 1.41415C7.80302 1.41415 7.60796 1.45295 7.42597 1.52834C7.24398 1.60372 7.07862 1.71421 6.93934 1.8535L1.146 7.64683C1.09827 7.69298 1.06021 7.74817 1.03403 7.80919C1.00785 7.8702 0.994093 7.93582 0.993547 8.0022C0.993002 8.06859 1.00568 8.13443 1.03085 8.19586C1.05602 8.2573 1.09318 8.31311 1.14014 8.36003C1.18711 8.40696 1.24296 8.44406 1.30441 8.46917C1.36587 8.49428 1.43172 8.5069 1.49811 8.50629C1.5645 8.50568 1.6301 8.49186 1.69109 8.46563C1.75208 8.43939 1.80723 8.40128 1.85334 8.3535L7.64667 2.56083Z'
        fill={color}
      />
      <path
        d='M7.99939 3.62189L13.4387 9.06122C13.4587 9.08122 13.4787 9.09989 13.4994 9.11855V13.2506C13.4994 13.9406 12.9394 14.5006 12.2494 14.5006H9.99939C9.86678 14.5006 9.7396 14.4479 9.64584 14.3541C9.55207 14.2603 9.49939 14.1332 9.49939 14.0006V11.0006C9.49939 10.8679 9.44671 10.7408 9.35294 10.647C9.25917 10.5532 9.132 10.5006 8.99939 10.5006H6.99939C6.86678 10.5006 6.7396 10.5532 6.64584 10.647C6.55207 10.7408 6.49939 10.8679 6.49939 11.0006V14.0006C6.49939 14.1332 6.44671 14.2603 6.35294 14.3541C6.25917 14.4479 6.132 14.5006 5.99939 14.5006H3.74939C3.41787 14.5006 3.09993 14.3689 2.86551 14.1344C2.63109 13.9 2.49939 13.5821 2.49939 13.2506V9.11855C2.52013 9.1 2.54036 9.08088 2.56006 9.06122L7.99939 3.62189Z'
        fill={color}
      />
    </svg>
  );
};
