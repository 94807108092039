import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimageScissors1 from './assets/IBimageScissors1.png';
import IBimageScissors2 from './assets/IBimageScissors2.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/Swiper';

// Styles
import styles from './ModalContent.module.css';

export const ScissorsModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageScissors1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  Directly after toe off the rear leg’s knee and hip bend, while at the same time the hip and knee of
                  the frontal leg will extend. These movements are reflexively linked to each other and form the overall
                  pattern called <i>scissors</i>: a combination of the stumble reflex (swing-leg recovery) and crossed
                  extensor reflex (swing leg retraction plus subsequent propulsion phase). In other words: flexion of
                  knee and hip from one leg leads to extension of hip and knee of the other and vice versa.
                  <br></br>
                  <br></br>
                  Scissors is qualified as good when swing leg recovery is fast enough when both knees are at least next
                  to each other at midstance.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-justify-content-center ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageScissors2} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  If the body is forced to respond to peak counteracting forces while the swing leg is still trailing,
                  this should be considered as a severe mistake; shock absorption will be compromised, and much energy
                  released throughout loading response will be lost.
                  <br></br>
                  <br></br>
                  If there is no swing-leg retraction, the leg will be pointing forward at foot plant (also see:
                  projection foot plant at midstance). Once such a foot plant in front of the hip starts to become a
                  habit, there is no longer any need to bring the rear leg rapidly forwards. The rear is only brought
                  forwards rapidly if there is enough tension in the hip flexors. If this is no longer needed, flexors
                  are tensed by keeping the hip somewhat back.
                  <br></br>
                  <br></br>
                  This leads to loss of tension in the whole system, leading to less control, loss of performance and
                  increased risk of injury.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
