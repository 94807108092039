// Styles
import styles from './BulletList.module.css';

// Utils
import { IonText } from '@ionic/react';

// Types

interface BulletListProps {
  listItems: string[];
}

export const BulletList = ({ listItems }: BulletListProps) => (
  <ul className={styles.bulletList}>
    {listItems.map((item, index) => (
      <li key={index}>
        <IonText className={styles.bulletListItem}>{item}</IonText>
      </li>
    ))}
  </ul>
);
