import { IonCol, IonImg, IonList, IonRow } from '@ionic/react';

// Context
import { useAuthContext } from '../authContext';
import { useUserInfoContext } from '../userInfoContext';

// Components
import { Input } from '../Input';

// Styles
import styles from './ProfileModalContent.module.css';

interface ProfileModalProps {
  cleanUp?: () => void;
}

export const ProfileModalContent = ({ cleanUp }: ProfileModalProps) => {
  const user = useAuthContext().state.userState?.user;
  const { userInfo } = useUserInfoContext();

  const imgURL = new URL(`../../assets/clientProfileLogos/${userInfo.organization.logoURL}.png`, import.meta.url).href;

  return (
    <div className={styles.wrapper}>
      <IonRow className='ion-align-items-center ion-justify-content-center'>
        <IonCol size='6' className='ion-justify-content-center ion-text-center '>
          <IonImg
            className=''
            src={imgURL}
            alt='Organization Logo'
            style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
          ></IonImg>
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center ion-justify-content-center' style={{ width: '100%' }}>
        <IonCol size='12' className='ion-margin-top'>
          <IonList mode='md'>
            <Input
              readonly={true}
              label='Name'
              labelPlacement='stacked'
              value={`${userInfo.firstName} ${userInfo.lastName}`}
            ></Input>
            <br />
            <Input label='Email' label-placement='stacked' readonly={true} value={user!.email!}></Input>
            <br />
            <Input
              label='Organization'
              label-placement='stacked'
              readonly={true}
              value={userInfo.organization.org_name!}
            ></Input>
          </IonList>
        </IonCol>
      </IonRow>
    </div>
  );
};
