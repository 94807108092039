import { EuclideanGraphAnnotations } from '../../../model/reportV2.model';

/**
 * Creates annotations for kinematic graphs.
 *
 * @param annotations_Left - An array of annotations for the left graph.
 * @param annotations_Right - An array of annotations for the right graph.
 * @returns An array of graph annotations or undefined.
 */
export function createEuclideanGraphAnnotations(
  annotations: any,
  color: any,
  highlights: { x: any; x2: any },
): EuclideanGraphAnnotations[] {
  return [
    {
      x: annotations[0] - 1,
      borderColor: color[0],
      label: {
        text: 'Hip',
        borderColor: 'transparent',
        position: 'top',
        orientation: 'horizontal',
        offsetY: -10,
        style: {
          background: 'transparent',
          fontSize: '8px',
          fontFamily: 'Montserrat',
          color: color[0],
        },
      },
    },
    {
      x: annotations[1] - 1,
      borderColor: color[1],
      label: {
        text: 'Knee',
        borderColor: 'transparent',
        position: 'top',
        orientation: 'horizontal',
        offsetY: 0,
        style: {
          background: 'transparent',
          fontSize: '8px',
          fontFamily: 'Montserrat',
          color: color[1],
        },
      },
    },
    {
      x: annotations[2] - 1,
      borderColor: color[2],
      label: {
        text: 'Ankle',
        borderColor: 'transparent',
        position: 'top',
        orientation: 'horizontal',
        offsetY: 10,
        style: {
          background: 'transparent',
          fontSize: '8px',
          fontFamily: 'Montserrat',
          color: color[2],
        },
      },
    },
    {
      x: highlights.x - 1,
      x2: highlights.x2,
      borderColor: '#c2c2c2',
      fillColor: '#ABF263',
    },
  ];
}
