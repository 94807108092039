import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimagepositiverunningmidstance1 from './assets/IBimagepositiverunningmidstance1.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/Swiper';

// Styles
import styles from './ModalContent.module.css';

export const PositiveRunningMidstanceModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagepositiverunningmidstance1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  Together will multiple smaller attractors, the scissors contribute to an overall stable pattern called{' '}
                  <i>Positive Running at midstance</i>.<br></br>
                  <br></br>
                  When running at speed, positive running pose is shown as the bisector of both thigh angles. The line
                  that divides the angle into two equal segments should point markedly forward (positive) if underlying
                  attractors are performed well. For each runner there is an ideal direction for this angle.
                  <br></br>
                  <br></br>
                  If technique changes due to fatigue or physical issues, the direction of the bisector will point more
                  downwards (less positive) resulting in decrease in performance.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
