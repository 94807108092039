import { IonFab, IonFabButton, IonFabList } from '@ionic/react';
import { PropsWithChildren } from 'react';
import type { JSX as IonicJSX } from '@ionic/core';
import { IconRotate, IconSize } from '../Icon/types';
import { Icon, IconName } from '../Icon';

type IonFloatinButtonProps = IonicJSX.IonFab;

// FloatingButton is styled via /theme/FloatingButton.css
interface FloatingButtonProps extends IonFloatinButtonProps {
  children: React.ReactNode;
  size?: 'small' | 'default' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';
  buttonIcon?: IconName;
  buttonIconDirection?: IconRotate;
  slot:
    | 'fixed'
    | 'fixed-bottom'
    | 'fixed-top'
    | 'fixed-start'
    | 'fixed-end'
    | 'floating'
    | 'floating-bottom'
    | 'floating-top'
    | 'floating-start'
    | 'floating-end'
    | 'center'
    | 'center-bottom'
    | 'center-top'
    | 'center-start'
    | 'center-end';
  horizontal: 'start' | 'end' | 'center';
  vertical: 'top' | 'bottom' | 'center';
  side: 'start' | 'end' | 'top' | 'bottom';
  iconSize?: IconSize;
}

export const FloatingButton = ({
  children,
  size = 'small',
  color = 'primary',
  buttonIcon = 'sliders',
  side = 'top',
  buttonIconDirection,
  iconSize = IconSize.medium,
  ...props
}: PropsWithChildren<FloatingButtonProps>) => {
  const iconSizesSwitch = {
    small: IconSize.extraSmall,
    medium: IconSize.medium,
    large: IconSize.large,
  };
  return (
    <IonFab slot={props.slot} horizontal={props.horizontal} vertical={props.vertical}>
      <IonFabButton size={'small'} color={color}>
        {buttonIcon && <Icon name={buttonIcon} direction={buttonIconDirection} size={iconSize} />}
        {/* <IonIcon icon={sliders} size='small' /> */}
      </IonFabButton>
      <IonFabList side={side} className=''>
        {children}
      </IonFabList>
    </IonFab>
  );
};
