/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2024
 * ==================================================================
 *
 * ORYX Test page.
 */

import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonText, useIonAlert, IonAlert } from '@ionic/react';
import { ORYXHeader } from '../../../components/menuItems/Header';
import './ORYX.css';

import { useAuthContext } from '../../../components/authContext';
import { Button } from '../../../components/Button';
import { useEffect, useState } from 'react';
import { MappableSegment, OrientationMap } from './context/socket';
import { ScannedSensor, Sensor } from './components/Sensor';

type MeasurementState =
  | 'scanningAndConnecting'
  | 'Placement'
  | 'CalibrationIdle'
  | 'CalibrationRunning'
  | 'CalibrationSuccess'
  | 'Measuring';

type Assignment = {
  macAddress: string;
  segment: MappableSegment;
};

type KinematicModel =
  | 'ORYXGO'
  | 'Pitcher_RightHanded'
  | 'Pitcher_LeftHanded'
  | 'FullBody'
  | 'KneeStability'
  | undefined;

export const ORYXTestPage = () => {
  const userId = useAuthContext().state.userState?.user.uid;
  const backTarget = history.state.state?.from;
  const [measurementState, setMeasurementState] = useState<MeasurementState>('scanningAndConnecting');
  const [sensorMap, setSensorMap] = useState<OrientationMap>({});
  const [kinematicModel, setKinematicModel] = useState<KinematicModel>('ORYXGO');
  const [showKMAlert, setShowKMAlert] = useState(false);
  const KinematicModels: KinematicModel[] = [
    'ORYXGO',
    'Pitcher_RightHanded',
    'Pitcher_LeftHanded',
    'FullBody',
    'KneeStability',
  ];
  const [presentAlert] = useIonAlert();

  //  Allowed Sensors
  // TODO Get this from the Database
  const allowedDevices = ['FE:82:64:29:48:E9', 'ED:9B:08:6A:C2:C7', 'FB:8F:72:1D:63:9B'];

  // Sensor Assignments
  // TODO Get this from the Database
  const assignments: Assignment[] = [
    {
      macAddress: 'FE:82:64:29:48:E9',
      segment: 'right pelvis',
    },
    {
      macAddress: 'ED:9B:08:6A:C2:C7',
      segment: 'left pelvis',
    },
    {
      macAddress: 'FB:8F:72:1D:63:9B',
      segment: 'right pelvis',
    },
  ];

  useEffect(() => {
    if (kinematicModel === undefined) {
      setShowKMAlert(true);
    }
  }, [kinematicModel, presentAlert]);

  return (
    <>
      <IonAlert
        isOpen={showKMAlert}
        onDidDismiss={() => setShowKMAlert(false)}
        header={'Choose a Kinematic Model'}
        message={'Choose a Kinematic Model'}
        buttons={[
          {
            text: 'Confirm',
            role: 'confirm',
            handler: (selectedValue: KinematicModel) => {
              console.log('Selected model:', selectedValue);
              setKinematicModel(selectedValue);
            },
          },
        ]}
        inputs={KinematicModels.map((model) => ({
          label: model,
          type: 'radio' as const,
          value: model,
        }))}
      />

      <IonPage id='main'>
        <ORYXHeader backTarget={backTarget} />
        <IonContent className='ion-padding' id='main' color='light'>
          <IonGrid className='Test full_height'>
            <IonRow className='full_height'>
              <IonCol className='ion-no-padding' style={{ width: '20%', borderRight: '1px solid #2E2E2E' }}>
                <IonRow style={{ height: '50%', borderBottom: '1px solid grey', paddingBottom: '32px' }} className=''>
                  <IonCol size='12' className=''>
                    <IonText> Sensor Hub Connected</IonText>
                  </IonCol>
                  <IonCol size='12' className=''>
                    <Button>Connect Sensors</Button>
                  </IonCol>
                  <IonCol size='12' className=''>
                    <Button disabled>Reset Orientation</Button>
                  </IonCol>
                  <IonCol size='12' className=''>
                    <Button disabled>Place Sensors</Button>
                  </IonCol>
                  <IonCol size='12' className=''>
                    <Button disabled>Calibrate</Button>
                  </IonCol>
                  <IonCol size='12' className=''>
                    <Button>Collect</Button>
                  </IonCol>
                </IonRow>
              </IonCol>
              {/* Depending on the state of the measurement, show the different content */}
              <IonCol size='8' className=''>
                <IonRow
                  style={{ height: '90%', borderBottom: '1px solid grey', paddingBottom: '32px' }}
                  className='Test'
                >
                  <IonCol size={measurementState === 'scanningAndConnecting' ? '6' : '4'} className='Test'>
                    {Object.entries(sensorMap).map(([_, v]) => {
                      if (v.mappedSegment.includes('left') && !v.connected) {
                        return (
                          <div
                            key={v.mappedSegment}
                            style={{
                              border: '1px solid grey',
                              padding: '10px',
                              borderRadius: '5px',
                              marginBottom: '10px',
                            }}
                          >
                            <ScannedSensor mappedSegment={v.mappedSegment} />
                          </div>
                        );
                      }
                      return (
                        <div
                          style={{
                            border: '1px solid grey',
                            padding: '10px',
                            borderRadius: '5px',
                            marginBottom: '10px',
                          }}
                        >
                          <Sensor
                            mappedSegment={'right pelvis'}
                            sensorOrientation={0}
                            batteryLevel={0}
                            charging={false}
                            key={'right pelvis'}
                          />
                        </div>
                      );
                    })}
                  </IonCol>
                  <IonCol
                    size={measurementState === 'scanningAndConnecting' ? '6' : '4'}
                    className={measurementState !== 'scanningAndConnecting' ? 'Test' : 'ion-hide Test'}
                  >
                    AvatarCol
                  </IonCol>
                  <IonCol size={measurementState === 'scanningAndConnecting' ? '6' : '4'} className='Test'>
                    <div
                      style={{ border: '1px solid grey', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}
                    >
                      <p style={{ margin: 0 }}>Right Pelvis</p>
                      <Sensor
                        mappedSegment={'right pelvis'}
                        sensorOrientation={0}
                        batteryLevel={75}
                        charging={false}
                        key={'right pelvis'}
                      />
                    </div>{' '}
                    <div
                      style={{ border: '1px solid grey', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}
                    >
                      <p style={{ margin: 0 }}>Right Thigh</p>
                      <ScannedSensor mappedSegment={'right thigh'} />
                    </div>{' '}
                    <div
                      style={{ border: '1px solid grey', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}
                    >
                      <p style={{ margin: 0 }}>Right Calf</p>

                      <ScannedSensor mappedSegment={'right calf'} />
                    </div>{' '}
                    <div style={{ border: '1px solid grey', padding: '10px', borderRadius: '5px' }}>
                      <p style={{ margin: 0 }}>Right Foot</p>

                      <ScannedSensor mappedSegment={'right foot'} />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-justify-content-end'>
                  <Button>Continue</Button>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        {/* <ErrorToast message={isError ? isError.message : ''} clearError={() => clearError()} /> */}
      </IonPage>
    </>
  );
};
