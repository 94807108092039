import { AreasOfConcern, ReportType, Version } from './report.model';

export type ReportInfoId = string;

export enum ReportStatus {
  ERROR = 'error',
  PENDING = 'pending',
  SUCCESS = 'success',
  DELETED = 'deleted',
}

/** ReportInfo struct holding meta information on the file, processing, and report
 * This is combined from the KinematicsFiles and ReportInfo database collections.
 */
export type ReportInfo = {
  areasOfConcern: AreasOfConcern;
  coordinativeVariability: string;
  coordinativeVariabilityPlanes: string;
  coordinativeVariabilityStance: string | undefined; // only run reports
  coordinativeVariabilitySquat: string | undefined; // only squat reports
  crpAnkleLeft: string;
  crpAnkleRigh: string;
  crpHipLeft: string;
  crpHipRight: string;
  crpKneeLeft: string;
  crpKneeRight: string;
  crpMean: string;
  fileId: string;
  filename: string;
  id: ReportInfoId | undefined;
  measurementType?: string; // ORYX Movement Analysis or?
  reportId: string | undefined;
  reportName: string;
  reportType: ReportType;
  status: ReportStatus;
  stridesCoronalLeft: string;
  stridesCoronalRight: string;
  stridesLeft: string;
  stridesRight: string;
  stridesTransversalLeft: string;
  stridesTransversalRight: string;
  tags: string[];
  uploadTimestamp: Date;
  version: Version;
  _processingVersion: string;
  errorMessage: string | undefined;
};

/** Dictionary of ReportInfo structs, indexed by ReportInfoId */
export type ReportInfoDict = {
  [id in ReportInfoId]: ReportInfo;
};
