import React from 'react';
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import IBimageMobilityKnee from './assets/IBimageMobilityKnee.png';

// Slides
import { SwiperSlide } from 'swiper/react';

// Styles
import styles from './ModalContent.module.css';
import { VerticalSwiper } from '../../../../components/Swiper';

export const KneeMobilityStatusModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='4' size-xl='4' className=''>
                <div>
                  <IonImg src={IBimageMobilityKnee} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='7' size-xl='7' className=''>
                <div>
                  When the knee is bend, is can conduct other movements than only flexion and extension. The lower leg
                  can rotate both medial and lateral, causing rotations within the knee (max 30&deg; medial and 45&deg;
                  lateral rotation when the knee is bend 90&deg;). When extending the knee, these movements will be
                  restricted; due to the architecture of the femoral epicondyle the ligaments will be tensioned,
                  fixating the knee joint in extended position.
                  <br></br>
                  <br></br>
                  In the swing phase, the knee should bend 60&deg; or more (see also: knee flexion midswing). In stance
                  phase the should move within a bandwidth of 12-27&deg; (see also: knee flexion midstance).
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
