import { IonCardContent, IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import { useState } from 'react';

// Components
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../../components/CardHeader/cardHeader';
import { ReportModal } from '../../../../components/Modal/ReportModal';
import { StatusDotGroup } from '../Fields/Fields';

// Styles
import styles from './Cards.module.css';

type AttractorCardProps = {
  title: string;
  info: React.ReactNode;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
export const MobilityStatusCard = ({ title, info, img, romRow, statusRow }: AttractorCardProps) => {
  const [showModal, setShowModal] = useState({ show: false });

  return (
    <>
      <ReportModal
        isOpen={showModal.show}
        onDidDismiss={() => setShowModal({ show: false })}
        header={`${title} Mobility`}
        setShowInfo={() => setShowModal({ show: false })}
      >
        {info}
      </ReportModal>

      <Card style={{ height: 'fit-content', margin: 0 }}>
        <CardHeader title={title} info={true} setShowInfo={setShowModal} />
        <IonCardContent className={styles.cardContent}>
          <IonGrid class='ion-no-padding' fixed>
            <IonRow className='full_width ion-justify-content-center'>
              <IonCol>
                <IonImg src={img} class={styles.avatar} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ marginRight: '4px' }}>
                <StatusDotGroup name='Left' value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
              </IonCol>
              <span style={{ width: '1px', backgroundColor: '#e0e0e0' }} />
              <IonCol style={{ marginLeft: '4px' }}>
                <StatusDotGroup name='Right' value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </Card>
    </>
  );
};
