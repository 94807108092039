import React from 'react';
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import IBimageMobilityAnkle from './assets/IBimageMobilityAnkle.png';

// Slides
import { SwiperSlide } from 'swiper/react';

// Styles
import styles from './ModalContent.module.css';
import { VerticalSwiper } from '../../../../components/Swiper';

export const AnkleMobilityStatusModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='4' size-xl='4' className=''>
                <div>
                  <IonImg src={IBimageMobilityAnkle} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='7' size-xl='7' className=''>
                <div>
                  The ankle (or tibiotalar joint) constitutes the junction of the lower leg and foot, which can mainly
                  be distinguished in the talocrural joint and subtalar joint.
                  <br></br>
                  <br></br>
                  The talocrural can not only hinge, but also roll and slide. The pivot point is therefor not fixed but
                  is approximately at the height of the malleolus. It enables dorsal and plantar flexion. In gait the
                  ankle reaches typically 10-15&deg; of dorsal flexion and 15-20&deg; of plantar flexion.
                  <br></br>
                  <br></br>
                  The subtalar joint (also talocalcaneal joint) allows inversion and eversion of the foot. In gait the
                  ROM of this motion is typically 15&deg;.
                  <br></br>
                  <br></br>
                  Due to the complex architecture of the foot, it is very difficult to measure inversion and eversion
                  adequately. The shape of the foot can alter due to pronation and supination, which influences the
                  measurement of the ankle slightly. No current technology can separate subtalar and tibiotalar
                  movement. Therefore, analysis of eversion and inversion should be treated very carefully.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
