import React from 'react';
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import IBimageMobilityPelvis from './assets/IBimageMobilityPelvis.png';

// Slides
import { SwiperSlide } from 'swiper/react';

// Styles
import styles from './ModalContent.module.css';
import { VerticalSwiper } from '../../../../components/Swiper';

export const PelvisMobilityStatusModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='3' size-xl='3' className=''>
                <div>
                  <IonImg src={IBimageMobilityPelvis} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='8' size-xl='8' className=''>
                <div>
                  Because many muscles are attached to the pelvis, which can produce force in numerous directions, it
                  works as a giant lever. Muscles can affect greatly each other by causing subtle pelvic movements. In
                  other words, small changes can result in large differences elsewhere. From that perspective the pelvic
                  area might be the most important link within the chain. Therefor, its movement should be analyzed and
                  trained with great attention.
                  <br></br>
                  <br></br>
                  In the sagittal plane, the pelvis is typically maintained in anterior pelvic tilt throughout the full
                  gait cycle. The total excursion of this movement is relatively small, approximately 5–7 degrees. To
                  much ROM (typically 10&deg; or more) indicates poor pelvic stability, typically due to poor abdominal
                  and or hamstring strength. A low ROM ( &lt; 4&deg;) is typically caused by (or a combination of) poor
                  mobility, tight hamstrings, weak back muscles. People showing a low pelvic ROM often report lower back
                  pains.
                  <br></br>
                  <br></br>
                  In the frontal plane, ROM is between 6 – 10&deg; at self selected speed. As frontal plane movements
                  are fluctuations for slight movement adjustments, ROM should not be too low. Too much ROM indicates
                  stability issues (also see pelvic obliquity and hip abduction).
                  <br></br>
                  <br></br>
                  In transverse plane, anterior and posterior rotation should not be much more than 4&deg;, limiting the
                  combined ROM to approximately 8 – 10&deg;.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
