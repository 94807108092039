import {
  IonCardHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import ORYX_MagnifyingGlassIcon from '../../assets/icons/ORYX_MagnifyingGlassIcon.svg';

import styles from './cardHeader.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface ORYXCardHeaderProps {
  title?: string;
  subtitle?: string;
  info?: boolean;
  zoom?: boolean;
  setShowInfo?: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
    }>
  >;
  setShowZoom?: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
    }>
  >;
  children?: React.ReactNode;
}

export const ORYXCardHeader = ({
  title,
  subtitle,
  info,
  setShowInfo,
  zoom,
  setShowZoom,
  children,
}: ORYXCardHeaderProps) => {
  return (
    <IonCardHeader className={styles.cardHeader}>
      <IonGrid fixed className='ion-no-padding'>
        <IonRow className={mergeClassNames('ion-align-items-center', styles['header'])}>
          <IonCol size='9' className={styles['content']}>
            <IonCardTitle className={styles.title}>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className={styles.subTitle}>{subtitle}</IonCardSubtitle>}
          </IonCol>

          <IonCol size='3' className='ion-justify-content-center ion-text-end'>
            {zoom ||
              (info && (
                <IonButtons className='ion-justify-content-end'>
                  {zoom && setShowZoom && (
                    <IonButton
                      slot='icon-only'
                      fill='clear'
                      className='attractorCard_Header_Buttons'
                      onClick={() => setShowZoom({ show: true })}
                    >
                      <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                    </IonButton>
                  )}
                  {info && setShowInfo && (
                    <IonButton
                      slot='icon-only'
                      fill='clear'
                      className='attractorCard_Header_Buttons'
                      onClick={() => setShowInfo({ show: true })}
                    >
                      <IonIcon icon={informationCircleOutline} />
                    </IonButton>
                  )}
                </IonButtons>
              ))}
            {children}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardHeader>
  );
};
