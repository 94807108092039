import React from 'react';

import { View, Text, Image } from '@react-pdf/renderer';
import { PDFKinematicGraphSection } from './Components/PDFKinematicGraphSection';
import { PDFPage } from './Components/PDFPage';
import { PDFFormatting, PDFTextStyles } from './styles';

// Image Imports
import singleLegSquatAvatar from '../../assets/avatars/squat/singleLegSquatAvatar.png';
import { PDFSLSquatInfoTable } from './Components/PDFSLSquatInfoTable';
import { PDFVersion } from './PDFDocument';

export interface DocumentProps {
  reportInfo: any;
  data: any;
  version: PDFVersion;
}

export const PDFSLSquatReport = ({ reportInfo, data, version }: DocumentProps) => {
  const reportName = reportInfo.reportName;
  const logoUrl = data.logoURL;
  return (
    <>
      {/* MObility Status & Analysis Cards */}
      <PDFPage reportName={reportName} logoUrl={logoUrl} PageNumber={1}>
        <Image style={PDFFormatting.avatar} src={singleLegSquatAvatar} />
        <View style={PDFFormatting.contentContainer_65}>
          <View style={PDFFormatting.tableContainer}>
            <Text style={PDFTextStyles.containerTitle}>Info</Text>
            <PDFSLSquatInfoTable
              keyPerformanceIndicatorData={data.qualityData}
              romData={data.romData}
              labels={data.Labels}
              repetitionTimeData={data.repetitionData}
            />
          </View>
        </View>
      </PDFPage>

      {/* Kinematic Graphs */}
      {version === 'Specialist' && (
        <PDFPage reportName={reportName} logoUrl={logoUrl} PageNumber={2}>
          <PDFKinematicGraphSection
            title={'Pelvis'}
            sagittalGraph={data.pelvisKinematicSagittalChartDataUri}
            coronalGraph={data.pelvisKinematicCoronalChartDataUri}
            transversalGraph={data.pelvisKinematicTransversalChartDataUri}
          />
          <PDFKinematicGraphSection
            title={'Hip'}
            sagittalGraph={data.hipKinematicSagittalChartDataUri}
            coronalGraph={data.hipKinematicCoronalChartDataUri}
            transversalGraph={data.hipKinematicTransversalChartDataUri}
          />
          <PDFKinematicGraphSection
            title={'Knee'}
            sagittalGraph={data.kneeKinematicSagittalChartDataUri}
            coronalGraph={data.kneeKinematicCoronalChartDataUri}
            transversalGraph={data.kneeKinematicTransversalChartDataUri}
          />
          <PDFKinematicGraphSection
            title={'Ankle'}
            sagittalGraph={data.ankleKinematicSagittalChartDataUri}
            coronalGraph={data.ankleKinematicCoronalChartDataUri}
            transversalGraph={data.ankleKinematicTransversalChartDataUri}
          />
        </PDFPage>
      )}
    </>
  );
};
