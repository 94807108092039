import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import { KinematicYAxis, kinematicGraphAnnotations } from '../../../../model/reportV2.model';
import styles from './ApexCharts.module.css';
import { ReportModal } from '../../../../components/Modal/ReportModal';

type KinematicsGraphV2Props = {
  data: any;
  subtitle: string;
  graphAnnotations: kinematicGraphAnnotations;
  strokeWidth: number;
  forPdf?: boolean;
  yAxis: KinematicYAxis;
  title?: string;
};

export const KinematicsGraphV2 = React.forwardRef(
  (
    { data, subtitle, graphAnnotations, strokeWidth, forPdf = false, yAxis, title }: KinematicsGraphV2Props,
    ref: React.ForwardedRef<Chart>,
  ) => {
    const [loading, setLoading] = useState(!forPdf ? true : false);
    const [showModal, setShowModal] = useState({ show: false });
    const [showLegend, setShowLegend] = useState(false);

    const options: ApexOptions = {
      chart: {
        type: 'line',
        width: '100%',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        offsetX: -15,
      },
      xaxis: {
        type: 'numeric',
        labels: {
          show: false,
          style: {
            fontSize: '8px',
          },
        },
        axisTicks: {
          show: false,
        },
        // title: {
        //   text: 'Percentage of stride time',
        //   style: {
        //     fontSize: '10px',
        //     fontFamily: 'Montserrat',
        //     fontWeight: 300,
        //   },
        //   offsetY: 5,
        // },
      },
      yaxis: {
        show: true,
        min: yAxis.yMin,
        max: yAxis.yMax,
        tickAmount: yAxis.tickAmount,
        labels: {
          formatter: function (value: number) {
            return value.toFixed(0);
          },
          style: {
            fontSize: '8px',
          },
        },
        axisTicks: {
          show: true,
        },
      },
      grid: {
        show: true,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      subtitle: {
        text: subtitle,
        align: 'center',
        margin: 0,
        floating: true,
        offsetY: 0,

        style: {
          fontSize: '8px',
          fontFamily: 'Montserrat',
        },
      },
      stroke: {
        width: strokeWidth,
        dashArray: [0],
      },
      legend: {
        show: showLegend,
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 5,
        fontSize: '9px',
        markers: {
          width: 8,
          height: 8,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      annotations: {
        xaxis: graphAnnotations,
        yaxis: [
          {
            y: 0,
            borderColor: 'red',
          },
        ],
      },
      markers: {
        size: [0, 0, 2],
        strokeWidth: 0,
        showNullDataPoints: false,
      },
      tooltip: {
        enabled: false,
      },
    };

    useEffect(() => {
      if (!forPdf) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }, [data]);

    return (
      <>
        {!forPdf && title && (
          <ReportModal
            isOpen={showModal.show}
            onDidDismiss={() => {
              setShowModal({ show: false });
              setShowLegend(false);
            }}
            header={`${title} Movement`}
            setShowInfo={() => setShowModal({ show: false })}
          >
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' className=''>
                <Chart ref={ref} options={options} series={data} type='line' height='250%' />
              </IonCol>
            </IonRow>
          </ReportModal>
        )}

        <div
          className={styles.kinematicsGraph_Wrapper}
          onClick={() => {
            setShowModal({ show: true });
            setTimeout(() => {
              setShowLegend(true);
            }, 200);
          }}
        >
          {!loading ? (
            <Chart ref={ref} options={options} series={data} type='line' />
          ) : (
            <IonSpinner name='bubbles' color='primary' />
          )}
        </div>
      </>
    );
  },
);
