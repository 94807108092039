/** The Run Kinematics report tab */
import { IonCardContent, IonCol, IonRow, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';

// Components
import {
  JointCouplingGraph,
  ScissorsColumnChart,
  ScissorsGraphCard,
  StanceCoordinativeVariabilityGraph,
  StanceLineGraph,
  ThighBarGraph,
} from '../components/Graphs';
import { Card } from '../../../components/Card';
import { InfoToast } from '../../../components/InfoToast/InfoToast';

// Hooks & context
import { ScissorsReportData } from '../../../model/reportV2.model';

// Styles

type AttractorsReportProps = {
  tabData: ScissorsReportData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunScissorsReportTab = ({ tabData }: AttractorsReportProps) => {
  const [showInfoToast, setShowInfoToast] = useState(false);

  useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);
  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow className='ion-align-items-stretch ion-justify-content-center'>
          <IonCol size='4' className=''>
            <ScissorsGraphCard
              series={tabData.scissorsData_Left.graphData}
              title={'Scissors Left'}
              x={tabData.scissorsData_Left.firstMarker}
              x2={tabData.scissorsData_Left.secondMarker}
              colors={tabData.scissorsData_Left.colors}
            />
          </IonCol>
          <IonCol size='4' className=''>
            <ScissorsGraphCard
              series={tabData.scissorsData_Right.graphData}
              title={'Scissors Right'}
              x={tabData.scissorsData_Right.firstMarker}
              x2={tabData.scissorsData_Right.secondMarker}
              colors={tabData.scissorsData_Right.colors}
            />
          </IonCol>
          <IonCol size='4' className=''>
            <ScissorsColumnChart
              title={'Scissors velocity'}
              data={tabData.scissorsBarGraphData}
              yAxisLabel={tabData.hamstringData.yAxisLabelVelocity}
            />
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-stretch ion-justify-content-center'>
          <IonCol size='4' className=''>
            <JointCouplingGraph
              data={tabData.runJointCouplingData.seriesJointCoupling_Left}
              title={'Hip / Knee coupling Left'}
              zoomModalContent={tabData.runJointCouplingData.jointCouplingZoomModalContent}
              xMin={tabData.runJointCouplingData.jointCouplingAxisRange.xMinValue}
              xMax={tabData.runJointCouplingData.jointCouplingAxisRange.xMaxValue}
              xRange={tabData.runJointCouplingData.jointCouplingAxisRange.xTickAmount}
            />
          </IonCol>
          <IonCol size='4' className=''>
            <JointCouplingGraph
              data={tabData.runJointCouplingData.seriesJointCoupling_Right}
              title={'Hip / Knee coupling Right'}
              zoomModalContent={tabData.runJointCouplingData.jointCouplingZoomModalContent}
              xMin={tabData.runJointCouplingData.jointCouplingAxisRange.xMinValue}
              xMax={tabData.runJointCouplingData.jointCouplingAxisRange.xMaxValue}
              xRange={tabData.runJointCouplingData.jointCouplingAxisRange.xTickAmount}
            />
          </IonCol>
          <IonCol size='4' className=''>
            <ThighBarGraph
              data={tabData.hamstringData.thighVelocityBarData}
              title={'Hamstring propulsion - Velocity'}
              xAxisCategories={['Peak TSw', 'AV. TSw', 'Peak TSt', 'Av. Stance']}
              stdData={tabData.hamstringData.thighVelocityBarData_std}
              yAxisLabel={tabData.hamstringData.yAxisLabelVelocity}
            />
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-stretch ion-justify-content-center'>
          <IonCol size='4' className=''>
            <StanceCoordinativeVariabilityGraph
              data={tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.series}
              title={'Coordinative Variability Hip Left'}
              subtitle={'Pelvis / Thigh (s/s & c/t)'}
              ICAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.ICAnnotation_Left
              }
              MStAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.MStAnnotation_Left
              }
              HOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.HOAnnotation_Left
              }
              TOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.TOAnnotation_Left
              }
            />
          </IonCol>
          <IonCol size='4' className=''>
            <StanceCoordinativeVariabilityGraph
              data={tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.series}
              title={'Coordinative Variability Hip Right'}
              subtitle={'Pelvis / Thigh (s/s & c/t)'}
              ICAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.ICAnnotation_Right
              }
              MStAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.MStAnnotation_Right
              }
              HOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.HOAnnotation_Right
              }
              TOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.TOAnnotation_Right
              }
            />
          </IonCol>
          <IonCol size='4' className=''>
            <ThighBarGraph
              data={tabData.hamstringData.thighAccelerationBarData}
              title={'Hamstring propulsion - Acceleration'}
              xAxisCategories={['TSw', 'Stance']}
              stdData={tabData.hamstringData.thighAccelerationBarData_std}
              yAxisLabel={tabData.hamstringData.yAxisLabelAcceleration}
            />
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-stretch ion-justify-content-center ion-padding-bottom'>
          <IonCol size='4' className=''>
            <StanceLineGraph
              series={tabData.swingLegRetractionData.thighAccelerationSeriesData_Left}
              title={'Swing Leg Retraction Left'}
              subtitle={'Propulsion in stance - Acceleration'}
              ICAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.ICAnnotation_Left
              }
              MStAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.MStAnnotation_Left
              }
              HOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.HOAnnotation_Left
              }
              TOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Left.annotations.TOAnnotation_Left
              }
              yAxisMin={tabData.swingLegRetractionData.yMinValue}
              yAxisMax={tabData.swingLegRetractionData.yMaxValue}
              yAxisLabel={tabData.hamstringData.yAxisLabelAcceleration}
            />
          </IonCol>
          <IonCol size='4' className=''>
            <StanceLineGraph
              series={tabData.swingLegRetractionData.thighAccelerationSeriesData_Right}
              title={'Swing Leg Retraction Right'}
              subtitle={'Propulsion in stance - Acceleration'}
              ICAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.ICAnnotation_Right
              }
              MStAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.MStAnnotation_Right
              }
              HOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.HOAnnotation_Right
              }
              TOAnnotation={
                tabData.coordinativeVariabilityData.coordinativeVariabilityData_Right.annotations.TOAnnotation_Right
              }
              yAxisMin={tabData.swingLegRetractionData.yMinValue}
              yAxisMax={tabData.swingLegRetractionData.yMaxValue}
              yAxisLabel={tabData.hamstringData.yAxisLabelAcceleration}
            />
          </IonCol>
          <IonCol size='4' className='' style={{ height: '100%' }}>
            <Card style={{ margin: 0 }}>
              <IonCardContent>
                <table className='ORYX_Table_Scroll'>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Left</th>
                      <th>Right</th>
                      <th>Symmetry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='ORYX_Table_Head'>
                      <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                        Scissors
                      </td>
                    </tr>
                    <tr>
                      <td>Average velocity</td>
                      <td>
                        {tabData.scissorsTableData.scissors.averageVelocity.left +
                          ' ' +
                          tabData.scissorsTableData.scissors.averageVelocity.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.scissors.averageVelocity.right +
                          ' ' +
                          tabData.scissorsTableData.scissors.averageVelocity.unit}
                      </td>
                      <td>{tabData.scissorsTableData.scissors.averageVelocity.symmetry + ' %'}</td>
                    </tr>
                    <tr className='ORYX_Table_Head'>
                      <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                        Average Velocities
                      </td>
                    </tr>
                    <tr className=''>
                      <td>Terminal Swing</td>
                      <td>
                        {tabData.scissorsTableData.averageVelocities.terminalSwing.left +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.averageVelocities.terminalSwing.right +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                      </td>
                      <td>{tabData.scissorsTableData.averageVelocities.terminalSwing.symmetry + ' %'}</td>
                    </tr>
                    <tr className=''>
                      <td>Stance</td>
                      <td>
                        {tabData.scissorsTableData.averageVelocities.stance.left +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.stance.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.averageVelocities.stance.right +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.stance.unit}
                      </td>
                      <td>{tabData.scissorsTableData.averageVelocities.stance.symmetry + ' %'}</td>
                    </tr>
                    <tr className='ORYX_Table_Head'>
                      <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                        Peak velocities and accelerations
                      </td>
                    </tr>
                    <tr className=''>
                      <td>Velocity Terminal Swing</td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.symmetry +
                          ' %'}
                      </td>
                    </tr>
                    <tr className=''>
                      <td> Velocity Terminal Stance</td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.symmetry + ' %'}
                      </td>
                    </tr>
                    <tr className=''>
                      <td>Acceleration Terminal Swing</td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.symmetry +
                          ' %'}
                      </td>
                    </tr>
                    <tr className=''>
                      <td>Acceleration Stance</td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                      </td>
                      <td>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.symmetry + ' %'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>
        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='The graphs can be enlarged by clicking / tapping on the graph.'
        />
      </>
    );
  }
};
