import { KinematicsGraph, ProjectionFootPlantGraph } from '../../../pages/ReportV2/components/Graphs';

interface RunReportChartProps {
  PDFDownloadData: any;
  ChartComponentRefs: any;
}

export const RunReportCharts = ({ PDFDownloadData, ChartComponentRefs: ChartComponentRefs }: RunReportChartProps) => {
  return (
    <div style={{ display: 'none' }}>
      <ProjectionFootPlantGraph
        data={PDFDownloadData.footplantAtInitialContactSeries_Left}
        subtitle='Initial Contact'
        forPdf={true}
        ref={ChartComponentRefs['footplantAtInitialContact_Left']}
      />
      <ProjectionFootPlantGraph
        data={PDFDownloadData.footplantAtMidStanceSeries_Left}
        subtitle='MidStance'
        forPdf={true}
        ref={ChartComponentRefs['footplantAtMidStance_Left']}
      />
      <ProjectionFootPlantGraph
        data={PDFDownloadData.footplantAtHeelOffSeries_Left}
        subtitle='Heel Off'
        forPdf={true}
        ref={ChartComponentRefs['footplantAtHeelOff_Left']}
      />
      <ProjectionFootPlantGraph
        data={PDFDownloadData.footplantAtInitialContactSeries_Right}
        subtitle='Initial Contact'
        forPdf={true}
        ref={ChartComponentRefs['footplantAtInitialContact_Right']}
      />
      <ProjectionFootPlantGraph
        data={PDFDownloadData.footplantAtMidStanceSeries_Right}
        subtitle='MidStance'
        forPdf={true}
        ref={ChartComponentRefs['footplantAtMidStance_Right']}
      />
      <ProjectionFootPlantGraph
        data={PDFDownloadData.footplantAtHeelOffSeries_Right}
        subtitle='Heel Off'
        forPdf={true}
        ref={ChartComponentRefs['footplantAtHeelOff_Right']}
      />
      <KinematicsGraph
        data={PDFDownloadData.pelvisKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        yAxis={PDFDownloadData.pelvisKinematicData.Sagittal.yAxis}
        strokeWidth={2}
        forPdf={true}
        ref={ChartComponentRefs['pelvisKinematicSagittal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.pelvisKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Drop (-) / Hike (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.pelvisKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['pelvisKinematicCoronal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.pelvisKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.pelvisKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['pelvisKinematicTransversal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.hipKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Extension (-) / Flexion (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.hipKinematicData.Sagittal.yAxis}
        ref={ChartComponentRefs['hipKinematicSagittal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.hipKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Abduction (-) / Adduction (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.hipKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['hipKinematicCoronal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.hipKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'External Rotation (-) / Internal Rotation (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.hipKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['hipKinematicTransversal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.kneeKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Extension (-) / Flexion (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.kneeKinematicData.Sagittal.yAxis}
        ref={ChartComponentRefs['kneeKinematicSagittal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.kneeKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Valgus (-) / Varus (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.kneeKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['kneeKinematicCoronal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.kneeKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'External Rotation (-) / Internal Rotation (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.kneeKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['kneeKinematicTransversal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.ankleKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.ankleKinematicData.Sagittal.yAxis}
        ref={ChartComponentRefs['ankleKinematicSagittal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.ankleKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Eversion (-) / Inversion (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.ankleKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['ankleKinematicCoronal']}
      />
      <KinematicsGraph
        data={PDFDownloadData.ankleKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'External Rotation (-) / Internal Rotation (+)'}
        graphAnnotations={PDFDownloadData.graphAnnotations}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.ankleKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['ankleKinematicTransversal']}
      />
    </div>
  );
};
