import { IconProps } from '..';

export const IconUpload = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 3.15227e-05C1.10218 3.15053e-05 0.720644 0.158067 0.43934 0.439371C0.158035 0.720676 -4.81777e-08 1.10221 -6.55671e-08 1.50003L-6.33815e-07 14.5C-6.51205e-07 14.8979 0.158035 15.2794 0.439339 15.5607C0.720644 15.842 1.10217 16 1.5 16L12.5 16C12.8978 16 13.2794 15.842 13.5607 15.5607C13.842 15.2794 14 14.8979 14 14.5L14 5.62103C13.9996 5.22334 13.8414 4.84207 13.56 4.56103L9.44 0.439032C9.30052 0.299646 9.13494 0.189127 8.95271 0.113799C8.77048 0.0384708 8.57518 -0.00018886 8.378 3.18234e-05L1.5 3.15227e-05ZM7 6.00003C7.19891 6.00003 7.38968 6.07905 7.53033 6.2197C7.67098 6.36035 7.75 6.55112 7.75 6.75003L7.75 8.25003L9.25 8.25003C9.44891 8.25003 9.63968 8.32905 9.78033 8.4697C9.92098 8.61035 10 8.80112 10 9.00003C10 9.19894 9.92098 9.38971 9.78033 9.53036C9.63968 9.67101 9.44891 9.75003 9.25 9.75003L7.75 9.75003L7.75 11.25C7.75 11.4489 7.67098 11.6397 7.53033 11.7804C7.38968 11.921 7.19891 12 7 12C6.80109 12 6.61032 11.921 6.46967 11.7804C6.32902 11.6397 6.25 11.4489 6.25 11.25L6.25 9.75003L4.75 9.75003C4.55109 9.75003 4.36032 9.67101 4.21967 9.53036C4.07902 9.38971 4 9.19894 4 9.00003C4 8.80112 4.07902 8.61035 4.21967 8.4697C4.36032 8.32905 4.55109 8.25003 4.75 8.25003L6.25 8.25003L6.25 6.75003C6.25 6.55112 6.32902 6.36035 6.46967 6.2197C6.61032 6.07905 6.80109 6.00003 7 6.00003Z'
        fill={color}
      />
    </svg>
  );
};
