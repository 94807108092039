/** Squat analysis report tab */
import { IonCardContent, IonCol, IonGrid, IonIcon, IonImg, IonRow, IonText, IonThumbnail } from '@ionic/react';

// Styles
import styles from '../Reports.module.css';

// Images
import singleLegSquatAvatar from '../../../assets/avatars/squat/singleLegSquatAvatar.png';
import hipCoronalMovement from '../../../assets/icons/kinematicsIcons/hipCoronalMovement.png';
import hipTransversalMovement from '../../../assets/icons/kinematicsIcons/hipTransversalMovement.png';
import kneeCoronalMovement from '../../../assets/icons/kinematicsIcons/kneeCoronalMovement.png';

// Hooks
import { SLSquatAnalysisData } from '../../../model/reportV2.model';

// Components
import { helpCircleOutline } from 'ionicons/icons';
import { BulletList } from '../../../components/BulletList/BulletList';
import { Card } from '../../../components/Card';
import { CardHeader } from '../../../components/CardHeader/cardHeader';
import { DataTable } from '../../../components/DataTable/DataTable';
import { DataTableColumn } from '../../../components/DataTable/DataTableColumn';
import { DataTableHeader } from '../../../components/DataTable/DataTableHeader';
import { DataTableRow } from '../../../components/DataTable/DataTableRow';
import { DataTableSection } from '../../../components/DataTable/DataTableSection';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

type SLSquatReportProps = {
  tabData: SLSquatAnalysisData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const SingleLegSquatAnalysisReportTab = ({ tabData }: SLSquatReportProps) => {
  const colors = ['tableLabel_succes', 'tableLabel_warning', 'tableLabel_danger', 'tableLabel_succes'];
  function getColor(index: number) {
    return colors[index % colors.length];
  }

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow className='ion-padding-vertical'>
          <Card style={{ height: 'fit-content', margin: 0 }}>
            <CardHeader
              title={
                <>
                  <IonGrid fixed className='ion-no-padding'>
                    <IonRow className='ion-no-padding ion-justify-content-center'>
                      <IonCol size='5' style={{ marginRight: '4px' }}>
                        <IonRow className='ion-justify-content-center ion-padding-bottom'>
                          <IonText className={styles.title}>Left Leg</IonText>
                        </IonRow>
                        <IonRow className='ion-justify-content-center'>
                          <IonCol size='6' className='ion-text-center'>
                            <b>Rep Time:</b> {tabData.repTime_Left} (± {tabData.stdRepTime_Left}) sec.
                          </IonCol>
                          <IonCol size='6' className='ion-text-center'>
                            <b>Q-Score:</b>{' '}
                            <span
                              className={
                                tabData.qScore_Left === 1
                                  ? styles.qualityLabel_succes
                                  : tabData.qScore_Left === 4
                                    ? styles.qualityLabel_danger
                                    : styles.qualityLabel_warning
                              }
                              id='showQualityLabel'
                            >
                              {tabData.qScoreLabel_Left}
                            </span>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                      <span style={{ width: '1px', backgroundColor: '#e0e0e0' }} />
                      <IonCol size='5' style={{ marginLeft: '4px' }}>
                        <IonRow className='ion-justify-content-center  ion-padding-bottom'>
                          <IonText className={styles.title}>Right Leg</IonText>
                        </IonRow>
                        <IonRow className='ion-justify-content-center'>
                          <IonCol size='6' className='ion-text-center'>
                            <b>Rep Time:</b> {tabData.repTime_Right} (± {tabData.stdRepTime_Right}) sec.
                          </IonCol>
                          <IonCol size='6' className='ion-text-center'>
                            <b>Q-Score:</b>{' '}
                            <span
                              className={
                                tabData.qScore_Right === 1
                                  ? styles.qualityLabel_succes
                                  : tabData.qScore_Right === 4
                                    ? styles.qualityLabel_danger
                                    : styles.qualityLabel_warning
                              }
                              id='showQualityLabel'
                            >
                              {tabData.qScoreLabel_Right}
                            </span>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </>
              }
            />
          </Card>
        </IonRow>
        <IonRow className='ion-padding-bottom'>
          <Card style={{ margin: 0 }}>
            <IonCardContent>
              <IonRow className='ion-align-items-center ion-justify-content-center'>
                <IonCol size='5' className=''>
                  <IonImg src={singleLegSquatAvatar} className={styles.squatsAvatar} />
                </IonCol>
                <IonCol size='7' className='ion-no-padding'>
                  <DataTable>
                    <DataTableHeader titles={['', 'Left', 'Right', 'Symmetry']} />

                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Pelvis</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[0][0].toFixed(1) + '°'} ± {tabData.romData[0][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[0][2].toFixed(1) + '°'} ± {tabData.romData[0][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[0][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                    </DataTableSection>
                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Hip</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[1][0].toFixed(1) + '°'} ± {tabData.romData[1][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[1][2].toFixed(1) + '°'} ± {tabData.romData[1][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[1][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          External rotation <IonIcon src={helpCircleOutline} id='trigger-hip-external-rotation' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[2][0])}>
                          {tabData.hipLabels.externalRotationLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[2][1])}>
                          {tabData.hipLabels.externalRotationLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Internal rotation <IonIcon src={helpCircleOutline} id='trigger-hip-internal-rotation' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[3][0])}>
                          {tabData.hipLabels.internalRotationLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[3][1])}>
                          {tabData.hipLabels.internalRotaionLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Abduction <IonIcon src={helpCircleOutline} id='trigger-hip-abduction' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[4][0])}>
                          {tabData.hipLabels.abductionLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[4][1])}>
                          {tabData.hipLabels.abductionLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Adduction <IonIcon src={helpCircleOutline} id='trigger-hip-adduction' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[5][0])}>
                          {tabData.hipLabels.adductionLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[5][1])}>
                          {tabData.hipLabels.adductionLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                    </DataTableSection>

                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Knee</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[2][0].toFixed(1) + '°'} ± {tabData.romData[2][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[2][2].toFixed(1) + '°'} ± {tabData.romData[2][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[2][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Varus / Valgus <IonIcon src={helpCircleOutline} id='trigger-knee-valgus' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[6][0])}>
                          {tabData.varusValgusLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[6][1])}>
                          {tabData.varusValgusLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                    </DataTableSection>

                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Ankle</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[3][0].toFixed(1) + '°'} ± {tabData.romData[3][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[3][2].toFixed(1) + '°'} ± {tabData.romData[3][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[3][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Early heel raise
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[0][0])}>
                          {tabData.ankleLabels.earlyHeelRaiseLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[0][1])}>
                          {tabData.ankleLabels.earlyHeelRaiseLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                    </DataTableSection>
                  </DataTable>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </Card>
        </IonRow>

        <Tooltip
          trigger='trigger-hip-external-rotation'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={hipTransversalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Rotation <0°: Hip endorotates',
                    'Fair: Rotation between 10° & 20°',
                    'Poor: Rotation more than 20°',
                    'Rotation <10°: Rotation is less than 10°',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
        <Tooltip
          trigger='trigger-hip-abduction'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={hipCoronalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Abduction <10°: Abduction less than 10°',
                    'Fair: Abduction between 10° & 20°',
                    'Poor: Abduction more than 20°',
                    'Abduction <0°: Adduction detected°',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
        <Tooltip
          trigger='trigger-hip-adduction'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={hipCoronalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Adduction <10°: Adduction less than 10°',
                    'Fair: Adduction between 10° & 20°',
                    'Poor: Adduction more than 20°',
                    'Adduction >0°: Abduction detected°',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
        <Tooltip
          trigger='trigger-hip-internal-rotation'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={hipTransversalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Rotation <-10°: Rotation is less than 10°',
                    'Fair: Rotation between -10° & -20°',
                    'Poor: Rotation more than -20°',
                    'Rotation >0°: Hip exorotates°',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
        <Tooltip
          trigger='trigger-knee-valgus'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
          reference='trigger'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={kneeCoronalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Valgus <10°',
                    'Fair: Valgus between 10° and 20°',
                    'Poor: Valgus more than 20°',
                    'Varus: Knee move outward',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
      </>
    );
  }
};
