import React, { useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonIcon, IonButton, IonButtons } from '@ionic/react';

import { closeCircleOutline } from 'ionicons/icons';
import { uuid } from '../../../../utilities';
import {
  KinematicData,
  ProximoDistalSequenceAnnotations,
  SingleSideVariabilityGraphAnnotations,
} from '../../../../model/reportV2.model';

import { ProximodistalSequenceGraph } from '../Graphs';

import styles from './ModalContent.module.css';
import { Loading } from '../../../../components/Loading/Loading';

type ProximodistalSequenceModalContentProps = {
  EuclideanData: KinematicData;
  proximodistalSequenceData: ProximoDistalSequenceAnnotations;
  xAxisLeft: number;
  xAxisRight: number;
  setShowZoomModal: any;
  cycleEventAnnotations_Left: SingleSideVariabilityGraphAnnotations[];
  cycleEventAnnotations_Right: SingleSideVariabilityGraphAnnotations[];
};

function createLegend(): any[] {
  const meanEntry = [
    {
      id: uuid(),
      name: 'Mean',
      color: '#FFC700',
    },
  ];

  const allStridesEntry = [
    {
      id: uuid(),
      name: 'All strides',
      color: '#0A2E2C',
    },
  ];

  return [...meanEntry, ...allStridesEntry];
}

export const ProximodistalSequenceModalContent = ({
  EuclideanData,
  proximodistalSequenceData,
  setShowZoomModal,
  xAxisLeft,
  xAxisRight,
  cycleEventAnnotations_Left,
  cycleEventAnnotations_Right,
}: ProximodistalSequenceModalContentProps) => {
  const [loading, setLoading] = useState(true);
  const [legend, setLegend] = useState<any[]>([]);
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [activeTabPDS, setActiveTabPDS] = useState('Mean');

  const seriesProximodistalSequence_Left = useMemo(() => {
    return showAllStrides ? EuclideanData.allStrides.Left.series : EuclideanData.mean.Left.series;
  }, [showAllStrides, EuclideanData]);

  const seriesProximodistalSequence_Right = useMemo(() => {
    return showAllStrides ? EuclideanData.allStrides.Right.series : EuclideanData.mean.Right.series;
  }, [showAllStrides, EuclideanData]);

  const yAxis_Left = useMemo(() => {
    return showAllStrides ? EuclideanData.allStrides.Left.yAxis : EuclideanData.mean.Left.yAxis;
  }, [showAllStrides, EuclideanData]);

  const yAxis_Right = useMemo(() => {
    return showAllStrides ? EuclideanData.allStrides.Right.yAxis : EuclideanData.mean.Right.yAxis;
  }, [showAllStrides, EuclideanData]);

  // Tab change set the active tab and the data for the graphs.
  const tabChange = (name: string) => {
    localStorage.setItem('activeTabPDS', name);
    setActiveTabPDS(name);
    if (name === 'All strides') {
      setShowAllStrides(true);
      //   setDataLeft(data.kneePositionsLeft);
      //   setDataRight(data.kneePositionsRight);
    } else {
      setShowAllStrides(false);
      //   setDataLeft(data.kneePositionLeft);
      //   setDataRight(data.kneePositionRight);
    }
  };

  useEffect(() => {
    const activeTabPDS = localStorage.getItem('activeTabPDS');
    if (activeTabPDS) {
      setActiveTabPDS(activeTabPDS);
    } else {
      localStorage.setItem('activeTabPDS', 'Mean');
      setActiveTabPDS('Mean');
    }
  });

  useEffect(() => {
    setLegend(createLegend());
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [EuclideanData]);

  const cleanUp = () => {
    localStorage.setItem('activeTabPDS', 'Mean');
    setShowAllStrides(true);
    setShowZoomModal({ show: false, graph: '' });
  };

  return (
    <>
      <IonGrid fixed className={styles.Modalcontent_container}>
        <IonRow className={styles.variabilityLegend_Row}>
          <IonCol size='11' className=''>
            <div className={styles.variabilityLegend}>
              {legend.map((element, _) => {
                const color = element.color;
                const iconStyle = {
                  backgroundColor: color,
                };

                return (
                  <div
                    key={element.id}
                    className={
                      activeTabPDS === element.name
                        ? styles.variabilityLegend_Entry_Selected
                        : styles.variabilityLegend_Entry
                    }
                    onClick={() => {
                      tabChange(element.name);
                    }}
                  >
                    <div className={styles.legend_dot} style={iconStyle} />
                    <p className={styles.legend_name}>{element.name}</p>
                  </div>
                );
              })}
            </div>
          </IonCol>
          <IonCol size='1' className='ion-align-items-center'>
            <IonButtons className='ion-justify-content-center ion-align-self-center' style={{ height: '100%' }}>
              <IonButton slot='icon-only' fill='clear' className={styles.closeButton} onClick={() => cleanUp()}>
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-center ion-align-items-stretch ion-padding-vertical ion-margin-vertical ion-justify-content-center'>
          {loading && <Loading />}
          {!loading && (
            <>
              <IonCol size='6' className='ion-no-padding'>
                <ProximodistalSequenceGraph
                  data={seriesProximodistalSequence_Left}
                  title={'Left'}
                  graphAnnotations={proximodistalSequenceData.Left}
                  yAxis={yAxis_Left}
                  xAxisStart={xAxisLeft}
                  cycleEventAnnotations={cycleEventAnnotations_Left}
                />
              </IonCol>
              <IonCol size='6' className='ion-no-padding'>
                <ProximodistalSequenceGraph
                  data={seriesProximodistalSequence_Right}
                  title={'Right'}
                  graphAnnotations={proximodistalSequenceData.Right}
                  yAxis={yAxis_Right}
                  xAxisStart={xAxisRight}
                  cycleEventAnnotations={cycleEventAnnotations_Right}
                />
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
};
