import { IconProps } from '..';

export const IconPerson = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.00051 4C5.00051 3.20435 5.31658 2.44129 5.87919 1.87868C6.4418 1.31607 7.20486 1 8.00051 1C8.79616 1 9.55922 1.31607 10.1218 1.87868C10.6844 2.44129 11.0005 3.20435 11.0005 4C11.0005 4.79565 10.6844 5.55871 10.1218 6.12132C9.55922 6.68393 8.79616 7 8.00051 7C7.20486 7 6.4418 6.68393 5.87919 6.12132C5.31658 5.55871 5.00051 4.79565 5.00051 4ZM2.50118 13.4033C2.52366 11.9596 3.11294 10.5827 4.14182 9.56972C5.1707 8.55674 6.55665 7.98897 8.00051 7.98897C9.44437 7.98897 10.8303 8.55674 11.8592 9.56972C12.8881 10.5827 13.4774 11.9596 13.4998 13.4033C13.5016 13.5005 13.4749 13.5961 13.4232 13.6784C13.3715 13.7607 13.2969 13.8261 13.2085 13.8667C11.5746 14.6158 9.79795 15.0024 8.00051 15C6.14318 15 4.37851 14.5947 2.79251 13.8667C2.70417 13.8261 2.62956 13.7607 2.57782 13.6784C2.52608 13.5961 2.49944 13.5005 2.50118 13.4033Z'
        fill={color}
      />
    </svg>
  );
};
