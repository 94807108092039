import { IconProps } from '..';

export const IconInfo = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        d='M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM9 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6.75 8a.75.75 0 0 0 0 1.5h.75v1.75a.75.75 0 0 0 1.5 0v-2.5A.75.75 0 0 0 8.25 8h-1.5Z'
        clipRule='evenodd'
        fill={color}
      />
    </svg>
  );
};
