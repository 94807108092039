import { IonButtons, IonHeader, IonProgressBar, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router';

// Styles
import styles from './PageHeader.module.css';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';

// Components
import { Button } from '../Button';
import { Icon } from '../Icon';
import { IconRotate } from '../Icon/types';

interface PageHeaderProps extends ComponentProps<typeof IonHeader> {
  backTarget?: string;
  isLoading?: boolean;
  progress?: number;
  title?: string;
}

export const PageHeader = ({
  children,
  title,
  backTarget,
  isLoading,
  progress,
  ...props
}: PropsWithChildren<PageHeaderProps>) => {
  const history = useHistory();

  const goBack = (backTarget: string) => {
    history.push(backTarget);
  };

  return (
    <IonHeader className='ion-no-padding' {...props}>
      <IonToolbar className={styles.toolbar}>
        {backTarget && !isLoading && (
          <IonButtons slot='start'>
            <Button variant='tertiary' onClick={() => goBack(backTarget)}>
              <Icon name='chevron' size={13} direction={IconRotate.West} />
              Back
            </Button>
          </IonButtons>
        )}

        {children ?? <IonTitle>{title}</IonTitle>}

        {isLoading && (
          <IonProgressBar
            value={progress}
            className={styles.progress}
            type={progress === undefined ? 'indeterminate' : 'determinate'}
          />
        )}
      </IonToolbar>
    </IonHeader>
  );
};
