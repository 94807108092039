import { IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react';
import { ApexOptions } from 'apexcharts';
import * as React from 'react';
import Chart from 'react-apexcharts';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../../components/CardHeader/cardHeader';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { ReportModal } from '../../../../components/Modal/ReportModal';
import styles from './ApexCharts.module.css';

type ThighBarGraphProps = {
  data: any;
  title: string;
  stdData: any;
  xAxisCategories: any;
  yAxisLabel: string;
  subtitle?: string;
};

export const ThighBarGraph = ({ data, title, stdData, xAxisCategories, yAxisLabel, subtitle }: ThighBarGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = React.useState('small');
  const [showModal, setShowModal] = React.useState({ show: false });

  React.useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: 0,
      height: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      style: {
        fontSize: '8px',
      },
      x: {
        show: true,
      },
      y: {
        formatter: function (val: number, { seriesIndex, dataPointIndex, w }) {
          if (seriesIndex == 0) {
            return (
              data[seriesIndex].data[dataPointIndex].toFixed(2) +
              yAxisLabel +
              ' ± ' +
              stdData.col0[dataPointIndex].toFixed(2)
            );
          } else {
            return (
              data[seriesIndex].data[dataPointIndex].toFixed(2) +
              yAxisLabel +
              ' ± ' +
              stdData.col1[dataPointIndex].toFixed(2)
            );
          }
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
    },

    xaxis: {
      type: 'category',
      categories: xAxisCategories,
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      stepSize: title == 'Hamstring propulsion - Acceleration' ? 25 : 1,
      title: {
        text: yAxisLabel,
        style: {
          color: '#707070',
          fontSize: '8px',
        },
      },
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    // title: {
    //   text: title,
    //   align: 'center',
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: 'bold',
    //     fontFamily: 'Montserrat',
    //     color: '#2066FA',
    //   },
    // },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
        radius: 50,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      offsetY: 10,
    },
    colors: ['#2066FA', '#AAFF49'],
  };

  return (
    <>
      <ReportModal
        isOpen={showModal.show}
        onDidDismiss={() => {
          setShowModal({ show: false });
        }}
        header={title}
        setShowInfo={() => setShowModal({ show: false })}
      >
        <IonGrid className='' style={{ height: '100%' }}>
          <IonRow className='ion-align-items-center ion-justify-content-center'>
            <IonCol size='11' className=''>
              <div className={styles.reportModalGraph_Wrapper}>
                <Chart options={options} series={data} type='bar' width='100%' />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ReportModal>

      <Card
        style={{ height: '100%', margin: 0 }}
        onClick={() => {
          setShowModal({ show: true });
        }}
      >
        {' '}
        <CardHeader title={title} subtitle={subtitle} />
        <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
          <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Chart options={options} series={data} type='bar' width='100%' height={windowSize == 'small' ? 125 : 175} />
          </div>
        </IonCardContent>
      </Card>
    </>
  );
};
