import { IonCol, IonIcon, IonImg, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';

// Context
import { useAuthContext } from '../authContext';
import { loadRUNData, loadSLSQUATData, loadSQUATData, loadWALKData } from './loadData';

// Components
import { cloudDownloadOutline, personCircleOutline } from 'ionicons/icons';
import { PDFDocument } from '.';
import { Spinner } from '../Spinner/Spinner';
import { RunReportCharts, SLSquatReportCharts, SquatReportCharts, WalkReportCharts } from './Charts';
import { Button } from '../Button';
import { PDFDownloadLink } from '@react-pdf/renderer';
// import { ChartRefs } from './Charts';
import { createChartRefs, chartKeys } from './Charts';

// Custom Icons
import stethoscopeIcon from '../../assets/icons/stethoscope.svg';
import errorComponent from '../../assets/icons/errorComponentIcon.svg';

// Styles
import styles from './PDFDownloadModal.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Types
import { PDFVersion } from './PDFDocument';
import { ReportInfo, ReportType } from '../../model';

interface PDFDownloadModalProps {
  //   cleanUp?: () => void;
  PDFError: (error: boolean) => void;
  reportInfo: ReportInfo;
  onClose: () => void;
}

export const PDFDownloadModalContent = ({ PDFError, reportInfo, onClose }: PDFDownloadModalProps) => {
  const userId = useAuthContext().state.userState?.user.uid;
  const [PDFDownloadChoosen, setPDFDownloadChoosen] = useState(false);
  const [PDFVersion, setPDFVersion] = useState<PDFVersion>('Specialist');
  const [dataReady, setDataReady] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  const [chartsMounted, setChartsMounted] = useState(false);
  const [PDFDownloadData, setPDFDownloadData] = useState<any>(undefined);
  const reportType =
    reportInfo.reportType === ReportType.WALK
      ? 'Walk'
      : reportInfo.reportType === ReportType.RUN
        ? 'Run'
        : reportInfo.reportType === ReportType.SQUAT
          ? 'Squat'
          : reportInfo.reportType === ReportType.SLSQUAT
            ? 'SLSquat'
            : 'Walk';
  const chartComponentRefs = {
    Walk: createChartRefs(chartKeys.Walk),
    Run: createChartRefs(chartKeys.Run),
    Squat: createChartRefs(chartKeys.Squat),
    SLSquat: createChartRefs(chartKeys.SLSquat),
  };
  const refs = chartComponentRefs[reportType];

  useEffect(() => {
    const loadPDFData = async (loadDataFunction: Function) => {
      try {
        const data = await loadDataFunction(reportInfo, userId!);
        setPDFDownloadData(data);
        setDataReceived(true);
      } catch (error) {
        console.error('Error loading PDF data:', error);
        PDFError(true);
      }
    };

    if (PDFDownloadChoosen) {
      const reportLoaders: Record<string, Function> = {
        Walk: loadWALKData,
        Run: loadRUNData,
        Squat: loadSQUATData,
        SLSquat: loadSLSQUATData,
      };

      if (reportType && reportLoaders[reportType]) {
        loadPDFData(reportLoaders[reportType]);
      }
    }
  }, [PDFDownloadChoosen]);

  useEffect(() => {
    if (!dataReceived) return;
    if (!refs) return;

    for (const key in refs) {
      if (refs[key].current === null) {
        return;
      }
    }
    setChartsMounted(true);
  }, [dataReceived, chartComponentRefs, reportType]);

  useEffect(() => {
    if (!chartsMounted) return;
    if (!refs) return;

    const promises = Object.keys(refs).map((key) =>
      // @ts-ignore .chart is not specified in exported type & nullability is checked by chartsMounted
      refs[key].current.chart.dataURI().then(({ imgURI }: { imgURI: string }) => {
        PDFDownloadData[`${key}ChartDataUri`] = imgURI;
      }),
    );

    Promise.all(promises)
      .then(() => setDataReady(true))
      .catch((error) => {
        console.error('Error rendering charts:', error);
        PDFError(true);
      });
  }, [chartsMounted, reportType, PDFDownloadData]);

  return (
    <>
      <IonRow
        className='ion-justify-content-center ion-align-items-center'
        style={{ height: '200px', marginTop: 'auto', marginBottom: 'auto', position: 'relative' }}
      >
        <>
          {PDFDownloadChoosen && dataReady ? (
            <PDFDownloadLink
              document={<PDFDocument reportInfo={reportInfo} data={PDFDownloadData} version={PDFVersion} />}
              fileName={`${reportInfo.reportName}.pdf`}
            >
              {({ blob, url, loading, error }) => {
                // console.log('blob:', blob, 'url:', url, 'loading:', loading, 'error:', error);
                return !url && !blob && !error ? (
                  <Spinner />
                ) : error ? (
                  <div className='' onClick={() => onClose()}>
                    <IonRow className='ion-align-items-stretch ion-no-padding' style={{ padding: '6px' }}>
                      <IonRow className='fullWidth ion-align-items-end'>
                        <IonCol className='' size='7'>
                          <strong>
                            Whoops! Something went wrong. <br />
                          </strong>
                          <p>Sorry about that!</p>
                        </IonCol>
                        <IonCol size='5' className=''>
                          <IonImg src={errorComponent} />
                        </IonCol>
                      </IonRow>
                      <IonRow className='fullWidth ion-justify-content-center'>
                        <Button variant='tertiary'>Close</Button>
                      </IonRow>
                    </IonRow>
                  </div>
                ) : (
                  <div
                    className={styles.Download}
                    onClick={() =>
                      setTimeout(() => {
                        onClose();
                      }, 500)
                    }
                  >
                    <IonIcon src={cloudDownloadOutline} className={styles.DownloadIcon} />
                    <p className={styles.DownloadLink}>Click to download</p>
                  </div>
                );
              }}
            </PDFDownloadLink>
          ) : PDFDownloadChoosen && !dataReady ? (
            <Spinner />
          ) : (
            <>
              <IonCol size='6' className=''>
                <IonRow className='ion-justify-content-center'>
                  <IonCol
                    size='10'
                    className={mergeClassNames(styles.download_buttonCol, 'ion-text-center')}
                    onClick={() => {
                      setPDFDownloadChoosen(true);
                      setPDFVersion('Specialist');
                    }}
                  >
                    <IonIcon src={stethoscopeIcon} className={styles.download_buttonIcon} />
                    <h5 className=''>
                      <small>Specialist Report</small>
                    </h5>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol size='6' className=''>
                <IonRow className='ion-justify-content-center'>
                  <IonCol
                    size='10'
                    className={mergeClassNames(styles.download_buttonCol, 'ion-text-center')}
                    onClick={() => {
                      setPDFDownloadChoosen(true);
                      setPDFVersion('Client');
                    }}
                  >
                    <IonIcon src={personCircleOutline} className={styles.download_buttonIcon} />
                    <h5 className=''>
                      <small>Client Report</small>
                    </h5>
                  </IonCol>
                </IonRow>
              </IonCol>
            </>
          )}
        </>
      </IonRow>

      {PDFDownloadChoosen && dataReceived && reportType === 'Walk' && (
        <WalkReportCharts PDFDownloadData={PDFDownloadData} ChartComponentRefs={chartComponentRefs.Walk} />
      )}
      {PDFDownloadChoosen && dataReceived && reportType === 'Run' && (
        <RunReportCharts PDFDownloadData={PDFDownloadData} ChartComponentRefs={chartComponentRefs.Run} />
      )}
      {PDFDownloadChoosen && dataReceived && reportType === 'Squat' && (
        <SquatReportCharts PDFDownloadData={PDFDownloadData} ChartComponentRefs={chartComponentRefs.Squat} />
      )}
      {PDFDownloadChoosen && dataReceived && reportType === 'SLSquat' && (
        <SLSquatReportCharts PDFDownloadData={PDFDownloadData} ChartComponentRefs={chartComponentRefs.SLSquat} />
      )}
    </>
  );
};
