import React, { PropsWithChildren } from 'react';
import { IonChip } from '@ionic/react';

import styles from './Tag.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface TagProps extends React.ComponentProps<typeof IonChip> {
  variant?: 'blue' | 'gray' | 'green' | 'yellow' | 'beige';
  size?: 'sm' | 'md';
}

export const Tag = ({ children, variant = 'gray', size = 'md', color, ...props }: PropsWithChildren<TagProps>) => (
  <IonChip className={mergeClassNames(styles.tag, styles[variant], styles[size])} {...props}>
    {children}
  </IonChip>
);
