import Walking from '../../../assets/icons/Walking.png';
import Running from '../../../assets/icons/Running.png';
import Squats from '../../../assets/icons/Squat.png';
import SingleLegSquat from '../../../assets/icons/SingleLegSquat.png';

interface ORYXMeasurement {
  url: string;
  iosIcon: string;
  title: string;
  id: number;
  description: string;
}

export const ORYXMeasurements: ORYXMeasurement[] = [
  {
    id: 1,
    title: 'Walk',
    url: '/docs/ORYX_Measurements/Walk',
    iosIcon: Walking,
    description: 'lorem ipsum..',
  },
  {
    id: 2,
    title: 'Run',
    url: '/docs/ORYX_Measurements/Run',
    iosIcon: Running,
    description: 'description',
  },
  {
    id: 1,
    title: 'Double leg Squat',
    url: '/docs/ORYX_Measurements/Squat',
    iosIcon: Squats,
    description: 'description',
  },
  {
    id: 1,
    title: 'Single Leg Squat',
    url: '/docs/ORYX_Measurements/SingleLegSquat',
    iosIcon: SingleLegSquat,
    description: 'lorem ipsum',
  },
];
