/** The Run Kinematics report tab */
import { IonCardContent, IonCol, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';

// Components
import { ProjectionFootPlantGraph, RearPendulum, RespectTheSagittalPlaneGraph } from '../components/Graphs';
import { Card } from '../../../components/Card';
import { CardHeader } from '../../../components/CardHeader/cardHeader';
import { ReportModal } from '../../../components/Modal/ReportModal';
import { RearPendulumModalContent, RespectTheSagittalPlaneModalContent } from '../components/Modals';
import { InfoToast } from '../../../components/InfoToast/InfoToast';

// Hooks & models
import { RunningStatusData } from '../../../model/reportV2.model';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

// Styles

type AttractorsReportProps = {
  tabData: RunningStatusData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunningStatusReportTab = ({ tabData }: AttractorsReportProps) => {
  const runningStatusData = tabData.runningStatusTableData;
  const romData = tabData.romData;
  const [showInfoToast, setShowInfoToast] = useState(false);

  const { width, height } = useWindowDimensions();
  const [projectionFootPlantRowHeight, setProjectionFootPlantRowHeight] = useState(105);
  const [showModal, setShowModal] = useState({
    show: false,
    title: '',
    component: <></>,
  });

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setProjectionFootPlantRowHeight(140);
      } else {
        setProjectionFootPlantRowHeight(105);
      }
    }
  }, [width]);

  useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <ReportModal
          isOpen={showModal.show}
          onDidDismiss={() => setShowModal({ show: false, title: '', component: <></> })}
          header={showModal.title}
          setShowInfo={() => setShowModal({ show: false, title: '', component: <></> })}
        >
          {showModal.component}
        </ReportModal>

        <IonRow className='ion-align-items-center ion-justify-content-center'>
          <IonCol size='4' className=''>
            <Card
              style={{ margin: 0 }}
              onClick={() =>
                setShowModal({
                  show: true,
                  title: 'Rear Pendulum',
                  component: <RearPendulumModalContent data={tabData.rearPendulumData.rearPendulumZoomModalContent} />,
                })
              }
            >
              <CardHeader title='Pendulum Left' />
              <IonRow className='ion-no-padding'>
                <RearPendulum
                  data={tabData.rearPendulumData.rearPendulumGraphData_Left}
                  TOLocation={tabData.rearPendulumData.rearPendulum_ToeOff_Left}
                  MSwLocation={tabData.rearPendulumData.rearPendulum_MidSwing_Left}
                  stanceData={tabData.rearPendulumData.rearPendulumStance_Left}
                />
              </IonRow>
              <IonRow className='ion-padding-bottom'>
                <IonCol style={{ marginRight: '4px' }} className='ion-no-padding ion-text-center'>
                  <small> &sigma; TO: {tabData.rearPendulumData.rearPendulumVariance_ToeOff_Left}</small>
                </IonCol>
                <span style={{ width: '1px', backgroundColor: '#e0e0e0' }} />
                <IonCol style={{ marginLeft: '4px' }} className='ion-no-padding ion-text-center'>
                  <small> &sigma; MSw: {tabData.rearPendulumData.rearPendulumVariance_MidSwing_Left}</small>
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
          <IonCol size='4' className=''>
            <Card
              style={{ margin: 0 }}
              onClick={() =>
                setShowModal({
                  show: true,
                  title: 'Rear Pendulum',
                  component: <RearPendulumModalContent data={tabData.rearPendulumData.rearPendulumZoomModalContent} />,
                })
              }
            >
              <CardHeader title='Pendulum Right' />
              <IonRow className='ion-no-padding'>
                <RearPendulum
                  data={tabData.rearPendulumData.rearPendulumGraphData_Right}
                  TOLocation={tabData.rearPendulumData.rearPendulum_ToeOff_Right}
                  MSwLocation={tabData.rearPendulumData.rearPendulum_MidSwing_Right}
                  stanceData={tabData.rearPendulumData.rearPendulumStance_Right}
                />
              </IonRow>
              <IonRow className='ion-padding-bottom'>
                <IonCol style={{ marginRight: '4px' }} className='ion-no-padding ion-text-center'>
                  <small> &sigma; TO: {tabData.rearPendulumData.rearPendulumVariance_ToeOff_Left}</small>
                </IonCol>
                <span style={{ width: '1px', backgroundColor: '#e0e0e0' }} />
                <IonCol style={{ marginLeft: '4px' }} className='ion-no-padding ion-text-center'>
                  <small> &sigma; MSw: {tabData.rearPendulumData.rearPendulumVariance_MidSwing_Left}</small>
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
          <IonCol size='4' className=''>
            <Card
              style={{ margin: 0 }}
              onClick={() =>
                setShowModal({
                  show: true,
                  title: 'Rear Pendulum',
                  component: (
                    <RespectTheSagittalPlaneModalContent
                      data={tabData.respectTheSagittalPlaneSeries}
                      annotations={tabData.annotations}
                    />
                  ),
                })
              }
            >
              <CardHeader title='Respect the sagittal plane' />
              <IonRow>
                <RespectTheSagittalPlaneGraph
                  data={tabData.respectTheSagittalPlaneSeries}
                  annotations={tabData.annotations}
                />
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>

        <IonRow className='ion-align-items-stretch ion-justify-content-center ion-padding-bottom'>
          <IonCol size='4' className=''>
            <Card style={{ height: '100%', margin: 0 }}>
              <CardHeader title='Projection footplant - Left' />
              <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                <ProjectionFootPlantGraph
                  data={tabData.footplantSeries_Left.footplantAtInitialContactSeries_Left}
                  subtitle='Initial Contact'
                />
              </IonRow>
              <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                <ProjectionFootPlantGraph
                  data={tabData.footplantSeries_Left.footplantAtMidStanceSeries_Left}
                  subtitle='MidStance'
                />
              </IonRow>
              <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                <ProjectionFootPlantGraph
                  data={tabData.footplantSeries_Left.footplantAtHeelOffSeries_Left}
                  subtitle='Heel Off'
                />
              </IonRow>
            </Card>
          </IonCol>
          <IonCol size='4'>
            <Card style={{ height: '100%', margin: 0 }}>
              <CardHeader title='Projection footplant - Right' />
              <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                <ProjectionFootPlantGraph
                  data={tabData.footplantSeries_Right.footplantAtInitialContactSeries_Right}
                  subtitle='Initial Contact'
                />
              </IonRow>
              <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                <ProjectionFootPlantGraph
                  data={tabData.footplantSeries_Right.footplantAtMidStanceSeries_Right}
                  subtitle='MidStance'
                />
              </IonRow>
              <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                <ProjectionFootPlantGraph
                  data={tabData.footplantSeries_Right.footplantAtHeelOffSeries_Right}
                  subtitle='Heel Off'
                />
              </IonRow>
            </Card>
          </IonCol>
          <IonCol size='4' className='' style={{ height: '100%' }}>
            <Card style={{ margin: 0 }}>
              <CardHeader title={'Info'} />
              <IonCardContent>
                <IonRow>
                  <table className='ORYX_List'>
                    <tbody>
                      <tr>
                        <td>Stride duration</td>
                        <td>{runningStatusData[0][0].toFixed(2) + ' s'}</td>
                      </tr>
                      <tr>
                        <td>Cadence</td>
                        <td colSpan={3}>{(runningStatusData[2][0] / 2).toFixed(0) + ' strides/min'}</td>
                      </tr>
                    </tbody>
                  </table>
                </IonRow>
                <IonRow className='ion-no-padding'>
                  <table className='ORYX_Table'>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Left</th>
                        <th>Right</th>
                        <th>Symmetry</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ground contact time</td>
                        <td>{runningStatusData[1][0].toFixed(2) + ' s'}</td>
                        <td>{runningStatusData[1][1].toFixed(2) + ' s'}</td>
                        <td>{runningStatusData[1][2].toFixed(2) + ' %'}</td>
                      </tr>
                      <tr>
                        <td>Propulsive velocity</td>
                        <td>{runningStatusData[4][0].toFixed(0) + ' m/s'}</td>
                        <td>{runningStatusData[4][1].toFixed(0) + ' m/s'}</td>
                        <td>{runningStatusData[4][2].toFixed(2) + ' %'}</td>
                      </tr>

                      <tr className='ORYX_Table_Head'>
                        <td> Pelvis</td>
                        <td>{romData[0][0].toFixed(1) + '°'}</td>
                        <td>{romData[0][1].toFixed(1) + '°'}</td>
                        <td>{romData[0][2].toFixed(0) + '%'}</td>
                      </tr>
                      <tr className='ORYX_Table_Head'>
                        <td> Hip</td>
                        <td>{romData[1][0].toFixed(1) + '°'}</td>
                        <td>{romData[1][1].toFixed(1) + '°'}</td>
                        <td>{romData[1][2].toFixed(0) + '%'}</td>
                      </tr>
                      <tr>
                        <td className='ORYX_Table_Indent'>Flexion</td>
                        <td>{romData[2][0].toFixed(1) + '°'}</td>
                        <td>{romData[2][1].toFixed(1) + '°'}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className='ORYX_Table_Indent'>Extension</td>
                        <td>{romData[3][0].toFixed(1) + '°'}</td>
                        <td>{romData[3][1].toFixed(1) + '°'}</td>
                        <td></td>
                      </tr>
                      <tr className='ORYX_Table_Head'>
                        <td>Knee</td>
                        <td>{romData[4][0].toFixed(1) + '°'}</td>
                        <td>{romData[4][1].toFixed(1) + '°'}</td>
                        <td>{romData[4][2].toFixed(0) + '%'}</td>
                      </tr>
                      <tr>
                        <td className='ORYX_Table_Indent'> Stance flexion</td>
                        <td>{romData[5][0].toFixed(1) + '°'}</td>
                        <td>{romData[5][1].toFixed(1) + '°'}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className='ORYX_Table_Indent'>Varus Valgus</td>
                        <td>{romData[6][0].toFixed(1) + '°'}</td>
                        <td>{romData[6][1].toFixed(1) + '°'}</td>
                        <td></td>
                      </tr>
                      <tr className='ORYX_Table_Head'>
                        <td>Ankle</td>
                        <td>{romData[7][0].toFixed(1) + '°'}</td>
                        <td>{romData[7][1].toFixed(1) + '°'}</td>
                        <td>{romData[7][2].toFixed(0) + '%'}</td>
                      </tr>
                      <tr>
                        <td className='ORYX_Table_Indent'>Dorsal flexion</td>
                        <td>{romData[8][0].toFixed(1) + '°'}</td>
                        <td>{romData[8][1].toFixed(1) + '°'}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className='ORYX_Table_Indent'>Plantar flexion</td>
                        <td>{romData[9][0].toFixed(1) + '°'}</td>
                        <td>{romData[9][1].toFixed(1) + '°'}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>

        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='The Pendulum graphs can be enlarged by clicking / tapping on the graph.'
        />
      </>
    );
  }
};
