/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Homepage.
 */

import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import './Login.css';
import login_bg_shape from '../../assets/login/login_bg_shape.png';
import { LoginForm } from '../../components/Forms/loginForm';
import { useAuthContext } from '../../components/authContext';

import styles from './Login.module.css';
import { ErrorComponent } from '../../components/404Component';

export const LoginPage: React.FC = () => {
  return (
    <IonPage className={styles.page}>
      <IonContent fullscreen className={styles.wrapper}>
        <IonGrid class={styles.grid}>
          <IonRow class='ion-align-items-center'>
            <IonCol class='ion-justify-content-center'>
              <LoginForm />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonImg src={login_bg_shape} className={styles.background} />
    </IonPage>
  );
};

export const ResetPasswordPage: React.FC = () => {
  const { state, clearError } = useAuthContext();

  return (
    // TODO: Implement reset password page
    <ErrorComponent />
  );
};

export const NewPasswordPage: React.FC = () => {
  const { state, clearError } = useAuthContext();

  return (
    // TODO: Implement new password page
    <ErrorComponent />
  );
};
