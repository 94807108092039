import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimagekneeextensionpreswing1 from './assets/IBimagekneeextensionpreswing1.png';
import IBimagekneeextensionpreswing2 from './assets/IBimagekneeextensionpreswing2.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/Swiper';

// Styles
import styles from './ModalContent.module.css';

export const KneeExtenstionTerminalStanceRunModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagekneeextensionpreswing1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  From landing to midstance, the leg is compressed (also see: midstance).
                  <br></br>
                  <br></br>
                  If the leg is sufficiently stiff, it can act as a spring mass mechanism. At compression, kinetic
                  energy is stored by stretched tendons and fascia for re-use in terminal stance.
                  <br></br>
                  <br></br>
                  Just like a spring mass, the end position of the leg should be similar to the initial knee position,
                  resulting in similar drop and jump height.
                  <br></br>
                  <br></br>
                  As a key hub for energy transport, the knee plays an important role in this mechanism.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagekneeextensionpreswing2} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  After reversing the falling movement (at midstance), this elastic stretch is released with leg
                  extension as a result, catapulting the body back upwards.
                  <br></br>
                  <br></br>
                  After unloading elastic energy, there is very little time and range of motion (the hip is almost fully
                  extended) to add energy to the push-off.
                  <br></br>
                  <br></br>
                  Although there must be knee extension for proximo distal sequence, excessive extension indicates
                  emphasis of push-off, resulting in an excessive forward pelvic rotation.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
