/** Overview report tab showing report metadata */
import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { addCircleOutline, chevronDownCircleOutline, closeCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { FirebaseTimestamp, ReportType } from '../../model';

import { ORYXCardHeader } from '../../components/SectionHeader';
import { useEffect, useRef, useState } from 'react';

import { useReportContext } from '../../components/reportContext';
import ORYX_EditIcon from '../../assets/icons/ORYX_EditIcon.svg';
import { FileInfo } from '../../model/reportV2.model';
import { Toast } from '../../components/Toast/Toast';

type OverviewReportProps = {
  id: string;
  fileInfo: FileInfo;
  reportType: ReportType;
};
export const getActivity = (reportType: ReportType | undefined) => {
  switch (reportType) {
    case 1:
      return 'Walk';
    case 2:
      return 'Run';
    case 3:
      return 'Squat';
    case 4:
      return 'Single Leg Squat';
    default:
      return 'Error';
  }
};

function convertDate(timestamp: FirebaseTimestamp) {
  const datestring = timestamp.toDate().toLocaleString();
  return datestring;
}

/** Overview report tab showing report metadata
 * @param report - The full report data, including reportInfo data
 */
export const OverviewReportTab = ({ id, fileInfo, reportType }: OverviewReportProps) => {
  const [isTouched, setIsTouched] = useState(false);
  const [addTags, setAddTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    editTags,
    isCompleted,
    isError,
    clearError,
    tags,
    fileListener,
    reportName,
    editReportName,
    notes,
    addReportNotes,
    editReportNotes,
  } = useReportContext();
  const [editName, setEditName] = useState(false);
  const [loadingReportName, setLoadingReportName] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [editNote, setEditNote] = useState<{ show: boolean; note: number | undefined; index: number }>({
    show: false,
    note: undefined,
    index: 0,
  });

  // if (import.meta.env.MODE === 'development') console.log('OverviewReportTab: data', data);
  const activity = getActivity(reportType);

  const uploadDate = new Date(fileInfo.uploadTimestamp * 1000);
  const uploadDateString = uploadDate?.toLocaleString();
  useEffect(() => {
    if (fileInfo) {
      fileListener(id);
    }
  }, [fileInfo]);

  useEffect(() => {
    console.log('Notes! ', notes);
  }, [notes]);

  // #region Tags
  const handleInputTags = (ev: Event) => {
    // If no comma is found, use the string as a single tag in the array
    // if a comma is found, split the string into an array of tags
    const input = (ev.target as HTMLInputElement).value;
    if (input.includes(', ')) {
      localStorage.setItem('tags', JSON.stringify([input]));
    } else {
      localStorage.setItem('tags', JSON.stringify([input]));
    }
  };

  // function triggered when user leaves the Tag input field
  const handleAddTags = () => {
    setLoading(true);
    const tagsLocalStorage = localStorage.getItem('tags');
    if (tagsLocalStorage) {
      // create array from the local storage string
      const tagsArray = JSON.parse(tagsLocalStorage!);
      const tagsToUpload = tagsArray[0].split(', ');
      setTimeout(() => {
        editTags(id, tagsToUpload);
        setAddTags(false);
        setIsTouched(false);
        localStorage.removeItem('tags');
        setLoading(false);
      }, 1000);
    }
  };

  // handle tags deletion
  const handleTagDelete = (index: number) => {
    // remove the tag from the array and update the db
    // if there is only 1 tag in the array, replace the entry with a blank string
    if (tags.length === 1) {
      editTags(id, ['']);
    }
    // if there are more than 1 tags in the array, remove the tag at the index
    else {
      const newTags = tags.filter((tag, i) => i !== index);
      editTags(id, newTags);
    }
  };

  // #endregion

  // #region Report Name
  const handleInputReportName = (ev: Event) => {
    const input = (ev.target as HTMLInputElement).value;
    localStorage.setItem('reportName', input);
  };

  const handleAddReportName = () => {
    setLoadingReportName(true);
    const reportNameLocalStorage = localStorage.getItem('reportName');
    if (reportNameLocalStorage) {
      setTimeout(() => {
        editReportName(id, reportNameLocalStorage);
        localStorage.removeItem('reportName');
        setLoadingReportName(false);
        setEditName(false);
      }, 1000);
    }
  };

  // #endregion

  // #region Note
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  useEffect(() => {
    if (!accordionGroup.current) {
      return;
    }
    // Set the IDs of the accordion items you want to open (e.g., 'accordion-0' and 'accordion-2')
    accordionGroup.current.value = ['accordion-0'];
  }, []);
  // #endregion
  useEffect(() => {
    if (import.meta.env.MODE) console.log('db Tags', tags);
    if (import.meta.env.MODE) console.log('db reportName', reportName);
  }, [tags, reportName]);

  if (!fileInfo) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <Toast isOpen={isCompleted} message={'File updated successfully!'} type={'info'} />

        <IonAlert
          isOpen={addNote}
          header='Please enter your note'
          className='informativeAlert'
          trigger='add-note'
          onDidDismiss={() => setAddNote(false)}
          buttons={[
            { text: 'Cancel', role: 'cancel', cssClass: 'destructiveAlertButton', handler: () => setAddNote(false) },
            { text: 'Save', handler: (alertData) => addReportNotes(id, alertData.note) },
          ]}
          inputs={[
            {
              name: 'note',
              type: 'textarea',
              placeholder: 'Add note here...',
            },
          ]}
        />

        <IonAlert
          isOpen={editNote.show}
          header='Please edit your note'
          className='informativeAlert'
          buttons={[
            { text: 'Cancel', role: 'cancel', cssClass: 'destructiveAlertButton' },
            { text: 'Save', handler: (alertData) => editReportNotes(id, alertData.note, editNote.index) },
          ]}
          inputs={[
            {
              name: 'note',
              type: 'textarea',
              placeholder: `${editNote.note}`,
            },
          ]}
        />

        <IonRow className=''>
          <IonCol size='6'>
            {/* file Info */}
            <IonCard style={{ height: '100%' }}>
              <ORYXCardHeader title={'File info'} />
              <IonCardContent className='reportcardContent'>
                <IonRow className='ion-align-items-center'>
                  <IonCol size='4' className=''>
                    <strong>Upload date: </strong>
                  </IonCol>
                  <IonCol size='8' className=''>
                    {uploadDateString}
                  </IonCol>
                </IonRow>

                <IonRow className='ion-align-items-center'>
                  <IonCol size='4'>
                    <strong>Filename: </strong>
                  </IonCol>
                  <IonCol size='8'>{fileInfo.filename}</IonCol>
                </IonRow>

                <IonRow className='ion-align-items-center'>
                  <IonCol size='4'>
                    <strong>Activity: </strong>
                  </IonCol>
                  <IonCol size='8'>{activity}</IonCol>
                </IonRow>

                <IonRow className='ion-align-items-center'>
                  <IonCol size='4'>
                    <strong>Reportname: </strong>
                  </IonCol>
                  {!editName ? (
                    <>
                      <IonCol size='7'>{reportName}</IonCol>
                      <IonCol size='1' className='ion-text-center ion-align-items-center'>
                        <IonIcon
                          icon={ORYX_EditIcon}
                          style={{ display: 'block', margin: 'auto' }}
                          color='primary'
                          onClick={() => setEditName(true)}
                        />
                      </IonCol>{' '}
                    </>
                  ) : (
                    <>
                      <IonCol size='7'>
                        <IonInput
                          className='create_ListInput'
                          placeholder='Enter (new) reportname'
                          type='text'
                          spellCheck={false}
                          autocapitalize='on'
                          onIonInput={(e) => {
                            handleInputReportName(e);
                          }}
                          onIonBlur={() => setEditName(false)}
                          fill='outline'
                          clearInput={true}
                          mode='ios'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleAddReportName();
                              setEditName(false);
                            }
                          }}
                        ></IonInput>
                      </IonCol>
                      {loadingReportName && (
                        <IonCol size='1' className='ion-text-center ion-align-items-center Test'>
                          <IonSpinner name='bubbles' className='' color='primary' />
                        </IonCol>
                      )}
                    </>
                  )}
                </IonRow>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size='6'>
            {/* Tags */}
            <IonCard style={{ height: '100%' }}>
              <ORYXCardHeader title={'Tags'}>
                <IonButton icon-only fill='clear' onClick={() => setAddTags(true)}>
                  <IonIcon src={addCircleOutline} />
                </IonButton>
              </ORYXCardHeader>
              <IonCardContent className='reportcardContent'>
                <IonRow className='ion-align-items-center'>
                  <IonCol size='12' className='ion-text-center'>
                    {tags[0] != '' ? (
                      <>
                        {tags.map((tag: any, index: any) => (
                          <IonChip key={tag}>
                            {tag}
                            <IonIcon
                              icon={closeCircleOutline}
                              color='warning'
                              onClick={() => handleTagDelete(index)}
                            ></IonIcon>
                          </IonChip>
                        ))}
                      </>
                    ) : (
                      <>
                        <IonItem className='' lines='none'>
                          <IonLabel className=''>No tags found</IonLabel>
                        </IonItem>
                      </>
                    )}
                  </IonCol>
                </IonRow>
                <IonList lines='none'>
                  {addTags && (
                    <>
                      <IonItem>
                        <IonInput
                          className='ORYX_Input'
                          label='Tags'
                          inputMode='text'
                          onIonBlur={() => setAddTags(false)}
                          labelPlacement='stacked'
                          type='text'
                          spellCheck={false}
                          autocapitalize='off'
                          onIonInput={(e) => {
                            handleInputTags(e);
                          }}
                          helperText='Seperate multiple tags by a comma'
                          clearInput={true}
                          mode='ios'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') handleAddTags();
                          }}
                        ></IonInput>
                      </IonItem>
                      <IonRow className='ion-justify-content-between ion-align-items-center'>
                        <IonCol size='11' className='ion-padding-horizontal'>
                          {/* <IonButton onClick={handleAddTags} expand='block' fill='outline' color='primary' /> */}
                        </IonCol>

                        <IonCol size='1' className='ion-no-padding ion-text-center'>
                          {loading ? (
                            <IonSpinner name='bubbles' className='' color='primary' />
                          ) : (
                            <IonIcon src={closeCircleOutline} color='warning' onClick={() => setAddTags(false)} />
                          )}
                        </IonCol>
                      </IonRow>
                    </>
                  )}
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow className='ion-margin-bottom'>
          <IonCol size='12'>
            {/* Notes */}
            <IonCard style={{ height: '100%', marginBottom: '20px' }}>
              <ORYXCardHeader title={'Notes'}>
                <IonButton icon-only fill='clear' onClick={() => setAddNote(true)} id='add-note'>
                  <IonIcon src={addCircleOutline} />
                </IonButton>
              </ORYXCardHeader>
              <IonCardContent className='reportcardContent'>
                <IonRow>
                  <IonCol size='12'>
                    {notes[0] == '' ? (
                      <IonText>No notes found</IonText>
                    ) : (
                      <>
                        <IonAccordionGroup mode='md' expand='inset' ref={accordionGroup} multiple={true}>
                          {notes.reverse().map((note: any, index: any) => (
                            <IonAccordion
                              key={index}
                              toggleIcon={chevronDownCircleOutline}
                              value={`accordion-${index}`}
                            >
                              <IonItem slot='header' className='ion_accordion_item'>
                                <IonText>{convertDate(note.createdOn)}</IonText>
                              </IonItem>
                              <IonRow className='ion-padding ion-align-items-center' slot='content'>
                                <IonCol size='11'>{note.note}</IonCol>
                              </IonRow>
                            </IonAccordion>
                          ))}
                        </IonAccordionGroup>
                      </>
                    )}
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </>
    );
  }
};
