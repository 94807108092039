import { ComponentProps, PropsWithChildren } from 'react';
import { IonItemDivider } from '@ionic/react';

import styles from './Divider.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface DividerProps extends ComponentProps<typeof IonItemDivider> {
  direction?: 'horizontal' | 'vertical';
}

export const Divider = ({ direction = 'horizontal', children, ...props }: PropsWithChildren<DividerProps>) => (
  <IonItemDivider className={mergeClassNames(styles.divider, styles[direction])}>{children}</IonItemDivider>
);
