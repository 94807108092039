import { IconProps } from '..';

export const IconSliders = ({ width = 20, height = 20, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      viewBox='0 0 24 24'
    >
      <line x1='4' y1='21' x2='4' y2='14'></line>
      <line x1='4' y1='10' x2='4' y2='3'></line>
      <line x1='12' y1='21' x2='12' y2='12'></line>
      <line x1='12' y1='8' x2='12' y2='3'></line>
      <line x1='20' y1='21' x2='20' y2='16'></line>
      <line x1='20' y1='12' x2='20' y2='3'></line>
      <line x1='1' y1='14' x2='7' y2='14'></line>
      <line x1='9' y1='8' x2='15' y2='8'></line>
      <line x1='17' y1='16' x2='23' y2='16'></line>
    </svg>
  );
};
