import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react';

import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { colors } from '../../../../theme/colors';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../../components/CardHeader/cardHeader';
import { ReportModal } from '../../../../components/Modal/ReportModal';
import { JointCouplingModalGraph } from './jointCouplingModalGraph';

type JointCouplingGraphProps = {
  data: any;
  title: string;
  zoomModalContent?: any;
  xMin: number;
  xMax: number;
  xRange: number;
};

export const JointCouplingGraph = ({ data, title, zoomModalContent, xMin, xMax, xRange }: JointCouplingGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const markerLabels = ['HO', 'TO', 'MSw', 'TSw', 'IC', 'MSt'];
  // create marker array so that only the scatter has markers
  const markerSizes = [];
  const markerColors = [];
  for (const value of Object.values(data)) {
    const Value = value as unknown as any;
    const type = Value.type;
    if (type === 'scatter') {
      markerSizes.push(3);
      markerColors.push('#E81A1A');
    } else {
      markerSizes.push(0);
      markerColors.push('#E81A1A');
    }
  }

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [data.length - 2],
      formatter: function (val: number, { seriesIndex, dataPointIndex, w }) {
        return markerLabels[dataPointIndex];
      },
      textAnchor: 'middle',
      offsetY: -5,
      background: {
        enabled: false,
      },
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
        colors: ['#707070'],
      },
    },
    xaxis: {
      min: xMin,
      max: xMax,
      type: 'numeric',
      tickAmount: xRange,
      labels: {
        formatter: function (value: string) {
          const Value = value as unknown as number;
          return Value.toFixed(0);
        },
        show: true,
        style: {
          fontSize: '8px',
        },
        offsetY: -5,
      },
      title: {
        text: 'Hip Angle (deg)',
        style: {
          fontSize: '8px',
          color: '#707070',
        },
      },
    },
    yaxis: {
      stepSize: 25,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
      title: {
        text: 'Knee Angle (deg)',
        style: {
          fontSize: '8px',
          color: '#707070',
        },
        offsetX: 5,
      },
    },
    // annotations: {
    //   xaxis: [
    //     {
    //       x: 0,
    //       borderColor: '#c2c2c2',
    //       strokeDashArray: 5,
    //     },
    //   ],
    //   yaxis: [
    //     {
    //       y: 0,
    //       borderColor: '#c2c2c2',
    //       strokeDashArray: 5,
    //     },
    //   ],
    // },
    stroke: {
      width: 1,
    },
    colors: colors,
    markers: {
      size: markerSizes,
      colors: markerColors,
      strokeWidth: 1,
      showNullDataPoints: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const [showZoomModal, setShowZoomModal] = useState({
    show: false,
  });

  return (
    <>
      <ReportModal
        isOpen={showZoomModal.show}
        onDidDismiss={() => setShowZoomModal({ show: false })}
        header={`${title}`}
        setShowInfo={() => setShowZoomModal({ show: false })}
      >
        <IonGrid className='' style={{ height: '100%' }}>
          <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
            <IonCol size='6' className='' style={{ height: '50%' }}>
              <JointCouplingModalGraph
                data={zoomModalContent.seriesJointCoupling_Left}
                side={'Left'}
                xMin={xMin}
                xMax={xMax}
                xRange={xRange}
              />
            </IonCol>
            <IonCol size='6' style={{ height: '50%' }} className='ion-align-items-center'>
              <JointCouplingModalGraph
                data={zoomModalContent.seriesJointCoupling_Right}
                side={'Right'}
                xMin={xMin}
                xMax={xMax}
                xRange={xRange}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </ReportModal>

      <Card style={{ height: '100%', margin: 0 }} onClick={() => setShowZoomModal({ show: true })}>
        <CardHeader title={title} />
        <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
          <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Chart
              options={options}
              series={data}
              type='line'
              width='100%'
              height={windowSize == 'small' ? 125 : 150}
            />
          </div>
        </IonCardContent>
      </Card>
    </>
  );
};
