import { IconProps } from '..';

export const IconSpinner = ({ width = 18, height = 18, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.8' cx='16.0743' cy='9.16959' r='1.68174' fill={color} />
      <circle opacity='0.7' cx='13.9119' cy='14.3914' r='1.60626' fill={color} />
      <circle opacity='0.6' cx='8.68978' cy='16.5545' r='1.44564' fill={color} />
      <circle opacity='0.5' cx='3.46728' cy='14.3915' r='1.30107' transform='rotate(45 3.46728 14.3915)' fill={color} />
      <circle opacity='0.4' cx='1.30524' cy='9.32899' r='1.17097' fill={color} />
      <circle opacity='0.3' cx='3.35498' cy='4.06053' r='1.05387' transform='rotate(45 3.35498 4.06053)' fill={color} />
      <circle cx='8.69001' cy='1.78474' r='1.78474' fill={color} />
      <circle opacity='0.9' cx='13.9122' cy='3.94764' r='1.78474' fill={color} />
    </svg>
  );
};
