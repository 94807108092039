import { useEffect, useState } from 'react';
import { colorLeft, colorRight } from '../../theme/colors';

import styles from './LegendToast.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

type legendToastProps = {
  isOpen: boolean;
  onClose: () => void;
  duration?: number;
};

/**
 * LegendToast component displays a toast notification with legend entries.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.isOpen - Determines if the toast is open.
 * @param {function} props.onClose - Function to call when the toast closes.
 * @param {number} [props.duration=5000] - Duration in milliseconds before the toast automatically closes.
 *
 * @returns {JSX.Element | null} The rendered LegendToast component or null if not open.
 */
export const LegendToast = ({ isOpen, onClose, duration = 5000 }: legendToastProps) => {
  const [isClosing, setIsClosing] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
      const timer = setTimeout(() => {
        setIsClosing(true);
        setTimeout(onClose, 500);
      }, duration);

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose, duration]);

  if (!isOpen && !isClosing) return null;

  return (
    isOpen && (
      <div className={mergeClassNames(styles.legendToast, isClosing ? styles['slide-out'] : undefined)}>
        <div className={styles.legendToast_Container} style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={styles.legendToast_Entry}>
            <div className={styles.legend_dot} style={{ backgroundColor: colorLeft }}></div>
            <p className={styles.legend_name}>Left</p>
          </div>
          <div className={styles.legendToast_Entry}>
            <div className={styles.legend_dot} style={{ backgroundColor: colorRight }}></div>
            <p className={styles.legend_name}>Right</p>
          </div>
        </div>
      </div>
    )
  );
};
