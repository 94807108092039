/** The Run Kinematics report tab */
import { IonCol, IonRow, IonText } from '@ionic/react';

// Components
import { AttractorCard } from '../components/Cards';

// Images
import ankleStiffness from '../../../assets/avatars/run/analysis/AnkleStiffness.png';
import footPlant from '../../../assets/avatars/run/analysis/FootPlant.png';
import hipLock from '../../../assets/avatars/run/analysis/HipLock.png';
import kneeAngleMidswing from '../../../assets/avatars/run/analysis/KneeAngleMidswing.png';
import kneeExtensionPreswing from '../../../assets/avatars/run/analysis/KneeExtensionPreswing.png';
import kneeFlexionMidstance from '../../../assets/avatars/run/analysis/kneeFlexionMidstance.png';
import positiveRunningMidstance from '../../../assets/avatars/run/analysis/PositiveRunningMidstance.png';
import positiveRunningToeOff from '../../../assets/avatars/run/analysis/PositiveRunningToeOff.png';
import projectionFootplant from '../../../assets/avatars/run/analysis/ProjectionFootplant.png';
import scissors from '../../../assets/avatars/run/analysis/Scissors.png';
import swingLegRecovery from '../../../assets/avatars/run/analysis/SwingLegRecovery.png';
import swingLegRetraction from '../../../assets/avatars/run/analysis/SwingLegRetraction.png';

// Hooks & Models
import { RunAttractorData } from '../../../model/reportV2.model';

// Styles
import {
  AnkleStiffnessModalContent,
  HipLockModalContent,
  KneeExtenstionTerminalStanceRunModalContent,
  SwingLegRecoveryModalContent,
  KneeFlexionMidSwingRunModalContent,
  SwingLegRetractionModalContent,
  ScissorsModalContent,
  FootplantFromAboveModalContent,
  ProjectionFootplantModalContent,
  KneeFlexionMidstanceRunModalContent,
  PositiveRunningMidstanceModalContent,
  PositiveRunningToeOffModalContent,
} from '../components/Modals';

type AttractorsReportProps = {
  tabData: RunAttractorData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunAttractorsReportTab = ({ tabData }: AttractorsReportProps) => {
  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      // <IonPage>
      //   <IonContent className={styles.content} id='main' color='light'>
      //     <IonGrid fixed style={{ height: '100%' }}>

      <>
        <IonRow>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Ankle stiffness'
              subtitle='Terminal stance'
              info={<AnkleStiffnessModalContent />}
              img={ankleStiffness}
              labels={tabData.attractorLabels[1]}
              attractorRow={tabData.attractorData[1]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Knee extension'
              subtitle='Terminal stance'
              info={<KneeExtenstionTerminalStanceRunModalContent />}
              img={kneeExtensionPreswing}
              labels={tabData.attractorLabels[2]}
              attractorRow={tabData.attractorData[2]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Hip lock'
              subtitle='Terminal stance'
              info={<HipLockModalContent />}
              img={hipLock}
              labels={tabData.attractorLabels[3]}
              attractorRow={tabData.attractorData[3]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Positive running'
              subtitle='Toe-off'
              info={<PositiveRunningToeOffModalContent />}
              img={positiveRunningToeOff}
              labels={tabData.attractorLabels[0]}
              attractorRow={tabData.attractorData[0]}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Swing leg recovery'
              subtitle='Initial swing'
              info={<SwingLegRecoveryModalContent />}
              img={swingLegRecovery}
              labels={tabData.attractorLabels[5]}
              attractorRow={tabData.attractorData[5]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Knee flexion'
              subtitle='Midswing'
              info={<KneeFlexionMidSwingRunModalContent />}
              img={kneeAngleMidswing}
              labels={tabData.attractorLabels[6]}
              attractorRow={tabData.attractorData[6]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Swing leg retraction'
              subtitle='Terminal Swing'
              info={<SwingLegRetractionModalContent />}
              img={swingLegRetraction}
              labels={tabData.attractorLabels[9]}
              attractorRow={tabData.attractorData[9]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Scissors motion'
              subtitle='Terminal swing'
              info={<ScissorsModalContent />}
              img={scissors}
              labels={tabData.attractorLabels[10]}
              attractorRow={tabData.attractorData[10]}
            />
          </IonCol>
        </IonRow>

        <IonRow className='ion-padding-bottom'>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Footplant from above'
              subtitle='Midstance'
              info={<FootplantFromAboveModalContent />}
              img={footPlant}
              labels={tabData.attractorLabels[11]}
              attractorRow={tabData.attractorData[11]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Projection footplant'
              subtitle='Midstance'
              info={<ProjectionFootplantModalContent />}
              img={projectionFootplant}
              labels={tabData.attractorLabels[8]}
              attractorRow={tabData.attractorData[8]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Knee flexion'
              subtitle='Midstance'
              info={<KneeFlexionMidstanceRunModalContent />}
              img={kneeFlexionMidstance}
              labels={tabData.attractorLabels[14]}
              attractorRow={tabData.attractorData[14]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Positive running'
              subtitle='Midstance'
              info={<PositiveRunningMidstanceModalContent />}
              img={positiveRunningMidstance}
              labels={tabData.attractorLabels[7]}
              attractorRow={tabData.attractorData[7]}
            />
          </IonCol>
        </IonRow>
      </>
      //     </IonGrid>
      //   </IonContent>
      // </IonPage>
    );
  }
};
