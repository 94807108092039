import { IonText } from '@ionic/react';

import styles from './DataTable.module.css';

interface DataTableHeaderProps {
  children: React.ReactNode;
  title?: string;
}

export const DataTableSection = ({ children, title }: DataTableHeaderProps) => {
  return (
    <div className={styles.tableSection}>
      {title && <IonText className={styles.sectionTitle}>{title}</IonText>}
      {children}
    </div>
  );
};
