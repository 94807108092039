export const ORYXLogo = () => (
  <svg width='62' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.13 15.665c1.597 0 2.965-.592 3.932-1.614.966-1.022 1.517-2.457 1.517-4.12 0-1.662-.55-3.104-1.515-4.124-.965-1.022-2.33-1.609-3.919-1.609s-2.95.591-3.912 1.615c-.96 1.022-1.506 2.456-1.506 4.118 0 1.662.542 3.104 1.499 4.124.958 1.022 2.315 1.61 3.905 1.61Zm.015-9.119c.894 0 1.608.326 2.103.894.498.573.795 1.419.795 2.49 0 1.073-.297 1.904-.796 2.481-.495.573-1.21.905-2.102.905-.893 0-1.609-.326-2.103-.894-.498-.573-.795-1.419-.795-2.49 0-1.073.297-1.919.795-2.491.494-.569 1.208-.894 2.103-.894v-.001Z'
      fill='#0A1414'
    />
    <path
      d='M46.578 8.368a5.478 5.478 0 0 0-.12.232 7.24 7.24 0 0 0-.124-.233l-2.085-3.831a.29.29 0 0 0-.255-.15h-2.155a.24.24 0 0 0-.21.357l3.581 6.387v4.074c0 .16.13.288.29.288h1.855c.16 0 .29-.129.29-.288V11.13l3.505-6.276 3.399 5.09-3.535 5.173a.24.24 0 0 0 .2.375h2.264a.292.292 0 0 0 .241-.128l2.316-3.453c.336.594.66 1.126 1.04 1.708l.002.002 1.19 1.745a.29.29 0 0 0 .24.127h2.277a.24.24 0 0 0 .202-.372l-3.423-5.189 3.408-5.174a.24.24 0 0 0-.202-.371h-2.248a.29.29 0 0 0-.245.134l-2.2 3.449a23.115 23.115 0 0 0-.966-1.567V6.4l-1.377-2.014h-4.828a.29.29 0 0 0-.255.152L46.578 8.37v-.002Z'
      fill='#0A1414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.677 15.491a.288.288 0 0 1-.29-.288V4.672c0-.16.129-.288.289-.288h4.043c1.248 0 2.277.314 2.997.935.726.626 1.103 1.532 1.103 2.638 0 .752-.178 1.414-.536 1.96-.307.469-.74.84-1.28 1.107l1.923 4.126a.24.24 0 0 1-.22.341h-2.104a.29.29 0 0 1-.263-.168l-1.72-3.764h-1.798v3.644c0 .159-.13.288-.29.288h-1.854Zm2.144-8.888v2.752h1.898c.538 0 .928-.142 1.18-.369.249-.223.4-.57.4-1.029 0-.459-.15-.784-.396-.998-.252-.22-.642-.356-1.184-.356h-1.898Z'
      fill='#0A1414'
    />
    <path
      d='M11.198 1.467c0 .81-.66 1.466-1.475 1.466a1.471 1.471 0 0 1-1.475-1.466C8.248.657 8.908 0 9.723 0a1.47 1.47 0 0 1 1.475 1.467ZM14.484 5.333c0 1.031-.84 1.867-1.877 1.867a1.872 1.872 0 0 1-1.878-1.867c0-1.03.84-1.866 1.878-1.866 1.037 0 1.877.835 1.877 1.866ZM15.289 12.133A2.14 2.14 0 0 0 17.435 10a2.14 2.14 0 0 0-2.146-2.133A2.14 2.14 0 0 0 13.143 10a2.14 2.14 0 0 0 2.146 2.133ZM9.723 11.733c.963 0 1.744-.776 1.744-1.733 0-.957-.781-1.733-1.744-1.733S7.98 9.043 7.98 10c0 .957.78 1.733 1.743 1.733ZM7.242 6.667c.74 0 1.341-.597 1.341-1.334 0-.736-.6-1.333-1.34-1.333C6.5 4 5.9 4.597 5.9 5.333c0 .737.6 1.334 1.341 1.334ZM6.17 10c0 .736-.601 1.333-1.342 1.333-.74 0-1.341-.597-1.341-1.333s.6-1.333 1.341-1.333c.74 0 1.341.597 1.341 1.333ZM5.221 2.41a.945.945 0 0 0 .948-.943.945.945 0 0 0-.948-.943.945.945 0 0 0-.948.943c0 .52.424.942.948.942ZM6.17 18.533c0 .52-.425.943-.949.943a.945.945 0 0 1-.948-.943c0-.52.424-.942.948-.942s.948.422.948.942ZM.939 10.933A.936.936 0 0 0 1.878 10a.936.936 0 0 0-.94-.933A.936.936 0 0 0 0 10c0 .515.42.933.939.933ZM8.583 14.667c0 .736-.6 1.333-1.34 1.333C6.5 16 5.9 15.403 5.9 14.667c0-.737.6-1.334 1.341-1.334.74 0 1.341.597 1.341 1.334ZM9.723 20a1.47 1.47 0 0 0 1.475-1.467c0-.81-.66-1.466-1.475-1.466a1.47 1.47 0 0 0-1.475 1.466c0 .81.66 1.467 1.475 1.467ZM14.484 14.667c0 1.03-.84 1.866-1.877 1.866a1.872 1.872 0 0 1-1.878-1.866c0-1.031.84-1.867 1.878-1.867 1.037 0 1.877.836 1.877 1.867Z'
      fill='#90C953'
    />
  </svg>
);
