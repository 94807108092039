import React, { useContext, useState } from 'react';

type LoadingContextProps = {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
};

const LoadingContext = React.createContext<LoadingContextProps>({ isLoading: false, setLoading: () => {} });

interface LoadingContextProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingContextProviderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  return <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => useContext(LoadingContext);
