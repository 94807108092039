import { registerPlugin } from '@capacitor/core';

import type { NativeQSenseControlPlugin } from './definitions';

const NativeQSenseControl = registerPlugin<NativeQSenseControlPlugin>('NativeQSenseControl', {
  web: () => import('./web').then((m) => new m.NativeQSenseControlWeb()),
});

export * from './definitions';
export { NativeQSenseControl };
