import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { GraphSeriesData, KinematicYAxis } from '../../../../model/reportV2.model';
import styles from './ApexCharts.module.css';
import { Loading } from '../../../../components/Loading/Loading';
import { ReportModal } from '../../../../components/Modal/ReportModal';

type FunctionalMovementGraphProps = {
  data: GraphSeriesData[];
  title?: string;
  subtitle: string;
  strokeWidth: number;
  forPdf?: boolean;
  yAxisPdf?: any;
  yAxis: KinematicYAxis;
};

export const FunctionalMovementKinematicsGraph = React.forwardRef(
  (
    { data, title, subtitle, strokeWidth, forPdf = false, yAxisPdf, yAxis }: FunctionalMovementGraphProps,
    ref: React.ForwardedRef<Chart>,
  ) => {
    const [showModal, setShowModal] = useState({ show: false });
    const [showLegend, setShowLegend] = useState(false);
    const [loading, setLoading] = React.useState(!forPdf ? true : false);
    const options: ApexOptions = {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        offsetX: forPdf ? -5 : -10,
      },
      xaxis: {
        type: 'numeric',
        labels: {
          style: {
            fontSize: '8px',
          },
        },
      },
      yaxis: {
        show: true,
        min: yAxis.yMin,
        max: yAxis.yMax,
        tickAmount: yAxis.tickAmount,
        labels: {
          formatter: function (value: number) {
            return value.toFixed(0);
          },
          style: {
            fontSize: '8px',
          },
        },
        axisTicks: {
          show: true,
        },
      },
      grid: {
        show: true,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      // title: {
      //   text: !forPdf ? title : '',
      //   align: 'center',
      //   margin: 0,
      //   style: {
      //     fontSize: '14px',
      //     fontWeight: 'bold',
      //     fontFamily: 'Montserrat',
      //     color: '#0A1414',
      //   },
      // },
      subtitle: {
        text: subtitle,
        align: 'center',
        margin: forPdf ? 5 : -10,
        floating: forPdf ? true : false,
        offsetY: forPdf ? 5 : 25,
        style: {
          fontSize: '8px',
          fontFamily: 'Montserrat',
        },
      },

      stroke: {
        width: strokeWidth,
        dashArray: [0],
      },
      legend: {
        show: showLegend,
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '9px',
        markers: {
          width: 8,
          height: 8,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: 'red',
          },
        ],
      },
      markers: {
        size: [0, 0, 2],
        strokeWidth: 0,
        showNullDataPoints: false,
      },
      tooltip: {
        enabled: false,
      },
    };

    useEffect(() => {
      if (!forPdf) {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }, [data]);

    return (
      <>
        {!forPdf && title && (
          <ReportModal
            isOpen={showModal.show}
            onDidDismiss={() => {
              setShowModal({ show: false });
              setShowLegend(false);
            }}
            header={`${title} Movement`}
            setShowInfo={() => setShowModal({ show: false })}
          >
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' className=''>
                <Chart ref={ref} options={options} series={data} type='line' height='250%' />
              </IonCol>
            </IonRow>
          </ReportModal>
        )}

        <div
          className={styles.kinematicsGraph_Wrapper}
          onClick={() => {
            setShowModal({ show: true });
            setTimeout(() => {
              setShowLegend(true);
            }, 200);
          }}
        >
          {!loading ? (
            <Chart
              ref={ref}
              options={options}
              series={data}
              type='line'
              height={forPdf ? 'auto' : '100%'}
              width={forPdf ? 250 : '100%'}
            />
          ) : (
            <Loading />
          )}
        </div>
      </>
    );
  },
);
