/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Homepage.
 */

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonModal,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSpinner,
  IonText,
  isPlatform,
  useIonAlert,
  useIonToast,
} from '@ionic/react';
import { checkmarkCircleSharp } from 'ionicons/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ORYXHeader } from '../../../components/menuItems/Header';

import { useAuthContext } from '../../../components/authContext';
import { ORYXCardHeader } from '../../../components/SectionHeader';
import { uuid } from '../../../utilities';
import { dataType, NativeQSenseControl } from '../../../plugins/NativeQSenseControl';
import { Button } from '../../../components/Button';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

interface AccelerometerGraphProps {
  data: Array<{ time: number; x: number; y: number; z: number }>;
}

const AccelerometerGraph: React.FC<AccelerometerGraphProps> = ({ data }) => {
  return (
    <LineChart width={800} height={400} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='time' label={{ value: 'Time', position: 'insideBottom', offset: -10 }} />
      <YAxis label={{ value: 'Acceleration', angle: -90, position: 'insideLeft' }} />
      <Tooltip />
      <Legend />
      <Line type='monotone' dataKey='x' stroke='#8884d8' name='X-axis' />
      <Line type='monotone' dataKey='y' stroke='#82ca9d' name='Y-axis' />
      <Line type='monotone' dataKey='z' stroke='#ffc658' name='Z-axis' />
    </LineChart>
  );
};

export const SensorTestingPage = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const platform = isPlatform('capacitor') ? 'capacitor' : 'web';
  const userId = useAuthContext().state.userState?.user.uid;
  const [presentAlert] = useIonAlert();
  const [scanning, setScanning] = useState(false);
  const [discoveredDevices, setDiscoveredDevices] = useState<string[]>([]);
  const [connectedDevices, setConnectedDevices] = useState<string[]>([]);
  const [motionLevels, setMotionLevels] = useState<{ [device: string]: number }>({});
  const [batteryLevels, setBatteryLevels] = useState<{ [device: string]: number }>({});
  const [present] = useIonToast();
  const [data, setData] = useState([]);

  const allowedDevices = ['FE:82:64:29:48:E9', 'ED:9B:08:6A:C2:C7', 'FB:8F:72:1D:63:9B'];

  useEffect(() => {
    if (platform !== 'capacitor' && import.meta.env.MODE !== 'development') {
      presentAlert({
        header: 'Not on Native app',
        subHeader: 'Not implementend on web',
        message: 'This functionality is only available on the native app',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              setTimeout(() => {
                history.push('/dashboard');
              }, 500);
            },
          },
        ],
      });
    }
  }, [platform]);

  async function fetchData(macAddress: string, dataType: dataType) {
    try {
      const result = await NativeQSenseControl.getData({ macAddress, dataType });
      console.log(`Received ${dataType} data:`, result.data);
      //  change each point ([double] containing x, y and z) to a datapoint of 3 lines: X, y and z
      const data = result.data.map((point: number[], index: number) => ({
        time: index,
        x: point[0],
        y: point[1],
        z: point[2],
      }));
      setData(data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  }

  // Add Listeners
  useEffect(() => {
    const onScanningListener = NativeQSenseControl.addListener('onScanningComplete', (data) => {
      setScanning(true);
    });

    const onDeviceDiscoveredListener = NativeQSenseControl.addListener('onDeviceDiscovered', (data) => {
      setDiscoveredDevices((prev) => [...prev, data.device]);
    });

    const onAllDevicesDiscoveredListener = NativeQSenseControl.addListener('onAllDevicesDiscovered', (data) => {
      present({
        message: 'All Sensors discovered',
        duration: 2000,
        position: 'bottom',
        color: 'success',
      });
    });

    const onDeviceConnectedListener = NativeQSenseControl.addListener('onDeviceConnected', (data) => {
      setConnectedDevices((prev) => [...prev, data.device]);
    });

    const onAllDevicesConnectedListener = NativeQSenseControl.addListener('onAllDevicesConnected', (data) => {
      present({
        message: 'All Sensors connected',
        duration: 2000,
        position: 'bottom',
        color: 'success',
      });
    });

    const onDeviceDisconnectedListener = NativeQSenseControl.addListener('onDeviceDisconnected', (data) => {
      setConnectedDevices((prev) => prev.filter((device) => device !== data.device));
      setDiscoveredDevices((prev) => prev.filter((device) => device !== data.device));
    });

    const onBatteryUpdateListener = NativeQSenseControl.addListener('onBatteryUpdate', (data) => {
      setBatteryLevels((prev) => ({
        ...prev,
        [data.device]: data.battery,
      }));
    });

    const onMotionUpdateListener = NativeQSenseControl.addListener('onMotionUpdate', (data) => {
      console.log('onMotionUpdateListener', data);
      setMotionLevels((prev) => ({
        ...prev,
        [data.device]: data.motion,
      }));
    });

    return () => {
      onScanningListener.remove();
      onDeviceDiscoveredListener.remove();
      onDeviceConnectedListener.remove();
      onDeviceDisconnectedListener.remove();
      onAllDevicesDiscoveredListener.remove();
      onBatteryUpdateListener.remove();
      onMotionUpdateListener.remove();
    };
  }, []);

  return (
    <>
      <IonModal isOpen={showModal}>
        <AccelerometerGraph data={data} />
      </IonModal>

      <IonPage id='main'>
        <ORYXHeader backTarget='/dashboard' />
        <IonContent className='ion-padding' id='main' fullscreen color='light'>
          <IonGrid fixed className='full_height'>
            <IonRow className='ion-no-padding full_height'>
              <IonCard className='' style={{ height: '95%' }}>
                <ORYXCardHeader title='Sensor Testing' />
                <IonCardContent className='' style={{ height: '95%' }}>
                  <IonRow className='full_height'>
                    <IonCol size='4' className=''>
                      <IonButton
                        className='primary'
                        onClick={() => {
                          console.log('Start Scan button clicked!');
                          NativeQSenseControl.startScan({ allowedDevices: allowedDevices })
                            .then((result) => setScanning(true))
                            .catch((error) => console.error(error));
                        }}
                      >
                        {scanning ? <IonSpinner name='bubbles' /> : 'Start Scanning'}
                      </IonButton>
                      <IonButton
                        className='primary'
                        onClick={() => {
                          console.log('Stop Scan button clicked!');
                          NativeQSenseControl.stopScan()
                            .then((result) => setScanning(false))
                            .catch((error) => console.error(error));
                        }}
                        disabled={!scanning}
                      >
                        Stop scanning
                      </IonButton>
                      <IonButton className='primary' onClick={() => NativeQSenseControl.connectDevices()}>
                        Connect Sensors
                      </IonButton>
                      <IonButton className='primary' onClick={() => NativeQSenseControl.setDataMode()}>
                        Set DataMode
                      </IonButton>
                      <IonButton className='primary' onClick={() => NativeQSenseControl.startStreaming()}>
                        Start Streaming
                      </IonButton>
                      <IonButton className='primary' onClick={() => NativeQSenseControl.stopStreaming()}>
                        Stop Streaming
                      </IonButton>
                      <IonButton
                        className='primary'
                        onClick={() => {
                          NativeQSenseControl.disConnectDevices();
                          console.log('Disconnect Sensors button clicked!');
                        }}
                      >
                        Disconnect Sensors
                      </IonButton>
                    </IonCol>
                    <IonCol size='8' className=''>
                      {discoveredDevices.map((device) => (
                        <IonRow key={uuid()}>
                          <IonCol>
                            <IonText>
                              {device}{' '}
                              {connectedDevices.includes(device) ? (
                                <IonIcon icon={checkmarkCircleSharp} color='success' />
                              ) : null}
                            </IonText>
                          </IonCol>
                          {connectedDevices.includes(device) && (
                            <>
                              <IonCol>
                                <IonText>{batteryLevels[device] || 0}%</IonText>
                              </IonCol>
                              <IonCol>
                                <Button onClick={() => fetchData(device, 'accelerometer')}>Fetch Accelerometer</Button>
                              </IonCol>
                              <IonCol>
                                <Button onClick={() => setShowModal(true)}>Show data</Button>
                              </IonCol>
                              <IonProgressBar color='secondary' value={motionLevels[device] || 0}></IonProgressBar>
                            </>
                          )}
                        </IonRow>
                      ))}
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};
