import { IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';

import { mergeClassNames } from '../../utilities/mergeClassNames';
import styles from './cardHeader.module.css';

export const KinematicsCardHeader = () => {
  return (
    <IonCardHeader className={styles.cardHeader}>
      <IonGrid fixed className='ion-no-padding'>
        <IonRow className={mergeClassNames('ion-align-items-center', styles['header'])}>
          <IonCol size='4' className='ion-text-center'>
            <IonCardTitle className={styles.title}>Sagittal</IonCardTitle>
          </IonCol>
          <IonCol size='4' className='ion-text-center'>
            <IonCardTitle className={styles.title}>Frontal</IonCardTitle>
          </IonCol>
          <IonCol size='3' className='ion-text-center'>
            <IonCardTitle className={styles.title}>Transversal</IonCardTitle>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardHeader>
  );
};
