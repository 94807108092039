import type { JSX as IonicJSX } from '@ionic/core';
import { IonButton } from '@ionic/react';
import { MouseEventHandler, PropsWithChildren } from 'react';

import { mergeClassNames } from '../../utilities/mergeClassNames';
import { Icon, IconName } from '../Icon';
import { IconRotate, IconSize } from '../Icon/types';

import styles from './button.module.css';

// This allows us to get the props of the IonButton so that the Button can be used as a drop-in replacement
type IonButtonProps = IonicJSX.IonButton;

interface ButtonProps extends Omit<IonButtonProps, 'size' | 'expand'> {
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary';
  leadingIcon?: IconName;
  leadingIconDirection?: IconRotate;
  trailingIcon?: IconName;
  trailingIconDirection?: IconRotate;
  className?: string;
  isFullWidth?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLIonButtonElement>;
}

export const Button = ({
  children,
  variant = 'primary',
  size = 'medium',
  leadingIcon,
  leadingIconDirection,
  trailingIcon,
  trailingIconDirection,
  type,
  isFullWidth,
  isLoading,
  onClick,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const iconSizesSwitch = {
    small: IconSize.extraSmall,
    medium: IconSize.medium,
    large: IconSize.large,
  };

  return (
    <IonButton
      className={mergeClassNames(
        styles.button,
        styles[variant],
        styles[size],
        styles[isLoading ? 'loading' : ''],
        isFullWidth && styles.fullWidth,
        props.className,
      )}
      onClick={onClick}
      {...(type ? { type } : {})}
    >
      <div className={styles[`inner-button-${size}`]}>
        {isLoading ? (
          <Icon name='spinner' color='#90C953' className={styles.spinner} />
        ) : (
          <>
            {leadingIcon && <Icon name={leadingIcon} direction={leadingIconDirection} size={iconSizesSwitch[size]} />}
            {children}
            {trailingIcon && (
              <Icon name={trailingIcon} direction={trailingIconDirection} size={iconSizesSwitch[size]} />
            )}
          </>
        )}
      </div>
    </IonButton>
  );
};
