import { IonCard, IonCardContent, IonImg, IonText } from '@ionic/react';
import { useHistory } from 'react-router';

// Components
import { Icon } from '../Icon';

// Icons
import Walking from '../../assets/icons/Walking.png';
import Running from '../../assets/icons/Running.png';
import squatter from '../../assets/icons/Squat.png';
import slsquatter from '../../assets/icons/SingleLegSquat.png';

// Images
import ReportCardPattern from '../../assets/report-card-pattern.png';

// Types
import { IconRotate } from '../Icon/types';
import { ReportType } from '../../model';

// Styles
import styles from './ReportCard.module.css';

interface ReportCardProps {
  id: string;
  name: string;
  date: Date;
  reportType: ReportType;
  majorVersion: number;
}

export const ReportCard = ({ id, name, date, reportType, majorVersion }: ReportCardProps) => {
  const history = useHistory();

  const imageMap = new Map([
    [ReportType.WALK, Walking],
    [ReportType.RUN, Running],
    [ReportType.SQUAT, squatter],
    [ReportType.SLSQUAT, slsquatter],
  ]);

  const activityNamesMap = new Map([
    [ReportType.WALK, 'Walk'],
    [ReportType.RUN, 'Run'],
    [ReportType.SQUAT, 'Squat'],
    [ReportType.SLSQUAT, 'Single Leg Squat'],
  ]);

  const routeSegmentMap = new Map([
    [ReportType.WALK, 'Walk'],
    [ReportType.RUN, 'Run'],
    [ReportType.SQUAT, 'Squat'],
    [ReportType.SLSQUAT, 'SingleLegSquat'],
  ]);

  const handleClick = () => {
    if (routeSegmentMap.has(reportType)) {
      history.push(`/${majorVersion === 1 ? 'reportsV1' : 'reportsV2'}/${routeSegmentMap.get(reportType)}/${id}`);
    }
  };

  return (
    <IonCard className={styles.card} onClick={handleClick}>
      <div className={styles.color}>
        <IonImg src={imageMap.get(reportType) ?? Walking} className={styles.icon} />

        <IonImg src={ReportCardPattern} className={styles.pattern} />
      </div>

      <IonCardContent className={styles.content}>
        <div className={styles.header}>
          <IonText className={styles.name}>{activityNamesMap.get(reportType)}</IonText>
          <IonText class={styles.date}>
            {date.toLocaleDateString(undefined, {
              month: 'short',
              year: 'numeric',
              day: 'numeric',
            })}
            &nbsp;
            {date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
          </IonText>
        </div>

        <div className={styles.bottom}>
          <IonText className={styles.activity}>{name}</IonText>
          <Icon name='chevron' direction={IconRotate.East} className={styles.chevron} color='#829696' />
        </div>
      </IonCardContent>
    </IonCard>
  );
};
