/** Reports page, showing metadata for available reports for the current user */
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonSearchbar } from '@ionic/react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useReportContext } from '../../components/reportContext';
import { useSearch } from '../../hooks/useSearch';
import { ReportInfo, ReportInfoDict } from '../../model';
import { analytics } from '../../config/firebaseConfig';
import { Button } from '../../components/Button';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Toast } from '../../components/Toast/Toast';
import styles from './reportList.module.css';
import { ReportCardList } from '../../components/ReportListCard';
import { Loading } from '../../components/Loading/Loading';

/** Map over reports sorted by uploadTimestamp */
const sortReportInfos = (reportInfoDict: ReportInfoDict): ReportInfo[] => {
  // Create array of tuples (id, reportInfo)
  const items: [string, ReportInfo][] = Object.keys(reportInfoDict).map((id: string) => {
    return [id, reportInfoDict[id]];
  });

  // Sort the array based on the second element (reportInfo.uploadTimestamp)
  const reportsSorted = items.sort((first, second) => {
    const a = second[1].uploadTimestamp.getTime();
    const b = first[1].uploadTimestamp.getTime();
    return a - b;
  });

  return reportsSorted.map(([_id, reportInfo]) => reportInfo);
};

type ReportsPageProps = {
  filteroption?: string;
};
/** Reports page, showing all reports (metadata) available for the current user
 * Loading reportInfo data from the database using the useReportInfo hook.
 */
export const ReportsPage = ({ filteroption }: ReportsPageProps) => {
  const historyHook = useHistory();
  const { reportInfoDict, isLoading, isError, clearError } = useReportContext();

  const [searchOptions, setSearchOptions] = React.useState({
    includeScore: true,
    useExtendedSearch: true,
    keys: ['filename', 'tags', 'reportType'],
  });

  const list = useMemo(() => sortReportInfos(reportInfoDict), [reportInfoDict]);
  const { onSearch, query, searchResult } = useSearch(searchOptions, list);

  const handleSearch = (e: any) => {
    localStorage.setItem('search', e.target.value);
    setSearchOptions({
      includeScore: true,
      useExtendedSearch: true,
      keys: ['filename', 'tags', 'reportType'],
    });
    onSearch(e);
  };

  useEffect(() => {
    const localStorageSearch = localStorage.getItem('search');
    if (history.state.state !== undefined) {
      analytics.logEvent('search_report');
      try {
        setSearchOptions({
          includeScore: true,
          useExtendedSearch: true,
          keys: ['reportType'],
        });
        onSearch(history.state.state['filterOption']);
        localStorage.setItem('search', history.state.state['filterOption']);
      } catch {
        if (
          localStorageSearch === '1' ||
          localStorageSearch === '2' ||
          localStorageSearch === '3' ||
          localStorageSearch === '4'
        ) {
          setSearchOptions({
            includeScore: true,
            useExtendedSearch: true,
            keys: ['reportType'],
          });
          const filterOption = Number(localStorageSearch);
          onSearch(filterOption);
        } else {
          setSearchOptions({
            includeScore: true,
            useExtendedSearch: true,
            keys: ['filename', 'tags', 'reportType'],
          });
          onSearch(localStorageSearch);
        }
      }
    }
  }, [history]);

  useEffect(() => {
    console.log(searchResult);
    console.log('reportInfoDict', reportInfoDict);
  }, [searchResult, reportInfoDict]);

  // // Scrolling
  // // State variables to manage the number of cards to render and load
  // const [visibleCardCount, setVisibleCardCount] = useState(2); // Initial number of cards
  // const cardsToLoad = 10; // Number of cards to load when scrolling to the end

  // // const shouldLoadMore = useMemo(() => visibleCardCount < list.length, [visibleCardCount, list]);

  // const generateItems = () => {
  //   setVisibleCardCount((prevCount) => prevCount + cardsToLoad);
  // };

  // useEffect(() => {
  //   console.log('generating items');
  //   generateItems();

  //   return () => {
  //     setVisibleCardCount(10);
  //   };
  // }, []);

  return (
    <IonPage>
      <PageHeader title='Reports' backTarget='/dashboard' isLoading={isLoading === 'reportInfo'} />
      <IonContent id='main' color='light' className={styles.content}>
        <IonGrid fixed>
          <IonRow>
            <IonCol class='ion-padding'>
              <Button
                variant='primary'
                size='medium'
                onClick={() => {
                  historyHook.push('/upload');
                }}
              >
                Upload Files
              </Button>
            </IonCol>

            <IonCol className='ion-align-self-center'>
              <IonSearchbar value={query} onIonInput={(e) => handleSearch(e)} showCancelButton='focus'></IonSearchbar>
            </IonCol>
          </IonRow>
          <IonRow>
            {isLoading ? <Loading /> : <ReportCardList data={reportInfoDict} searchResult={searchResult} />}
          </IonRow>
          {/* <IonRow>
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                generateItems();
                setTimeout(() => ev.target.complete(), 500);
              }}>
              <IonInfiniteScrollContent></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonRow> */}
        </IonGrid>
      </IonContent>

      {/* Show errors, if any, as "toast" below the page */}
      <Toast
        type={'warning'}
        isOpen={isError ? true : false}
        onDidDismiss={() => clearError()}
        message={isError && isError.message}
        header={'Error fetching reports'}
      />
    </IonPage>
  );
};
