// Slides
import { Swiper } from 'swiper/react';
import { Keyboard, Mousewheel, Pagination } from 'swiper';

interface VerticalSwiperProps {
  children: React.ReactNode;
}

export const VerticalSwiper = ({ children }: VerticalSwiperProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  return (
    <Swiper
      modules={[Pagination, Mousewheel, Keyboard]}
      pagination={pagination}
      mousewheel={true}
      keyboard={true}
      initialSlide={0}
      speed={400}
      direction='vertical'
      className=''
      style={{ height: '100%' }}
    >
      {children}
    </Swiper>
  );
};
