import { IonHeader } from '@ionic/react';

// Styles
import styles from './ModalHeader.module.css';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';

type ModalHeaderProps = ComponentProps<typeof IonHeader>;

export const ModalHeader = ({ children, ...props }: PropsWithChildren<ModalHeaderProps>) => {
  return (
    <IonHeader className={styles.header} {...props}>
      {children}
    </IonHeader>
  );
};
