import { ComponentProps, PropsWithChildren } from 'react';
import { IonCard } from '@ionic/react';

import { mergeClassNames } from '../../utilities/mergeClassNames';

import styles from './index.module.css';

interface CardProps extends ComponentProps<typeof IonCard> {
  className?: string;
}

export const Card = ({ children, className, ...props }: PropsWithChildren<CardProps>) => {
  return (
    <IonCard className={mergeClassNames(styles.card, className)} {...props}>
      {children}
    </IonCard>
  );
};
