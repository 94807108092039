import { FunctionalMovementKinematicsGraph } from '../../../pages/ReportV2/components/Graphs';

interface SquatReportChartProps {
  PDFDownloadData: any;
  ChartComponentRefs: any;
}

export const SquatReportCharts = ({ PDFDownloadData, ChartComponentRefs }: SquatReportChartProps) => {
  return (
    <div style={{ display: 'none' }}>
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.pelvisKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.pelvisKinematicData.Sagittal.yAxis}
        ref={ChartComponentRefs['pelvisKinematicSagittal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.pelvisKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Drop (-) / Hike (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.pelvisKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['pelvisKinematicCoronal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.pelvisKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.pelvisKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['pelvisKinematicTransversal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.hipKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Extension (-) / Flexion (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.hipKinematicData.Sagittal.yAxis}
        ref={ChartComponentRefs['hipKinematicSagittal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.hipKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Abduction (-) / Adduction (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.hipKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['hipKinematicCoronal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.hipKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'External Rotation (-) / Internal Rotation (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.hipKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['hipKinematicTransversal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.kneeKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Extension (-) / Flexion (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.kneeKinematicData.Sagittal.yAxis}
        ref={ChartComponentRefs['kneeKinematicSagittal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.kneeKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Valgus (-) / Varus (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.kneeKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['kneeKinematicCoronal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.kneeKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'External Rotation (-) / Internal Rotation (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.kneeKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['kneeKinematicTransversal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.ankleKinematicData.Sagittal.series}
        title={'Sagittal'}
        subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.ankleKinematicData.Sagittal.yAxis}
        ref={ChartComponentRefs['ankleKinematicSagittal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.ankleKinematicData.Coronal.series}
        title={'Frontal'}
        subtitle={'Eversion (-) / Inversion (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.ankleKinematicData.Coronal.yAxis}
        ref={ChartComponentRefs['ankleKinematicCoronal']}
      />
      <FunctionalMovementKinematicsGraph
        data={PDFDownloadData.ankleKinematicData.Transversal.series}
        title={'Transversal'}
        subtitle={'External Rotation (-) / Internal Rotation (+)'}
        strokeWidth={2}
        forPdf={true}
        yAxis={PDFDownloadData.ankleKinematicData.Transversal.yAxis}
        ref={ChartComponentRefs['ankleKinematicTransversal']}
      />
    </div>
  );
};
