import { IonContent, IonModal } from '@ionic/react';

// Styles
import styles from './Modal.module.css';

// Components
import { ReportModalHeader } from '../ModalHeader/ReportModalHeader';

// Types
import type { ComponentProps, PropsWithChildren, Ref } from 'react';

interface ModalProps extends ComponentProps<typeof IonModal> {
  ref?: Ref<HTMLIonModalElement>;
  trigger?: string;
  header?: string;
  setShowInfo?: () => void;
}

/**
 * A reusable modal component that leverages the IonModal component from Ionic.
 * It includes a header, content area, and footer.
 *
 * @param {React.ReactNode} children - The content to be displayed inside the modal.
 * @param {React.Ref<HTMLIonModalElement>} ref - A reference to the IonModal element.
 * @param {string} header - The content to be displayed in the modal header.
 * @param {ModalProps} props - Additional properties to be passed to the IonModal component.
 *
 * @returns {JSX.Element} The rendered modal component.
 *
 * @example

 */
export const ReportModal = ({ children, ref, header, setShowInfo, ...props }: PropsWithChildren<ModalProps>) => {
  return (
    <IonModal className={styles.reportmodal} {...props} ref={ref}>
      {header && <ReportModalHeader title={header ?? 'Modal title'} buttons={true} setShowInfo={setShowInfo} />}
      <IonContent className={styles.content}>
        {/* <div className={styles.inner}>{children}</div> */}

        {children}
      </IonContent>
    </IonModal>
  );
};
