import { ComponentProps, useEffect, useState } from 'react';
import { IonCol, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonRow } from '@ionic/react';

// Components
import { ReportListCard } from './ReportListCard';

// Types
import { ReportInfo, ReportInfoDict } from '../../model';

// Styles
import styles from './index.module.css';

interface ReportCardListProps extends Omit<ComponentProps<typeof IonGrid>, 'class'> {
  data: ReportInfoDict;
  searchResult: ReportInfo[];
}

export const ReportCardList = ({ data, searchResult }: ReportCardListProps) => {
  const [visibleCardCount, setVisibleCardCount] = useState(10); // Initial number of cards
  const cardsToLoad = 10; // Number of cards to load when scrolling to the end

  const generateItems = () => {
    setVisibleCardCount((prevCount) => prevCount + cardsToLoad);
  };

  // const [visibleCardCount, setVisibleCardCount] = useState(2); // Initial number of cards
  // const cardsToLoad = 10; // Number of cards to load when scrolling to the end

  // // const shouldLoadMore = useMemo(() => visibleCardCount < list.length, [visibleCardCount, list]);

  useEffect(() => {
    console.log('generating items');
    generateItems();

    return () => {
      setVisibleCardCount(10);
    };
  }, []);

  return (
    <IonGrid className={styles.grid}>
      <IonRow className={styles.row}>
        {data && (
          <>
            {searchResult.slice(0, visibleCardCount).map((reportInfo) => {
              return (
                <IonCol size='12' size-md='6' key={reportInfo.id}>
                  <ReportListCard
                    id={reportInfo.id ?? ''}
                    name={reportInfo.reportName}
                    date={reportInfo.uploadTimestamp}
                    majorVersion={reportInfo.version?.col0}
                    reportType={reportInfo.reportType}
                    tags={reportInfo.tags}
                    reportInfo={reportInfo}
                    key={reportInfo.id}
                  />
                </IonCol>
              );
            })}
          </>
        )}
      </IonRow>
      <IonRow>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonRow>
    </IonGrid>
  );
};
