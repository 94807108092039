import { IonRadio } from '@ionic/react';
import { JSX as IonicJSX } from '@ionic/core';
import { PropsWithChildren } from 'react';

import styles from './index.module.css';

type IonRadioProps = IonicJSX.IonRadio;

type RadioProps = Omit<IonRadioProps, 'justify'>;

export const Radio = ({ children, ...props }: PropsWithChildren<RadioProps>) => {
  return (
    <IonRadio {...props} labelPlacement='end' className={styles.radio} alignment='center' justify='start'>
      {children}
    </IonRadio>
  );
};
