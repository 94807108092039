import { IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../../components/CardHeader/cardHeader';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { ReportModal } from '../../../../components/Modal/ReportModal';
import styles from './ApexCharts.module.css';

type ScissorsGraphCardProps = {
  series: any;
  title: string;
  x: string;
  x2: string;
  colors: string[];
};

export const ScissorsGraphCard = ({ series, title, x, x2, colors }: ScissorsGraphCardProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');
  const [showModal, setShowModal] = useState({ show: false });

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      stepSize: 20,
      axisTicks: {
        show: true,
      },
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    // title: {
    //   text: title,
    //   align: 'center',
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: 'bold',
    //     fontFamily: 'Montserrat',
    //     color: '#2066FA',
    //   },
    // },

    stroke: {
      width: [1, 1],
      dashArray: [4, 0],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      offsetY: 10,
    },
    colors: colors,
    annotations: {
      xaxis: [
        {
          x: x,
          x2: x2,
          borderColor: '#00E396',
          fillColor: '#ABF263',
        },
      ],
    },
    markers: {
      size: [0, 0, 2],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <>
      <ReportModal
        isOpen={showModal.show}
        onDidDismiss={() => {
          setShowModal({ show: false });
        }}
        header={title}
        setShowInfo={() => setShowModal({ show: false })}
      >
        <IonGrid className='' style={{ height: '100%' }}>
          <IonRow className='ion-align-items-center ion-justify-content-center'>
            <IonCol size='11' className=''>
              <div className={styles.reportModalGraph_Wrapper}>
                <Chart options={options} series={series} type='line' width='100%' />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ReportModal>
      <Card
        style={{ height: '100%', margin: 0 }}
        onClick={() => {
          setShowModal({ show: true });
        }}
      >
        <CardHeader title={title} />
        <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
          <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Chart
              options={options}
              series={series}
              type='line'
              width='100%'
              height={windowSize == 'small' ? 125 : 175}
            />
          </div>
        </IonCardContent>
      </Card>
    </>
  );
};
