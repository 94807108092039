/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Login form Component, including the logo.
 */

import { IonCol, IonGrid, IonImg, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { analytics } from '../../config/firebaseConfig';

import logo from '../../assets/oryx-logo.svg';
import { useAuthContext } from '../authContext';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Button } from '../Button';
import { Input } from '../Input';
import { mergeClassNames } from '../../utilities/mergeClassNames';
import { IonInputCustomEvent } from '@ionic/core';

import './loginForm.css';
import styles from './loginForm.module.css';
import { Toast } from '../Toast/Toast';

export const LoginForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showLoginError, setShowLoginError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const SHOW_FORGOT = false;

  const { signInWithEmailAndPassword, state, clearError } = useAuthContext();

  useEffect(() => {
    if (state.error) {
      if (import.meta.env.MODE !== 'production') console.log(state.error);
      setShowLoginError(true);
    }
  }, [state]);

  async function login(e: React.FormEvent) {
    analytics.logEvent('login');
    e.preventDefault();
    setFormSubmitted(true);
    if (!!email && !!password) {
      setIsLoading(true);
      await signInWithEmailAndPassword(email, password);
      setIsLoading(false);
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.detail.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.detail.value);
  };

  function handleFocusEvent(ev: IonInputCustomEvent<FocusEvent>) {
    ev.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <>
      <Toast
        isOpen={showLoginError}
        header='Error signing in'
        message={'Incorrect email or password. Please try again.'}
        onDidDismiss={() => {
          setShowLoginError(false);
          clearError();
        }}
        type={'warning'}
      />

      <form noValidate onSubmit={login} className={styles['login-form']}>
        <IonGrid fixed className={styles['no-padding']} id='login-form'>
          <div>
            {import.meta.env.MODE !== 'production' && (
              <>
                <IonRow className={mergeClassNames('warning', styles.dev)}>
                  <IonCol>
                    <IonText style={{ color: '#ffffff' }}>{import.meta.env.MODE} environment!</IonText>
                  </IonCol>
                </IonRow>
              </>
            )}

            <IonRow>
              <IonCol size='12' className={styles['no-padding']}>
                <IonImg src={logo} className={styles.logo}></IonImg>
              </IonCol>
            </IonRow>
          </div>
          <div id='focus-target' className={styles.main}>
            <IonRow>
              <IonCol size='12' className={styles.copy}>
                <IonText className={styles.heading}>Sign in to Oryx</IonText>
                <IonText className={styles.description}>Please sign in to your account</IonText>
              </IonCol>
            </IonRow>

            <IonRow className={styles['no-padding']}>
              <IonCol size='12' class={styles['no-padding']}>
                <IonList mode='md' class={mergeClassNames(styles['no-padding'], styles.list)}>
                  <Input
                    name='email'
                    type='email'
                    label='Email'
                    label-placement='stacked'
                    onIonChange={handleEmail}
                    onIonFocus={handleFocusEvent}
                  />

                  <Input
                    name='password'
                    type='password'
                    label='Password'
                    label-placement='stacked'
                    onIonChange={handlePassword}
                    onIonFocus={handleFocusEvent}
                  />

                  {formSubmitted && (!email || !password) && (
                    <IonText color='warning'>
                      <p className='ion-padding-start'>Email and password are required.</p>
                    </IonText>
                  )}
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow class={styles['no-padding']}>
              <IonCol size='12' class={mergeClassNames(styles['button-wrapper'], styles['no-padding'])}>
                <Button type='submit' isFullWidth variant='primary' className={styles.button} isLoading={isLoading}>
                  Sign in
                </Button>
              </IonCol>
            </IonRow>
          </div>
          {SHOW_FORGOT && (
            <IonRow class={styles['forgot-wrapper']}>
              <IonCol size='12'>
                <IonItem lines='none' className={styles.forgot}>
                  <IonLabel
                    class={styles.forgot}
                    onClick={() => {
                      history.push(`/resetPassword`);
                    }}
                  >
                    <div className={styles['forgot-text']}>Forgot password?</div>
                  </IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </form>
    </>
  );
};
