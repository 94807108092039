import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonRow } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import errorComponent from '../../assets/icons/errorComponentIcon.svg';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <IonContent className='ion-padding' id='main' color='light' style={{ padding: '40px', height: '100%' }}>
          <IonGrid fixed style={{ padding: '40px', height: '100%' }}>
            <IonRow className='ion-align-items-center  ion-padding' style={{ padding: '40px', height: '100%' }}>
              <IonCol className='' size='6'>
                <h5>
                  Looks like you&apos;ve found the doorway to the great nothingness. <br />
                </h5>
                <p>Sorry about that!</p>
                <IonRow className='ion-justify-content-center ion-margin-vertical' style={{ marginTop: '50px' }}>
                  <IonButton className='primary-red' onClick={() => this.setState({ hasError: false, error: null })}>
                    <IonIcon icon={arrowBack} slot='start' />
                    Retry
                  </IonButton>
                </IonRow>
              </IonCol>
              <IonCol size='6' className=''>
                <IonImg src={errorComponent} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
