import { IconProps } from '..';

export const IconChart = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.2498 1.50009C11.5598 1.50009 10.9998 2.06009 10.9998 2.75009V13.2501C10.9998 13.9401 11.5598 14.5001 12.2498 14.5001H12.7498C13.4398 14.5001 13.9998 13.9401 13.9998 13.2501V2.75009C13.9998 2.05943 13.4398 1.50009 12.7498 1.50009H12.2498ZM6.49976 5.75009C6.49976 5.05943 7.05976 4.50009 7.74976 4.50009H8.24976C8.94042 4.50009 9.49976 5.06009 9.49976 5.75009V13.2501C9.49976 13.9401 8.93976 14.5001 8.24976 14.5001H7.74976C7.41824 14.5001 7.10029 14.3684 6.86587 14.134C6.63145 13.8996 6.49976 13.5816 6.49976 13.2501V5.75009ZM1.99976 8.75009C1.99976 8.05943 2.55976 7.50009 3.24976 7.50009H3.74976C4.44042 7.50009 4.99976 8.06009 4.99976 8.75009V13.2501C4.99976 13.9401 4.43976 14.5001 3.74976 14.5001H3.24976C2.91824 14.5001 2.60029 14.3684 2.36587 14.134C2.13145 13.8996 1.99976 13.5816 1.99976 13.2501V8.75009Z'
        fill={color}
      />
    </svg>
  );
};
