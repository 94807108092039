import { DataSeries, LineGraphData, StridesData } from '../../../model/reportV2.model';

/**
 * Converts the given StridesData object into a LineGraphData array.
 * Each element in the array represents a line graph data series.
 *
 * @param data - The StridesData object to convert.
 * @returns The LineGraphData array representing the converted data.
 */
export function toLineGraphDataStrides(data: StridesData): LineGraphData {
  const numCycles = Object.keys(data).length;

  const serie: { name: string; data: DataSeries }[] = [];

  for (let i = 0; i < numCycles; i++) {
    const cycleData: DataSeries = [];

    data[`col${i}`].forEach((yValue: any, index: number) => {
      const xValue = index;
      cycleData.push({ x: xValue, y: yValue });
    });
    serie.push({ name: `stride ${i + 1}`, data: cycleData });
  }

  return serie;
}
