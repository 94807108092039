import { flattenData, toGraphData, toVariabilityData } from '.';
import { ThighCoordinationData } from '../../../model/reportV2.model';
import { colorCC, colorCS, colorSS, colorST, variabilityAll_Left, variabilityAll_Right } from '../../../theme/colors';

/**
 * Retrieves the pelvis variability data based on the provided input data and side.
 *
 * @param data - The input data.
 * @param side - The side of the pelvis ('Left' or 'Right').
 * @returns The pelvis variability data.
 */
export function getThighCoordinationData(data: any, side: 'Left' | 'Right', index: number): ThighCoordinationData {
  const overviewColor = side === 'Left' ? variabilityAll_Left : variabilityAll_Right;

  const crpDataThigh = toGraphData(data.crpDataThigh[`crpThigh_${side}`]);
  const crpSeriesDataThigh = [
    { type: 'line', name: 'Thigh (s) / Thigh (s)', data: flattenData(crpDataThigh), color: colorSS },
  ];

  const crpDataThighC = toGraphData(data.crpDataThigh[`crpThighC_${side}`]);
  const crpSeriesDataThighC = [
    { type: 'line', name: 'Thigh (c) / Thigh (c)', data: flattenData(crpDataThighC), color: colorCC },
  ];

  const crpDataThighT = data.crpDataThigh[`crpThighT_${side}`]
    ? toGraphData(data.crpDataThigh[`crpThighT_${side}`])
    : null;
  const crpSeriesDataThighT = [
    { type: 'line', name: 'Thigh (t) / Thigh (t)', data: flattenData(crpDataThighT ?? []), color: colorST },
  ];

  const crpDataThighST = data.crpDataThigh[`crpThighST_${side}`]
    ? toGraphData(data.crpDataThigh[`crpThighST_${side}`])
    : null;
  const crpSeriesDataThighST = [
    { type: 'line', name: 'Thigh (s) / Thigh (t)', data: flattenData(crpDataThighST ?? []), color: colorCS },
  ];

  const coordinativeVariabilityThigh = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index}`],
  );
  const coordinativeVariabilitySeriesThigh = [
    { type: 'area', name: 'Thigh (s) / Thigh (s)', data: coordinativeVariabilityThigh, color: colorSS },
  ];

  const coordinativeVariabilityThighC = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index + 2}`],
  );

  const coordinativeVariabilitySeriesThighC = [
    { type: 'area', name: 'Thigh (c) / Thigh (c)', data: coordinativeVariabilityThighC, color: colorCC },
  ];

  const coordinativeVariabilityThighT = data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index + 4}`]
    ? toVariabilityData(data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index + 4}`])
    : null;
  const coordinativeVariabilitySeriesThighT = [
    { type: 'area', name: 'Thigh (t) / Thigh (t)', data: coordinativeVariabilityThighT ?? [], color: colorST },
  ];

  const coordinativeVariabilityThighST = data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index + 6}`]
    ? toVariabilityData(data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index + 6}`])
    : [];
  const coordinativeVariabilitySeriesThighST = [
    { type: 'area', name: 'Thigh (s) / Thigh (t)', data: coordinativeVariabilityThighST ?? [], color: colorCS },
  ];

  return {
    crpSeriesDataThigh,
    crpSeriesDataThighC,
    crpSeriesDataThighT,
    crpSeriesDataThighST,
    coordinativeVariabilitySeriesThigh,
    coordinativeVariabilitySeriesThighC,
    coordinativeVariabilitySeriesThighT,
    coordinativeVariabilitySeriesThighST,
  };
}
