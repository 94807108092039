import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';

// Styles
import styles from './PageTopBar.module.css';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';

interface PageTopBarProps extends ComponentProps<typeof IonHeader> {
  title: string;
  description?: string;
}

export const PageTopBar = ({ children, title, description, ...props }: PropsWithChildren<PageTopBarProps>) => {
  return (
    <IonHeader className='ion-no-padding' {...props}>
      <IonToolbar className={styles.toolbar}>
        <IonTitle className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          {description && <span className={styles.description}>{description}</span>}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};
