/** Bar at the bottom of a page to show errors */
import { IonBreadcrumb, IonBreadcrumbs, IonIcon } from '@ionic/react';
import { arrowForwardCircle } from 'ionicons/icons';

type ORYXBreadCrumbsProps = {
  crumbs: string[];
};

export const ORYXBreadCrumbs = ({ crumbs }: ORYXBreadCrumbsProps) => (
  <IonBreadcrumbs itemsAfterCollapse={2} maxItems={2}>
    {crumbs.map((crumb, index) => (
      <IonBreadcrumb key={index}>
        <small>{crumb}</small>
        <IonIcon slot='separator' icon={arrowForwardCircle} size='small' />
      </IonBreadcrumb>
    ))}
  </IonBreadcrumbs>
);
