import { IonSegment, IonSegmentButton } from '@ionic/react';
import { ComponentProps } from 'react';

import styles from './index.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

export type SegmentButton = ComponentProps<typeof IonSegmentButton>;
interface SegmentedControlProps extends ComponentProps<typeof IonSegment> {
  segments: SegmentButton[];
  variant?: 'default' | 'condensed';
}

export const SegmentedControl = ({ segments, variant = 'default', ...props }: SegmentedControlProps) => {
  return (
    <IonSegment mode='ios' className={mergeClassNames(styles.control, styles[variant])} {...props} scrollable={true}>
      {segments.map((segment) => (
        <IonSegmentButton
          value={segment.value}
          {...segment}
          key={segment.value}
          className={mergeClassNames(segment.className, styles.button, styles[variant])}
        >
          {segment.title}
        </IonSegmentButton>
      ))}
    </IonSegment>
  );
};
