import React from 'react';

import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import pelvis from '../../../assets/avatars/walk/analysis/Pelvis.png';
import hip from '../../../assets/avatars/walk/analysis/Hip.png';
import knee from '../../../assets/avatars/walk/analysis/Knee.png';
import ankle from '../../../assets/avatars/walk/analysis/Ankle.png';
import { StatusDotField } from './PDFFields';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',
    marginHorizontal: '12px',
    alignSelf: 'center',
    marginBottom: '5px',
    paddingTop: '10px',
  },
  contentContainer: {
    display: 'flex',
    paddingHorizontal: 5,
    paddingVertical: 8,
    marginHorizontal: 5,
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    alignContent: 'center',
    border: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  Title: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#0A1414',
    marginBottom: 10,
  },
  strideDuration: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E2E2',
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: '100%',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  subTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#767676',
    paddingVertical: 2,
  },
  MobilityContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  MobilityContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    marginTop: '10px',
    // border: 1,
    // borderColor: '#E2E2E2',
    // borderRadius: 4,
    // borderStyle: 'solid',
    width: '25%',
  },
  avatar: {
    width: '85px',
    alignSelf: 'center',
  },
  joint: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#0A1414',
    textAlign: 'center',
    paddingVertical: '10px',
  },
  mobilityOutputContainer: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    width: '100%',
  },
  SpeedLabel: {
    backgroundColor: 'green',
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    color: '#ffffff',
    marginLeft: '20px',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
  warning: {
    backgroundColor: 'orange',
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    color: '#ffffff',
    marginLeft: '20px',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
  danger: {
    backgroundColor: 'red',
    borderRadius: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingHorizontal: '20px',
    color: '#ffffff',
    marginLeft: '20px',
    fontSize: 10,
    fontFamily: 'Montserrat',
  },
});

type WalkReportProps = {
  data: any;
};

//Mobility Card with an Avatar, title, status dots & Range of motion
type MobilityProps = {
  title: string;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Mobility report card content, showing avatar, left/right status and symmetry fields
 * @param img - The avatar
 * @param romRow - list of ROM data values
 * @param statusRow - list of status field tuples for (Left, Right)
 */
const Mobility = ({ title, img, romRow, statusRow }: MobilityProps) => (
  <View style={styles.MobilityContainer}>
    <Image style={styles.avatar} src={img} />
    <Text style={styles.joint}>{title}</Text>
    <View style={styles.mobilityOutputContainer}>
      <StatusDotField name={'Left'} value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
      <StatusDotField name={'Right'} value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
    </View>
  </View>
);

export const PDFMobilityStatus = ({ data }: WalkReportProps) => {
  const romData = data.romData;
  const statusData = data.mobilityData;
  const tableData = data.spatioTemporalData;
  const speedLabel = () => {
    if (tableData[0][4] === 1) {
      return 'Ok';
    } else if (tableData[0][4] === 2) {
      return 'Slow';
    } else if (tableData[0][4] === 3) {
      return 'Too slow';
    } else {
      return 'Too fast';
    }
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <Text style={styles.Title}>Mobility Status</Text>
          <View style={styles.strideDuration}>
            <Text style={styles.subTitle}>Stride Duration: {tableData[0][0].toFixed(2)}s.</Text>
            {tableData[0][4] === 1 && <Text style={styles.SpeedLabel}>{speedLabel()}</Text>}
            {tableData[0][4] === 2 && <Text style={styles.warning}>{speedLabel()}</Text>}
            {tableData[0][4] === 3 && <Text style={styles.danger}>{speedLabel()}</Text>}
            {tableData[0][4] === 4 && <Text style={styles.danger}>{speedLabel()}</Text>}
          </View>
          <View style={styles.MobilityContentContainer}>
            <Mobility title={'Pelvis'} img={pelvis} romRow={romData[0]} statusRow={statusData[0]} />
            <Mobility title={'Hips'} img={hip} romRow={romData[1]} statusRow={statusData[1]} />
            <Mobility title={'Knees'} img={knee} romRow={romData[4]} statusRow={statusData[2]} />
            <Mobility title={'Ankles'} img={ankle} romRow={romData[7]} statusRow={statusData[3]} />
          </View>
        </View>
      </View>
    </>
  );
};
