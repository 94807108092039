import {
  AnalysisData,
  HighLightData,
  MobilityStatusData,
  SpeedLabel,
  WalkReportData,
  walkVariabilityData,
  WalkKinematicData,
  JointCouplingSeriesData,
  VariabilityGraphData,
  kpiLabels,
} from '../../model/reportV2.model';

import {
  transpose,
  toMatrix,
  defineSpeedLabel,
  getAllKinematicData,
  getPelvisVariabilityData,
  getKneeVariabilityData,
  getAnkleVariabilityData,
  toJointCouplingData,
  calculateXaxisJointCouplingRange,
  createVariabilityLegend,
  createKinematicGraphsAnnotations,
  getPelvisScore,
  getHighSymmetryScore,
  getSymmetryScore,
  getHipScore,
  getHipExtensionScore,
  getKneeScore,
  getStanceFlexionScore,
  getVarusValgusScore,
  getAnkleScore,
  createVariabilitySingleSideGraphAnnotations,
} from './dataFunctions';

/**
 * Creates the WalkReportData object based on the provided data.
 *
 * @param data - The data used to create the WalkReportData object.
 * @returns The created WalkReportData object.
 */
export const createWalkReportData = (data: any): WalkReportData => {
  const classNames = ['tableLabel_danger', 'tableLabel_warning', 'tableLabel_succes'];
  function color(status: number): string {
    return classNames[status];
  }
  //#region Mobility Status Data
  const romData: number[][] = transpose(data.reportId.rom);
  const statusData: number[][] = transpose(data.reportId.mobilityStatus);
  const tableData: number[][] = toMatrix(data.reportId.spatioTemporal);
  const speedLabel: SpeedLabel = defineSpeedLabel(tableData[0][4]);

  const mobilityStatusData: MobilityStatusData = {
    romData,
    statusData,
    tableData,
    speedLabel,
  };

  //#endregion

  //#region AnalysisData
  const kpiData: number[][] = transpose(data.reportId.keyPerformanceIndicators);
  const varusValgusLabel_Left: string = romData[6][0] < 0 ? 'Valgus' : 'Varus';
  const varusValgusLabel_Right: string = romData[6][1] < 0 ? 'Valgus' : 'Varus';
  const kliLabels = kpiLabels;

  const analysisData: AnalysisData = {
    romData,
    kpiData,
    kpiLabels,
    varusValgusLabel_Left,
    varusValgusLabel_Right,
  };
  //#endregion

  //#region Variability Data
  const annotations_Left: number[] = data.reportId.pointsOfInterests.col0;
  const highlightsHip_Left: HighLightData = { x: data.reportId.pointsOfInterests.col0[4], x2: 100 };
  const annotations_Right: number[] = data.reportId.pointsOfInterests.col1;
  const highlightsHip_Right: HighLightData = { x: data.reportId.pointsOfInterests.col1[4], x2: 100 };
  const highlightsKnee_Left: HighLightData = {
    x: data.reportId.pointsOfInterests.col0[3],
    x2: data.reportId.pointsOfInterests.col0[4],
  };

  const highlightsKnee_Right: HighLightData = {
    x: data.reportId.pointsOfInterests.col1[3],
    x2: data.reportId.pointsOfInterests.col1[4],
  };

  const graphAnnotationsHipAnkle_Left = createVariabilitySingleSideGraphAnnotations(
    annotations_Left,
    highlightsHip_Left,
  );
  const graphAnnotationsHipAnkle_Right = createVariabilitySingleSideGraphAnnotations(
    annotations_Right,
    highlightsHip_Right,
  );
  const graphAnnotationsKnee_Left = createVariabilitySingleSideGraphAnnotations(annotations_Left, highlightsKnee_Left);
  const graphAnnotationsKnee_Right = createVariabilitySingleSideGraphAnnotations(
    annotations_Right,
    highlightsKnee_Right,
  );
  const {
    crpSeriesDataPelvisThighT: crpSeriesDataPelvisThighT_Left,
    meanCrpSeriesDataPelvisThigh: meanCrpSeriesDataPelvisThigh_Left,
    crpSeriesDataPelvisCThighT: crpSeriesDataPelvisCThighT_Left,
    meanCrpSeriesDataPelvisThighT: meanCrpSeriesDataPelvisThighT_Left,
    crpSeriesDataPelvisCThigh: crpSeriesDataPelvisCThigh_Left,
    meanCrpSeriesDataPelvisCThighT: meanCrpSeriesDataPelvisCThighT_Left,
    crpSeriesDataPelvisThigh: crpSeriesDataPelvisThigh_Left,
    meanCrpSeriesDataPelvisCThigh: meanCrpSeriesDataPelvisCThigh_Left,
    coordinativeVariabilitySeriesAllHip: coordinativeVariabilitySeriesAllHip_Left,
    coordinativeVariabilitySeriesMeanHip: coordinativeVariabilitySeriesMeanHip_Left,
    coordinativeVariabilitySeriesPelvisThigh: coordinativeVariabilitySeriesPelvisThigh_Left,
    coordinativeVariabilitySeriesPelvisThighT: coordinativeVariabilitySeriesPelvisThighT_Left,
    coordinativeVariabilitySeriesPelvisCThighT: coordinativeVariabilitySeriesPelvisCThighT_Left,
    coordinativeVariabilitySeriesPelvisCThigh: coordinativeVariabilitySeriesPelvisCThigh_Left,
  } = getPelvisVariabilityData(data, 'Left');

  const {
    crpSeriesDataPelvisThighT: crpSeriesDataPelvisThighT_Right,
    meanCrpSeriesDataPelvisThigh: meanCrpSeriesDataPelvisThigh_Right,
    crpSeriesDataPelvisCThighT: crpSeriesDataPelvisCThighT_Right,
    meanCrpSeriesDataPelvisThighT: meanCrpSeriesDataPelvisThighT_Right,
    crpSeriesDataPelvisCThigh: crpSeriesDataPelvisCThigh_Right,
    meanCrpSeriesDataPelvisCThighT: meanCrpSeriesDataPelvisCThighT_Right,
    crpSeriesDataPelvisThigh: crpSeriesDataPelvisThigh_Right,
    meanCrpSeriesDataPelvisCThigh: meanCrpSeriesDataPelvisCThigh_Right,
    coordinativeVariabilitySeriesAllHip: coordinativeVariabilitySeriesAllHip_Right,
    coordinativeVariabilitySeriesMeanHip: coordinativeVariabilitySeriesMeanHip_Right,
    coordinativeVariabilitySeriesPelvisThigh: coordinativeVariabilitySeriesPelvisThigh_Right,
    coordinativeVariabilitySeriesPelvisThighT: coordinativeVariabilitySeriesPelvisThighT_Right,
    coordinativeVariabilitySeriesPelvisCThighT: coordinativeVariabilitySeriesPelvisCThighT_Right,
    coordinativeVariabilitySeriesPelvisCThigh: coordinativeVariabilitySeriesPelvisCThigh_Right,
  } = getPelvisVariabilityData(data, 'Right');
  const {
    crpSeriesDataThighCalfT: crpSeriesDataThighCalfT_Left,
    meanCrpSeriesDataThighCalf: meanCrpSeriesDataThighCalf_Left,
    crpSeriesDataThighCCalfT: crpSeriesDataThighCCalfT_Left,
    meanCrpSeriesDataThighCCalfT: meanCrpSeriesDataThighCCalfT_Left,
    crpSeriesDataThighCalf: crpSeriesDataThighCalf_Left,
    meanCrpSeriesDataThighCalfT: meanCrpSeriesDataThighCalfT_Left,
    coordinativeVariabilitySeriesAllKnee: coordinativeVariabilitySeriesAllKnee_Left,
    coordinativeVariabilitySeriesMeanKnee: coordinativeVariabilitySeriesMeanKnee_Left,
    coordinativeVariabilitySeriesThighCalf: coordinativeVariabilitySeriesThighCalf_Left,
    coordinativeVariabilitySeriesThighCalfT: coordinativeVariabilitySeriesThighCalfT_Left,
    coordinativeVariabilitySeriesThighCCalfT: coordinativeVariabilitySeriesThighCCalfT_Left,
  } = getKneeVariabilityData(data, 'Left');
  const {
    crpSeriesDataThighCalfT: crpSeriesDataThighCalfT_Right,
    meanCrpSeriesDataThighCalf: meanCrpSeriesDataThighCalf_Right,
    crpSeriesDataThighCCalfT: crpSeriesDataThighCCalfT_Right,
    meanCrpSeriesDataThighCCalfT: meanCrpSeriesDataThighCCalfT_Right,
    crpSeriesDataThighCalf: crpSeriesDataThighCalf_Right,
    meanCrpSeriesDataThighCalfT: meanCrpSeriesDataThighCalfT_Right,
    coordinativeVariabilitySeriesAllKnee: coordinativeVariabilitySeriesAllKnee_Right,
    coordinativeVariabilitySeriesMeanKnee: coordinativeVariabilitySeriesMeanKnee_Right,
    coordinativeVariabilitySeriesThighCalf: coordinativeVariabilitySeriesThighCalf_Right,
    coordinativeVariabilitySeriesThighCalfT: coordinativeVariabilitySeriesThighCalfT_Right,
    coordinativeVariabilitySeriesThighCCalfT: coordinativeVariabilitySeriesThighCCalfT_Right,
  } = getKneeVariabilityData(data, 'Right');
  const {
    crpSeriesDataCalfTFootC: crpSeriesDataCalfTFootC_Left,
    meanCrpSeriesDataCalfFoot: meanCrpSeriesDataCalfFoot_Left,
    crpSeriesDataCalfFoot: crpSeriesDataCalfFoot_Left,
    meanCrpSeriesDataCalfTFootC: meanCrpSeriesDataCalfTFootC_Left,
    coordinativeVariabilitySeriesAllAnkle: coordinativeVariabilitySeriesAllAnkle_Left,
    coordinativeVariabilitySeriesMeanAnkle: coordinativeVariabilitySeriesMeanAnkle_Left,
    coordinativeVariabilitySeriesCalfFoot: coordinativeVariabilitySeriesCalfFoot_Left,
    coordinativeVariabilitySeriesCalfTFootC: coordinativeVariabilitySeriesCalfTFootC_Left,
  } = getAnkleVariabilityData(data, 'Left');
  const {
    crpSeriesDataCalfTFootC: crpSeriesDataCalfTFootC_Right,
    meanCrpSeriesDataCalfFoot: meanCrpSeriesDataCalfFoot_Right,
    crpSeriesDataCalfFoot: crpSeriesDataCalfFoot_Right,
    meanCrpSeriesDataCalfTFootC: meanCrpSeriesDataCalfTFootC_Right,
    coordinativeVariabilitySeriesAllAnkle: coordinativeVariabilitySeriesAllAnkle_Right,
    coordinativeVariabilitySeriesMeanAnkle: coordinativeVariabilitySeriesMeanAnkle_Right,
    coordinativeVariabilitySeriesCalfFoot: coordinativeVariabilitySeriesCalfFoot_Right,
    coordinativeVariabilitySeriesCalfTFootC: coordinativeVariabilitySeriesCalfTFootC_Right,
  } = getAnkleVariabilityData(data, 'Right');

  const crpSeriesPelvisThigh_Left = [
    ...crpSeriesDataPelvisThighT_Left,
    ...crpSeriesDataPelvisCThighT_Left,
    ...crpSeriesDataPelvisCThigh_Left,
    ...crpSeriesDataPelvisThigh_Left,
  ];
  const coordinativeVariabilityOverviewHip_Left = [
    ...coordinativeVariabilitySeriesAllHip_Left,
    // ...coordinativeVariabilitySeriesSelectedHip_Left,
    // ...coordinativeVariabilitySeriesMeanHip_Left,
  ];

  const crpSeriesPelvisThigh_Right = [
    ...crpSeriesDataPelvisThighT_Right,
    ...crpSeriesDataPelvisCThighT_Right,
    ...crpSeriesDataPelvisCThigh_Right,
    ...crpSeriesDataPelvisThigh_Right,
  ];

  const coordinativeVariabilityOverviewHip_Right = [
    ...coordinativeVariabilitySeriesAllHip_Right,
    // ...coordinativeVariabilitySeriesSelectedHip_Right,
    // ...coordinativeVariabilitySeriesMeanHip_Right,
  ];

  const crpSeriesThighCalf_Left = [
    ...crpSeriesDataThighCalfT_Left,
    ...crpSeriesDataThighCCalfT_Left,
    ...crpSeriesDataThighCalf_Left,
  ];

  const coordinativeVariabilityOverviewKnee_Left = [
    ...coordinativeVariabilitySeriesAllKnee_Left,
    // ...coordinativeVariabilitySeriesSelectedKnee_Left,
    // ...coordinativeVariabilitySeriesMeanKnee_Left,
  ];

  const crpSeriesThighCalf_Right = [
    ...crpSeriesDataThighCalfT_Right,
    ...crpSeriesDataThighCCalfT_Right,
    ...crpSeriesDataThighCalf_Right,
  ];

  const coordinativeVariabilityOverviewKnee_Right = [
    ...coordinativeVariabilitySeriesAllKnee_Right,
    // ...coordinativeVariabilitySeriesSelectedKnee_Right,
    // ...coordinativeVariabilitySeriesMeanKnee_Right,
  ];

  const crpSeriesCalfFoot_Left = [...crpSeriesDataCalfTFootC_Left, ...crpSeriesDataCalfFoot_Left];

  const coordinativeVariabilityOverviewAnkle_Left = [
    ...coordinativeVariabilitySeriesAllAnkle_Left,
    // ...coordinativeVariabilitySeriesSelectedAnkle_Left,
    // ...coordinativeVariabilitySeriesMeanAnkle_Left,
  ];

  const crpSeriesCalfFoot_Right = [...crpSeriesDataCalfTFootC_Right, ...crpSeriesDataCalfFoot_Right];

  const coordinativeVariabilityOverviewAnkle_Right = [
    ...coordinativeVariabilitySeriesAllAnkle_Right,
    // ...coordinativeVariabilitySeriesSelectedAnkle_Right,
    // ...coordinativeVariabilitySeriesMeanAnkle_Right,
  ];

  const meanCrpSeriesPelvisThigh_Left = [
    ...meanCrpSeriesDataPelvisThighT_Left,
    ...meanCrpSeriesDataPelvisCThighT_Left,
    ...meanCrpSeriesDataPelvisCThigh_Left,
    ...meanCrpSeriesDataPelvisThigh_Left,
  ];
  const meanCoordinativeVariabilityOverviewHip_Left = [...coordinativeVariabilitySeriesMeanHip_Left];

  const meanCrpSeriesPelvisThigh_Right = [
    ...meanCrpSeriesDataPelvisThighT_Right,
    ...meanCrpSeriesDataPelvisCThighT_Right,
    ...meanCrpSeriesDataPelvisCThigh_Right,
    ...meanCrpSeriesDataPelvisThigh_Right,
  ];

  const meanCoordinativeVariabilityOverviewHip_Right = [...coordinativeVariabilitySeriesMeanHip_Right];

  const meanCrpSeriesThighCalf_Left = [
    ...meanCrpSeriesDataThighCalfT_Left,
    ...meanCrpSeriesDataThighCCalfT_Left,
    ...meanCrpSeriesDataThighCalf_Left,
  ];

  const meanCoordinativeVariabilityOverviewKnee_Left = [...coordinativeVariabilitySeriesMeanKnee_Left];

  const meanCrpSeriesThighCalf_Right = [
    ...meanCrpSeriesDataThighCalfT_Right,
    ...meanCrpSeriesDataThighCCalfT_Right,
    ...meanCrpSeriesDataThighCalf_Right,
  ];

  const meanCoordinativeVariabilityOverviewKnee_Right = [...coordinativeVariabilitySeriesMeanKnee_Right];

  const meanCrpSeriesCalfFoot_Left = [...meanCrpSeriesDataCalfTFootC_Left, ...meanCrpSeriesDataCalfFoot_Left];

  const meanCoordinativeVariabilityOverviewAnkle_Left = [...coordinativeVariabilitySeriesMeanAnkle_Left];

  const meanCrpSeriesCalfFoot_Right = [...meanCrpSeriesDataCalfTFootC_Right, ...meanCrpSeriesDataCalfFoot_Right];

  const meanCoordinativeVariabilityOverviewAnkle_Right = [...coordinativeVariabilitySeriesMeanAnkle_Right];

  //#region Modal Data
  const crpModalSeriesHip_Left = [
    ...crpSeriesDataPelvisThigh_Left,
    ...crpSeriesDataPelvisThighT_Left,
    ...crpSeriesDataPelvisCThighT_Left,
    ...crpSeriesDataPelvisCThigh_Left,
  ];

  const coordinativeVariabilityModalSeriesHip_Left = [
    ...coordinativeVariabilitySeriesPelvisThigh_Left,
    ...coordinativeVariabilitySeriesPelvisThighT_Left,
    ...coordinativeVariabilitySeriesPelvisCThighT_Left,
    ...coordinativeVariabilitySeriesPelvisCThigh_Left,
    ...coordinativeVariabilitySeriesAllHip_Left,
  ];

  const crpModalSeriesHip_Right = [
    ...crpSeriesDataPelvisThigh_Right,
    ...crpSeriesDataPelvisThighT_Right,
    ...crpSeriesDataPelvisCThighT_Right,
    ...crpSeriesDataPelvisCThigh_Right,
  ];

  const coordinativeVariabilityModalSeriesHip_Right = [
    ...coordinativeVariabilitySeriesPelvisThigh_Right,
    ...coordinativeVariabilitySeriesPelvisThighT_Right,
    ...coordinativeVariabilitySeriesPelvisCThighT_Right,
    ...coordinativeVariabilitySeriesPelvisCThigh_Right,
    ...coordinativeVariabilitySeriesAllHip_Right,
  ];

  const crpModalSeriesKnee_Left = [
    ...crpSeriesDataThighCalf_Left,
    ...crpSeriesDataThighCalfT_Left,
    ...crpSeriesDataThighCCalfT_Left,
  ];

  const coordinativeVariabilityModalSeriesKnee_Left = [
    ...coordinativeVariabilitySeriesThighCalf_Left,
    ...coordinativeVariabilitySeriesThighCalfT_Left,
    ...coordinativeVariabilitySeriesThighCCalfT_Left,
    ...coordinativeVariabilitySeriesAllKnee_Left,
  ];

  const crpModalSeriesKnee_Right = [
    ...crpSeriesDataThighCalf_Right,
    ...crpSeriesDataThighCalfT_Right,
    ...crpSeriesDataThighCCalfT_Right,
  ];

  const coordinativeVariabilityModalSeriesKnee_Right = [
    ...coordinativeVariabilitySeriesThighCalf_Right,
    ...coordinativeVariabilitySeriesThighCalfT_Right,
    ...coordinativeVariabilitySeriesThighCCalfT_Right,
    ...coordinativeVariabilitySeriesAllKnee_Right,
  ];

  const crpModalSeriesAnkle_Left = [...crpSeriesDataCalfFoot_Left, ...crpSeriesDataCalfTFootC_Left];

  const coordinativeVariabilityModalSeriesAnkle_Left = [
    ...coordinativeVariabilitySeriesCalfFoot_Left,
    ...coordinativeVariabilitySeriesCalfTFootC_Left,
    ...coordinativeVariabilitySeriesAllAnkle_Left,
  ];

  const crpModalSeriesAnkle_Right = [...crpSeriesDataCalfFoot_Right, ...crpSeriesDataCalfTFootC_Right];

  const coordinativeVariabilityModalSeriesAnkle_Right = [
    ...coordinativeVariabilitySeriesCalfFoot_Right,
    ...coordinativeVariabilitySeriesCalfTFootC_Right,
    ...coordinativeVariabilitySeriesAllAnkle_Right,
  ];

  //#endregion

  //#region GraphData
  const mean: VariabilityGraphData = {
    Pelvis: {
      Left: {
        crpData: meanCrpSeriesPelvisThigh_Left,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewHip_Left,
      },
      Right: {
        crpData: meanCrpSeriesPelvisThigh_Right,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewHip_Right,
      },
    },
    Knee: {
      Left: {
        crpData: meanCrpSeriesThighCalf_Left,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewKnee_Left,
      },
      Right: {
        crpData: meanCrpSeriesThighCalf_Right,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewKnee_Right,
      },
    },
    Ankle: {
      Left: {
        crpData: meanCrpSeriesCalfFoot_Left,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewAnkle_Left,
      },
      Right: {
        crpData: meanCrpSeriesCalfFoot_Right,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewAnkle_Right,
      },
    },
  };

  const allStrides: VariabilityGraphData = {
    Pelvis: {
      Left: {
        crpData: crpSeriesPelvisThigh_Left,
        coordinativeVariabilityData: coordinativeVariabilityOverviewHip_Left,
      },
      Right: {
        crpData: crpSeriesPelvisThigh_Right,
        coordinativeVariabilityData: coordinativeVariabilityOverviewHip_Right,
      },
    },
    Knee: {
      Left: {
        crpData: crpSeriesThighCalf_Left,
        coordinativeVariabilityData: coordinativeVariabilityOverviewKnee_Left,
      },
      Right: {
        crpData: crpSeriesThighCalf_Right,
        coordinativeVariabilityData: coordinativeVariabilityOverviewKnee_Right,
      },
    },
    Ankle: {
      Left: {
        crpData: crpSeriesCalfFoot_Left,
        coordinativeVariabilityData: coordinativeVariabilityOverviewAnkle_Left,
      },
      Right: {
        crpData: crpSeriesCalfFoot_Right,
        coordinativeVariabilityData: coordinativeVariabilityOverviewAnkle_Right,
      },
    },
  };

  const modalData: VariabilityGraphData = {
    Pelvis: {
      Left: {
        crpData: crpModalSeriesHip_Left,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesHip_Left,
      },
      Right: {
        crpData: crpModalSeriesHip_Right,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesHip_Right,
      },
    },
    Knee: {
      Left: {
        crpData: crpModalSeriesKnee_Left,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesKnee_Left,
      },
      Right: {
        crpData: crpModalSeriesKnee_Right,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesKnee_Right,
      },
    },
    Ankle: {
      Left: {
        crpData: crpModalSeriesAnkle_Left,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesAnkle_Left,
      },
      Right: {
        crpData: crpModalSeriesAnkle_Right,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesAnkle_Right,
      },
    },
  };

  //#endregion

  //#region Legends
  const pelvisLegend = createVariabilityLegend(
    crpSeriesPelvisThigh_Left,
    coordinativeVariabilityOverviewHip_Left,
    coordinativeVariabilityOverviewHip_Right,
  );

  const kneeLegend = createVariabilityLegend(
    crpSeriesThighCalf_Left,
    coordinativeVariabilityOverviewKnee_Left,
    coordinativeVariabilityOverviewKnee_Right,
  );

  const ankleLegend = createVariabilityLegend(
    crpSeriesCalfFoot_Left,
    coordinativeVariabilityOverviewAnkle_Left,
    coordinativeVariabilityOverviewAnkle_Right,
  );

  const walkVariabilityData: walkVariabilityData = {
    graphAnnotationsHipAnkle_Left,
    graphAnnotationsHipAnkle_Right,
    graphAnnotationsKnee_Left,
    graphAnnotationsKnee_Right,

    annotations_Left,
    highlightsHip_Left,
    highlightsKnee_Left,
    highlightsKnee_Right,
    annotations_Right,
    highlightsHip_Right,
    mean,
    allStrides,
    modalData,
    pelvisLegend,
    kneeLegend,
    ankleLegend,
  };

  //#endregion

  //#endregion

  //#region Kinematic Data
  const pelvisKinematicData = getAllKinematicData(data, 'Pelvis');
  const hipKinematicData = getAllKinematicData(data, 'Hip');
  const kneeKinematicData = getAllKinematicData(data, 'Knee');
  const ankleKinematicData = getAllKinematicData(data, 'Ankle');
  const graphAnnotations = createKinematicGraphsAnnotations(annotations_Left, annotations_Right);

  const tableClassNames = {
    Pelvis: {
      Left: color(getPelvisScore(romData[0][0])),
      Right: color(getPelvisScore(romData[0][1])),
      Symmetry: color(getHighSymmetryScore(romData[0][2])),
    },
    Hip: {
      Left: color(getHipScore(romData[1][0])),
      Right: color(getHipScore(romData[1][1])),
      Symmetry: color(getSymmetryScore(romData[1][2])),
      ExtensionLeft: color(getHipExtensionScore(romData[3][0])),
      ExtensionRight: color(getHipExtensionScore(romData[3][1])),
    },
    Knee: {
      Left: color(getKneeScore(romData[4][0])),
      Right: color(getKneeScore(romData[4][1])),
      Symmetry: color(getSymmetryScore(romData[4][2])),
      stanceFlexionLeft: color(getStanceFlexionScore(romData[5][0])),
      stanceFlexionRight: color(getStanceFlexionScore(romData[5][1])),
      varusValgusLeft: color(getVarusValgusScore(romData[6][0])),
      varusValgusRight: color(getVarusValgusScore(romData[6][1])),
    },
    Ankle: {
      Left: color(getAnkleScore(romData[7][0])),
      Right: color(getAnkleScore(romData[7][1])),
      Symmetry: color(getSymmetryScore(romData[7][2])),
    },
  };

  const walkKinematicData: WalkKinematicData = {
    romData,
    tableData,
    graphAnnotations,
    tableClassNames,
    pelvisKinematicData,
    hipKinematicData,
    kneeKinematicData,
    ankleKinematicData,
  };

  //#endregion

  //#region Joint Coupling Data
  const leftKneeAngles = data.stridesLeft.leftKnee;
  const leftHipAngles = data.stridesLeft.leftHip;
  const seriesJointCouplingData_Left = toJointCouplingData(leftKneeAngles, leftHipAngles);
  const heelOff_Left = data?.reportId.pointsOfInterests.col0[0];
  const toeOff_Left = data?.reportId.pointsOfInterests.col0[1];
  const midSwing_Left = data?.reportId.pointsOfInterests.col0[2];
  const terminalSwing_Left = data?.reportId.pointsOfInterests.col0[5];
  const initialContact_Left = data?.reportId.pointsOfInterests.col0[3];
  const midStance_Left = data?.reportId.pointsOfInterests.col0[4];
  const meanLeftKneeAngle = data?.reportId.kinematicData.col6;
  const meanLeftHipAngle = data?.reportId.kinematicData.col2;

  const pointsOfInterestJointCouplingData_Left: JointCouplingSeriesData[] = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanLeftHipAngle[heelOff_Left],
          y: meanLeftKneeAngle[heelOff_Left],
        },
        {
          x: meanLeftHipAngle[toeOff_Left],
          y: meanLeftKneeAngle[toeOff_Left],
        },
        {
          x: meanLeftHipAngle[midSwing_Left],
          y: meanLeftKneeAngle[midSwing_Left],
        },
        {
          x: meanLeftHipAngle[terminalSwing_Left],
          y: meanLeftKneeAngle[terminalSwing_Left],
        },
        {
          x: meanLeftHipAngle[initialContact_Left],
          y: meanLeftKneeAngle[initialContact_Left],
        },
        {
          x: meanLeftHipAngle[midStance_Left],
          y: meanLeftKneeAngle[midStance_Left],
        },
      ],
    },
  ];

  const seriesJointCoupling_Left_Initial: JointCouplingSeriesData[] = [
    ...seriesJointCouplingData_Left,
    ...pointsOfInterestJointCouplingData_Left,
  ];

  const rightKneeAngles = data?.stridesRight.rightKnee;
  const rightHipAngles = data?.stridesRight.rightHip;
  const seriesJointCouplingData_Right = toJointCouplingData(rightKneeAngles, rightHipAngles);
  const heelOff_Right = data?.reportId.pointsOfInterests.col1[0];
  const toeOff_Right = data?.reportId.pointsOfInterests.col1[1];
  const midSwing_Right = data?.reportId.pointsOfInterests.col1[2];
  const terminalSwing_Right = data?.reportId.pointsOfInterests.col1[5];
  const initialContact_Right = data?.reportId.pointsOfInterests.col1[3];
  const midStance_Right = data?.reportId.pointsOfInterests.col1[4];
  const meanRightKneeAngle = data?.reportId.kinematicData.col7;
  const meanRightHipAngle = data?.reportId.kinematicData.col3;
  const pointsOfInterestJointCouplingData_Right: JointCouplingSeriesData[] = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanRightHipAngle[heelOff_Right],
          y: meanRightKneeAngle[heelOff_Right],
        },
        {
          x: meanRightHipAngle[toeOff_Right],
          y: meanRightKneeAngle[toeOff_Right],
        },
        {
          x: meanRightHipAngle[midSwing_Right],
          y: meanRightKneeAngle[midSwing_Right],
        },
        {
          x: meanRightHipAngle[terminalSwing_Right],
          y: meanRightKneeAngle[terminalSwing_Right],
        },
        {
          x: meanRightHipAngle[initialContact_Right],
          y: meanRightKneeAngle[initialContact_Right],
        },
        {
          x: meanRightHipAngle[midStance_Right],
          y: meanRightKneeAngle[midStance_Right],
        },
      ],
    },
  ];

  const seriesJointCoupling_Right_Initial: JointCouplingSeriesData[] = [
    ...seriesJointCouplingData_Right,
    ...pointsOfInterestJointCouplingData_Right,
  ];
  const { xMinValue, xMaxValue, xTickAmount } = calculateXaxisJointCouplingRange(
    seriesJointCouplingData_Left,
    seriesJointCouplingData_Right,
  );

  const highLightJointCoupling_Left = {
    type: 'area',
    name: 'Highlighted Area',
    data: [
      {
        x: xMinValue,
        y: -20,
      },
      {
        x: 0,
        y: -20,
      },
      {
        x: 0,
        y: meanLeftKneeAngle[heelOff_Left],
      },
      {
        x: xMinValue,
        y: meanLeftKneeAngle[heelOff_Left],
      },
    ],
    color: '#F3F7F7',
    opacity: 0.9,
  };

  const highLightJointCoupling_Right = {
    type: 'area',
    name: 'Highlighted Area',
    data: [
      {
        x: xMinValue,
        y: -20,
      },
      {
        x: 0,
        y: -20,
      },
      {
        x: 0,
        y: meanRightKneeAngle[heelOff_Right],
      },
      {
        x: xMinValue,
        y: meanRightKneeAngle[heelOff_Right],
      },
    ],
    color: '#F3F7F7',
    opacity: 0.9,
  };

  const seriesJointCoupling_Left: JointCouplingSeriesData[] = [
    ...seriesJointCoupling_Left_Initial,
    highLightJointCoupling_Left,
  ];

  const seriesJointCoupling_Right: JointCouplingSeriesData[] = [
    ...seriesJointCoupling_Right_Initial,
    highLightJointCoupling_Right,
  ];

  const jointCouplingAxisRange = {
    xMinValue,
    xMaxValue,
    xTickAmount,
  };

  const jointCouplingZoomModalContent = {
    seriesJointCoupling_Left,
    seriesJointCoupling_Right,
  };

  const walkJointCouplingData = {
    seriesJointCoupling_Left,
    seriesJointCoupling_Right,
    jointCouplingAxisRange,
    jointCouplingZoomModalContent,
  };
  //#endregion

  // Overview Data
  const fileInfo = data.fileId;
  const reportType = data.reportId.reportType;

  const walkReportData: WalkReportData = {
    mobilityStatusData,
    analysisData,
    walkVariabilityData,
    walkKinematicData,
    walkJointCouplingData,
    fileInfo,
    reportType,
  };

  return walkReportData;
};
