/** Squat analysis report tab */
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import * as React from 'react';

import { useState } from 'react';

// Components
import { Card } from '../../../components/Card';
import { InfoToast } from '../../../components/InfoToast/InfoToast';
import { ReportModal } from '../../../components/Modal/ReportModal';
import { VariabilityGraphLegenda } from '../components/CustomGraphComponents';
import { FunctionalMovementVariabilityGraph } from '../components/Graphs';
import { VariabilityModalContent } from '../components/Modals';

// Hooks
import { SquatVariabilityData } from '../../../model/reportV2.model';

// Styles
import styles from '../Reports.module.css';

type SquatsHipVariabilityReportProps = {
  tabData: SquatVariabilityData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const SquatsVariabilityReportTab = ({ tabData }: SquatsHipVariabilityReportProps) => {
  const [showInfoToast, setShowInfoToast] = useState(false);

  const [showMoreInfoModal, setShowMoreInfoModal] = useState({
    show: false,
    joint: '',
  });

  React.useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <ReportModal
          isOpen={showMoreInfoModal.show}
          onDidDismiss={() => setShowMoreInfoModal({ show: false, joint: '' })}
          setShowInfo={() => setShowMoreInfoModal({ show: false, joint: '' })}
        >
          <IonGrid className='' style={{ height: '100%' }} fixed>
            {showMoreInfoModal.joint === 'Hip' && (
              <VariabilityModalContent
                crp_Left={tabData.crpSeriesPelvisThigh_Left}
                crp_Right={tabData.crpSeriesPelvisThigh_Right}
                coordinativeVariabilityData_Left={tabData.coordinativeVariabilitySeriesHip_Left}
                coordinativeVariabilityData_Right={tabData.coordinativeVariabilitySeriesHip_Right}
                setShowMoreInfoModal={setShowMoreInfoModal}
              />
            )}
            {showMoreInfoModal.joint === 'Knee' && (
              <VariabilityModalContent
                crp_Left={tabData.crpSeriesThighCalf_Left}
                crp_Right={tabData.crpSeriesThighCalf_Right}
                coordinativeVariabilityData_Left={tabData.coordinativeVariabilitySeriesKnee_Left}
                coordinativeVariabilityData_Right={tabData.coordinativeVariabilitySeriesKnee_Right}
                setShowMoreInfoModal={setShowMoreInfoModal}
              />
            )}
            {showMoreInfoModal.joint === 'Ankle' && (
              <VariabilityModalContent
                crp_Left={tabData.crpSeriesCalfFoot_Left}
                crp_Right={tabData.crpSeriesCalfFoot_Right}
                coordinativeVariabilityData_Left={tabData.coordinativeVariabilitySeriesAnkle_Left}
                coordinativeVariabilityData_Right={tabData.coordinativeVariabilitySeriesAnkle_Right}
                setShowMoreInfoModal={setShowMoreInfoModal}
              />
            )}
          </IonGrid>
        </ReportModal>

        <IonRow className={styles.variabilityRow}>
          <IonCol size='12' className='ion-align-self-stretch'>
            <Card
              style={{ height: 'fit-content', margin: 0 }}
              onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}
            >
              <IonRow
                className='ion-no-padding ion-align-items-center ion-justify-content-evenly'
                style={{ height: '100%' }}
              >
                <IonCol size='2' className='ion-align-self-stretch'>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                    <IonCol size='12' className='ion-no-padding '>
                      <p className={styles.variabilityTitle}>Hip</p>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                    <VariabilityGraphLegenda legend={tabData.pelvisLegend} />
                  </IonRow>
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                  <FunctionalMovementVariabilityGraph
                    crpData={tabData.crpSeriesPelvisThigh_Left}
                    coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewHip_Left}
                  />
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                  <FunctionalMovementVariabilityGraph
                    crpData={tabData.crpSeriesPelvisThigh_Right}
                    coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewHip_Right}
                  />
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>
        <IonRow className={styles.variabilityRow}>
          <IonCol size='12' className='ion-align-self-stretch'>
            <Card
              style={{ height: 'fit-content', margin: 0 }}
              onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}
            >
              <IonRow
                className='ion-no-padding ion-align-items-center ion-justify-content-evenly'
                style={{ height: '100%' }}
              >
                <IonCol size='2' className='ion-align-self-stretch'>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                    <IonCol size='12' className='ion-no-padding'>
                      <p className={styles.variabilityTitle}>Knee</p>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                    <VariabilityGraphLegenda legend={tabData.kneeLegend} />
                  </IonRow>
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                  <FunctionalMovementVariabilityGraph
                    crpData={tabData.crpSeriesThighCalf_Left}
                    coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewKnee_Left}
                  />
                </IonCol>
                <IonCol size='5' className='ion-no-padding'>
                  <FunctionalMovementVariabilityGraph
                    crpData={tabData.crpSeriesThighCalf_Right}
                    coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewKnee_Right}
                  />
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>
        <IonRow className={styles.variabilityRow}>
          <IonCol size='12' className='ion-align-self-stretch'>
            <Card
              style={{ height: 'fit-content', margin: 0 }}
              onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}
            >
              <IonRow
                className='ion-no-padding ion-align-items-center ion-justify-content-evenly'
                style={{ height: '100%' }}
              >
                <IonCol size='2' className='ion-align-self-stretch'>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                    <IonCol size='12' className='ion-no-padding'>
                      <p className={styles.variabilityTitle}>Ankle</p>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '50%' }}>
                    <VariabilityGraphLegenda legend={tabData.ankleLegend} />
                  </IonRow>
                </IonCol>
                <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                  <FunctionalMovementVariabilityGraph
                    crpData={tabData.crpSeriesCalfFoot_Left}
                    coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewAnkle_Left}
                  />
                </IonCol>
                <IonCol size='5' className='ion-no-padding'>
                  <FunctionalMovementVariabilityGraph
                    crpData={tabData.crpSeriesCalfFoot_Right}
                    coordinativeVariabilityData={tabData.coordinativeVariabilityOverviewAnkle_Right}
                  />
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>

        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='All graphs can be enlarged by clicking / tapping on the card.'
        />
      </>
    );
  }
};
