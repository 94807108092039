import { IonGrid } from '@ionic/react';
import { ComponentProps, PropsWithChildren } from 'react';

// Styles
import styles from './DataTable.module.css';

export const DataTable = ({ children }: PropsWithChildren<ComponentProps<typeof IonGrid>>) => {
  return (
    <IonGrid fixed className={styles.table}>
      {children}
    </IonGrid>
  );
};
