import { IonApp, IonPage, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '@ionic/react/css/palettes/dark.system.css';

/* Theme variables */
import './theme/colors.css';
import './theme/globalstyles.css';
import './theme/variables.css';
import './theme/typography.css';
import './theme/swiper.css';
import './theme/pagination.css';
import './theme/floatingButton.css';
import { AuthProvider } from './components/authContext';
import { Routes } from './Routes';
import { LoadingProvider } from './hooks/LoadingContext';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

setupIonicReact({
  mode: 'ios',
  innerHTMLTemplatesEnabled: true,
  scrollAssist: false,
});

const App: React.FC = () => (
  <LoadingProvider>
    <IonApp>
      <IonPage>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </IonPage>
    </IonApp>
  </LoadingProvider>
);
export default App;
