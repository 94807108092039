/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Homepage.
 */

import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ORYXHeader } from '../../components/menuItems/Header';
import './index.css';
import Walking from '../../assets/icons/Walking.png';
import Running from '../../assets/icons/Running.png';
import Squats from '../../assets/icons/Squat.png';
import SingleLegSquat from '../../assets/icons/SingleLegSquat.png';
import { useAuthContext } from '../../components/authContext';
import { uuid } from '../../utilities';
import { MeasurementsCollection } from '../../model';
import { Button } from '../../components/Button';

interface Test {
  id: string;
  Name: string;
  Icon: string;
}

interface Measurements {
  url: string;
  title: string;
  status: string;
  availableOn: string;
  Tests: Test[];
  index: string;
}

const availableTests: Measurements[] = [
  {
    title: 'ORYX Movement Analysis',
    index: uuid(),
    url: 'ORYX',
    status: 'active',
    availableOn: 'capacitor',
    Tests: [
      {
        id: uuid(),
        Name: 'Walking',
        Icon: Walking,
      },
      {
        id: uuid(),
        Name: 'Running',
        Icon: Running,
      },
      {
        id: uuid(),
        Name: 'Squat',
        Icon: Squats,
      },
      {
        id: uuid(),
        Name: 'Single Leg Squat',
        Icon: SingleLegSquat,
      },
    ],
  },
  {
    title: 'ORYX Knee Stability',
    index: uuid(),
    url: 'KneeStability',
    status: 'active',
    availableOn: 'capacitor',
    Tests: [
      {
        id: uuid(),
        Name: 'Walking',
        Icon: Walking,
      },
      {
        id: uuid(),
        Name: 'Running',
        Icon: Running,
      },
      {
        id: uuid(),
        Name: 'Squat',
        Icon: Squats,
      },
      {
        id: uuid(),
        Name: 'Single Leg Squat',
        Icon: SingleLegSquat,
      },
    ],
  },
];

type RouterInfoObject = {
  name: string;
  hardwareId: string;
};

export const MeasurementsPage = () => {
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const history = useHistory();
  const [present] = useIonAlert();
  const platform = isPlatform('capacitor') ? 'capacitor' : 'web';
  const userId = useAuthContext().state.userState?.user.uid;
  const [loading, setLoading] = useState<boolean>(false);

  const [availableGOSystems, setAvailableGOSystems] = useState<RouterInfoObject[]>([]);
  const [availableKSSystems, setAvailableKSSystems] = useState<RouterInfoObject[]>([]);
  const [availableMeasurements, setAvailableMeasurements] = useState<MeasurementsCollection>();
  const [measurmentsCollectionFromCache, setMeasurementsCollectionFromCache] = useState<boolean>(true);
  // const [loading, setLoading] = useState<boolean>(true);

  const [showActionSheet, setShowActionSheet] = useState({ show: false, type: '' });

  type TestIconProps = {
    iconName: string;
  };
  // Icon component for each test
  const TestIcon = ({ iconName }: TestIconProps) => {
    const icon =
      iconName === 'Walking'
        ? Walking
        : iconName === 'Running'
          ? Running
          : iconName === 'Squat'
            ? Squats
            : SingleLegSquat;
    return (
      <IonAvatar slot='start'>
        <img src={icon} alt={iconName} />
      </IonAvatar>
    );
  };

  // const {
  //   ORYXGOCollection,
  //   ORYXKSCollection,
  //   measurementsCollection,
  //   isLoading,
  //   isError,
  //   errorMessage,
  //   clearError,
  //   getAvailableORYXGOSystems,
  //   getAvailableORYXKSSystems,
  //   getAvailableMeasurements,
  // } = useHardwareContext();

  // useEffect(() => {
  //   if (userId) {
  //     getAvailableORYXGOSystems(userId);
  //     getAvailableORYXKSSystems(userId);
  //     // Check the cache for measurementsCollection
  //     getCache('measurements_collection_cache')
  //       .then((cachedValue) => {
  //         if (cachedValue) {
  //           const availableMeasurements = cachedValue as MeasurementsCollection;
  //           if (import.meta.env.MODE) console.log('availableMeasurements: ', availableMeasurements);
  //           setAvailableMeasurements(availableMeasurements);
  //         } else {
  //           getAvailableMeasurements(userId);
  //           setMeasurementsCollectionFromCache(false);
  //         }
  //       })
  //       .catch((err) => {
  //         if (import.meta.env.MODE) console.log('Error retrieving measurements_collection_cache: ', err);
  //       });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isError) {
  //     present({
  //       header: 'Error',
  //       message: errorMessage,
  //       buttons: [
  //         {
  //           text: 'Ok',
  //           handler: () => {
  //             clearError();
  //           },
  //         },
  //       ],
  //     });
  //   }
  // }, [isError]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (ORYXGOCollection) {
  //       Object.entries(ORYXGOCollection).forEach(([key, value]) => {
  //         setAvailableGOSystems((prevValue) => [...prevValue, { hardwareId: key, name: value.alias }]);
  //       });
  //     }
  //     if (ORYXKSCollection) {
  //       Object.entries(ORYXKSCollection).forEach(([key, value]) => {
  //         setAvailableKSSystems((prevValue) => [...prevValue, { hardwareId: key, name: value!.alias }]);
  //       });
  //     }
  //     if (!measurmentsCollectionFromCache && availableMeasurements === undefined && measurementsCollection) {
  //       setAvailableMeasurements(measurementsCollection);
  //       if (import.meta.env.MODE) console.log('measurementsCollection: ', measurementsCollection);
  //     }
  //   }, 2000);
  // }, [isLoading]);

  // const handleTestCardClicked = (Test: Measurement) => {
  //   const availableSystems = Test.title === 'ORYX Movement Analysis' ? availableGOSystems : availableKSSystems;
  //   if (availableSystems.length === 1) {
  //     if (!import.meta.env.MODE || import.meta.env.MODE === 'development') {
  //       history.push(`/measurements/${Test.url}/${availableSystems[0].hardwareId}`, { from: '/measurements' });
  //     } else if (platform != Test.availableOn) {
  //       present({
  //         cssClass: '',
  //         header: 'Not available',
  //         message: 'This test is only available on an iPad',
  //         buttons: [{ text: 'Ok' }],
  //       });
  //     } else {
  //       history.push(`/measurements/${Test.url}/${availableSystems[0].hardwareId}`, { from: '/measurements' });
  //     }
  //   } else {
  //     setShowActionSheet({ show: true, type: Test.title });
  //   }
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, [availableGOSystems, availableKSSystems]);

  return (
    <IonPage id='main'>
      <ORYXHeader backTarget='/dashboard' />
      <IonContent className='ion-padding' id='main' fullscreen color='light'>
        <IonGrid className='Test full_height' fixed>
          <IonRow className='ion-justify-content-end'>
            <IonCol>
              <Button onClick={() => history.push(`/ORYX`, { from: '/measurements' })}>
                Start new ORYX GO session
              </Button>
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center ion-align-self-end'>
            <IonCol size='4'>
              <IonButton
                className='primary'
                onClick={() => {
                  history.push(`/sensortesting`, { from: '/measurements' });
                }}
              >
                Go To SensorTesting
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
// else if (availableMeasurements !== undefined && !isLoading && !loading) {
//   return (
//     <IonPage id='main'>
//       <ORYXHeader backTarget='/dashboard' />
//       <IonContent className='ion-padding' id='main' fullscreen color='light'>
//         <IonGrid>
//           <IonRow>
//             {availableMeasurements === undefined && (
//               <IonCol>
//                 <p>There are no measurements available for this user.</p>
//               </IonCol>
//             )}

//             {Object.values(availableMeasurements).map((Measurement, _index) => {
//               return (
//                 <>
//                   <IonCol class='ion-padding' size='6'>
//                     <IonModal isOpen={showModal.show} onDidDismiss={() => setShowModal({ show: false })}>
//                       <p>Under construction, coming soon...</p>
//                       <IonButton onClick={() => setShowModal({ show: false })}>Close</IonButton>
//                     </IonModal>
//                     <IonActionSheet
//                       isOpen={showActionSheet.show && showActionSheet.type === 'ORYX Movement Analysis'}
//                       onDidDismiss={() => setShowActionSheet({ show: false, type: '' })}
//                       cssClass='my-custom-class'
//                       buttons={[
//                         ...availableGOSystems.map((entry, index) => ({
//                           text: entry.name,
//                           handler: () => {
//                             history.push(`/measurements/ORYX/${availableGOSystems[index].hardwareId}`, {
//                               from: '/measurements',
//                             });
//                             setShowActionSheet({ show: false, type: '' });
//                           },
//                         })),
//                         {
//                           text: 'Cancel',
//                           role: 'cancel',
//                         },
//                       ]}
//                     />
//                     <IonActionSheet
//                       isOpen={showActionSheet.show && showActionSheet.type === 'ORYX Knee Stability'}
//                       onDidDismiss={() => setShowActionSheet({ show: false, type: '' })}
//                       cssClass='my-custom-class'
//                       buttons={[
//                         ...availableKSSystems.map((entry, index) => ({
//                           text: entry.name,
//                           handler: () => {
//                             history.push(`/measurements/KneeStability/${availableKSSystems[index].hardwareId}`, {
//                               from: '/measurements',
//                             });
//                             setShowActionSheet({ show: false, type: '' });
//                           },
//                         })),
//                         {
//                           text: 'Cancel',
//                           role: 'cancel',
//                         },
//                       ]}
//                     />

//                     <IonCard key={Measurement.title} className='measurementCard' button>
//                       <ORYXCardHeader title={Measurement.title} setShowInfo={setShowModal} zoom={false} info={true} />

//                       {isLoading || loading ? (
//                         <IonCardContent className='loading_Content'>
//                           <IonRow className='ion-justify-content-center ion-align-items-center'>
//                             <IonCol size='12' className='ion-justify-content-center ion-align-items-center'>
//                               <IonSpinner color='primary' name='bubbles' />
//                             </IonCol>
//                           </IonRow>
//                         </IonCardContent>
//                       ) : (
//                         <IonCardContent
//                           className='loaded_Content'
//                           onClick={(e) => {
//                             handleTestCardClicked(Measurement);
//                           }}>
//                           {Object.values(Measurement.Tests)
//                             .sort((a, b) => a.priority - b.priority)
//                             .map((Test, index) => {
//                               return (
//                                 <IonItem lines='none' detail={false} key={index}>
//                                   <TestIcon iconName={Test.icon} />
//                                   <IonLabel>{Test.name}</IonLabel>
//                                 </IonItem>
//                               );
//                             })}
//                         </IonCardContent>
//                       )}
//                     </IonCard>
//                   </IonCol>
//                 </>
//               );
//             })}
//           </IonRow>
//         </IonGrid>
//       </IonContent>
//     </IonPage>
//   );
// }
