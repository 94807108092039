import { IconProps } from '..';

export const IconArm = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.90796 2.35736C9.23063 2.41936 9.70196 2.5807 9.9293 3.03536C10.4273 4.03136 10.016 4.97803 9.57463 5.5667C9.28663 5.9507 8.91596 6.31203 8.48463 6.53336C8.39463 6.58003 8.2113 6.6667 7.99996 6.6667C7.60596 6.6667 7.21996 6.48736 6.9033 6.26336C6.8433 6.46736 6.7853 6.69136 6.7353 6.92869C6.63641 7.38407 6.58281 7.8481 6.5753 8.31403C7.2773 8.08736 7.9953 8.11136 8.61396 8.4607C9.07858 8.09504 9.61344 7.82886 10.1853 7.6787C11.052 7.45136 12.092 7.4827 13.0366 8.11203C13.7133 8.56336 14.226 9.41336 14.3293 10.3434C14.4373 11.3127 14.1006 12.3727 13.0826 13.1874C12.364 13.762 11.2686 13.984 10.2606 14.0647C9.2173 14.1487 8.1153 14.0914 7.25996 13.996C5.67463 13.82 4.4573 13.3774 3.60663 12.9207C3.04863 12.6207 2.2853 12.206 2.0433 11.5707C1.87996 11.14 1.92263 10.6174 1.96263 10.1694C2.01596 9.58203 2.14663 8.8487 2.3913 8.05403C2.8793 6.46803 3.83463 4.59136 5.5753 3.1527C6.11063 2.71003 7.00663 2.43536 7.75663 2.34336C8.1433 2.29603 8.54996 2.28936 8.90796 2.35736Z'
        fill={color}
      />
    </svg>
  );
};
