import {
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
} from '@ionic/react';

// Styles
import styles from './ReportModalHeader.module.css';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';
import { closeCircleOutline } from 'ionicons/icons';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface ReportModalHeaderProps extends ComponentProps<typeof IonHeader> {
  title: string;
  subtitle?: string;
  setShowInfo?: () => void;
  buttons?: boolean;
}

export const ReportModalHeader = ({
  title,
  subtitle,
  setShowInfo,
  buttons,
  ...props
}: PropsWithChildren<ReportModalHeaderProps>) => {
  return (
    <IonHeader className={styles.ReportModal_Header} {...props} mode='ios'>
      <IonGrid fixed className='ion-no-padding'>
        <IonRow className={mergeClassNames('ion-align-items-center', styles['header'])}>
          <IonCol size='11' className={styles.content}>
            <IonCardTitle className={styles.title}>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className={styles.subTitle}>{subtitle}</IonCardSubtitle>}
          </IonCol>
          {buttons && setShowInfo && (
            <IonCol size='1' className='ion-justify-content-center'>
              <IonButtons className='ion-justify-content-end ion-padding-end'>
                <IonButton
                  slot='icon-only'
                  fill='clear'
                  className={styles.modal_Header_Button}
                  onClick={() => setShowInfo()}
                >
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonButtons>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonHeader>
  );
};
