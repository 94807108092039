/** Component for line graphs with reference lines */
import * as React from 'react';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';

type KneePositionGraphProps = {
  data: any;
  title: string;
  subtitle: string;
  strokeWidth: number;
};

export const KneePositionGraph = ({ data, title, subtitle, strokeWidth }: KneePositionGraphProps) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [data]);

  const markerSizes = [];
  const markerColors = [];
  for (const value of Object.values(data)) {
    const Value = value as unknown as any;
    const type = Value.type;
    const name = Value.name;
    if (type === 'scatter') {
      markerSizes.push(4);
      markerColors.push('#E81A1A');
    } else {
      markerSizes.push(0);
      markerColors.push('#E81A1A');
    }
  }
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
      min: -0.5,
      max: 0.5,
      tickAmount: 4,
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(1);
        },
        style: {
          fontSize: '8px',
        },
      },
      min: 0,
      max: 0.9,
      tickAmount: 4,
      forceNiceScale: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    stroke: {
      width: strokeWidth,
      dashArray: [0],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    markers: {
      size: markerSizes,
      colors: markerColors,
      strokeWidth: 1,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: '#000',
          label: {
            text: 'COM',
            position: 'top',
            orientation: 'horizontal',
            style: {
              fontSize: '8px',
              fontFamily: 'Montserrat',
              color: '#000',
            },
          },
        },
      ],
    },
    title: {
      text: title,
      align: 'center',
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#707070',
      },
    },
    subtitle: {
      text: subtitle,
      align: 'center',
      margin: -10,
      floating: false,
      offsetY: 25,
      style: {
        fontSize: '10px',
        fontFamily: 'Montserrat',
      },
    },
  };

  return (
    <>
      <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        {!loading ? (
          <Chart options={options} series={data} type='line' />
        ) : (
          <IonSpinner name='bubbles' color='primary' />
        )}
      </div>
    </>
  );
};
