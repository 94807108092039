import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import { ChangeEvent, DragEvent, MouseEvent, useRef, useState } from 'react';

// Components
import { Tag } from '../Tag/Tag';
import { FileInput } from './fileInput';

// Styles
import styles from './uploadArea.module.css';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Images
import { trashBinOutline } from 'ionicons/icons';
import fileUploadIcon from '../../assets/icons/fileUploadIcon.svg';
import csvFileIcon from '../../assets/icons/csvFileIcon.svg';
import { Button } from '../Button';

export interface IUploadAreaProps {
  onChange?: ((files: FileList) => void) | undefined;
  onFileRemove?: ((event: MouseEvent<HTMLIonButtonElement>) => void) | undefined;
}
export const UploadArea = ({ onChange, onFileRemove }: IUploadAreaProps) => {
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log(files && files.length > 0);
    if (files && files.length > 0) {
      const filename = files.length > 1 ? `${files.length} files` : files[0].name;

      console.log({ event });

      setFileName(filename);
      onChange && onChange(files);
    }
  };

  // drag state
  const [dragActive, setDragActive] = useState(false);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const files = event.dataTransfer.files;

    if (files && files.length > 0) {
      const filename = files.length > 1 ? `${files.length} files` : event.dataTransfer.files[0].name;
      setFileName(filename);
      onChange && onChange(files);
    }
  };

  const handleClick = function (event: MouseEvent<HTMLDivElement> | MouseEvent<HTMLIonButtonElement>) {
    event.stopPropagation();
    setDragActive(false);

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <IonGrid fixed>
      <div
        className={mergeClassNames(dragActive && 'dragActive', styles.inner)}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <IonRow className='ion-justify-content-center'>
          <IonIcon
            src={fileName ? csvFileIcon : fileUploadIcon}
            className={mergeClassNames(dragActive && styles.activeIcon, styles.icon)}
          />
        </IonRow>
        {fileName ? (
          <>
            <IonRow className='ion-justify-content-center'>
              <IonCol className='ion-justify-content-center'>
                <IonButton
                  fill='clear'
                  size='small'
                  expand='full'
                  className='errorIcon'
                  onClick={(event) => {
                    event.preventDefault();
                    setFileName('');
                    onFileRemove && onFileRemove(event);
                  }}
                >
                  <IonIcon src={trashBinOutline} slot='icon-only' />
                </IonButton>
                <IonText className={styles.title}>{fileName.toString()}</IonText>
              </IonCol>
            </IonRow>
          </>
        ) : (
          <>
            <IonRow className='ion-justify-content-center'>
              <IonCol>
                <FileInput onChange={handleFileChange} ref={fileInputRef}>
                  <Button size='small' onClick={handleClick}>
                    Choose a file
                  </Button>
                </FileInput>
              </IonCol>
            </IonRow>
          </>
        )}
      </div>
      <IonRow>
        <IonCol>
          <Tag size='sm'>.CSV files only</Tag>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
