import { IonCol, IonGrid, IonText } from '@ionic/react';

import styles from './sectionHeader.module.css';

interface SectionHeaderProps {
  title: string;
  subTitle?: string;
}

export const SectionHeader = ({ title, subTitle }: SectionHeaderProps) => {
  return (
    <IonGrid className={styles.header}>
      <IonCol size='12' className={styles.wrapper}>
        <h1 className={styles.title}>{title}</h1>

        {subTitle && <IonText className={styles['sub-title']}>{subTitle}</IonText>}
      </IonCol>
    </IonGrid>
  );
};
