import { ReportInfo, ReportInfoDict, ReportInfoId, ReportStatus, ReportType } from '../model';

export const sortReportInfosDict = (reportInfoDict: ReportInfoDict): ReportInfoDict => {
  // Convert dictionary to an array of entries (id, reportInfo)
  const entries = Object.entries(reportInfoDict) as [ReportInfoId, ReportInfo][];
  const filteredEntries = entries.filter(([_, reportInfo]) => {
    return (
      reportInfo.status !== ReportStatus.DELETED &&
      reportInfo.status !== ReportStatus.ERROR &&
      reportInfo.reportType !== ReportType.ERROR &&
      reportInfo.reportType !== ReportType.KNEEL &&
      reportInfo.reportType !== ReportType.KNEER
    );
  });

  // Sort entries based on the `uploadTimestamp`
  const sortedEntries = filteredEntries.sort((a, b) => {
    const timeA = a[1].uploadTimestamp.getTime();
    const timeB = b[1].uploadTimestamp.getTime();
    return timeB - timeA;
  });

  // Convert the sorted array of entries back into a dictionary
  const sortedDict: ReportInfoDict = Object.fromEntries(sortedEntries);

  return sortedDict;
};
