import { useMemo, useState } from 'react';
import { IonContent, IonFooter, IonHeader, IonImg, IonList, IonMenu, IonToolbar } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';

// Styles
import styles from './Sidebar.module.css';

// Assets
import quickLinkBg from '../../assets/bg-pattern-sidebar.png';

// Components
import { ORYXLogo } from '../ORYXLogo/ORYXLogo';
import { Divider } from '../Divider/Divider';
import { NavItem } from '../NavItem/NavItem';
import { ProfileModalContent } from '../ProfileModal/ProfileModalContent';
import { Popover } from '../Popover/Popover';
import { Modal } from '../Modal/Modal';
import { Button } from '../Button';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';
import { isDevelopment, isProduction } from '../../utilities/environment';
import { isRouteMatch } from '../../utilities/isRouteMatch';
import { useAuthContext } from '../authContext';
import { useUserInfoContext } from '../userInfoContext';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';
import type { IconName } from '../Icon';

type SidebarProps = ComponentProps<typeof IonMenu>;

interface AppPage {
  title: string;
  url: string;
  icon?: IconName;
  identifier: string;
  status: string;
  showOn: 'development' | 'production';
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/dashboard',
    icon: 'home',
    identifier: 'dashboard',
    status: 'active',
    showOn: 'production',
  },
  {
    title: 'Reports',
    url: '/reports',
    identifier: 'reports',
    icon: 'chart',
    status: 'active',
    showOn: 'production',
  },
  {
    title: 'New test',
    url: '/measurements',
    identifier: 'measurements',
    icon: 'test',
    status: 'active',
    showOn: 'development',
  },
  {
    title: 'Pitching analysis',
    url: '/Pitching',
    identifier: 'pitching',
    icon: 'ball',
    status: 'active',
    showOn: 'development',
  },
  {
    title: 'Training Builder Tool',
    url: '/training-builder',
    identifier: 'trainingBuilder',
    icon: 'arm',
    status: 'disabled',
    showOn: 'development',
  },
  {
    title: 'Customers',
    url: '/customers',
    identifier: 'customers',
    icon: 'person',
    status: 'disabled',
    showOn: 'development',
  },
  {
    title: 'Knowledge Base',
    url: '/docs',
    icon: 'education',
    identifier: 'docs',
    status: 'active',
    showOn: 'development',
  },
];

export const Sidebar = ({ children, ...props }: PropsWithChildren<SidebarProps>) => {
  const location = useLocation();
  const { userInfo } = useUserInfoContext();
  const { signOut } = useAuthContext();
  const history = useHistory();

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const filteredPagesByEnvironment = appPages.filter((page) => {
    if (isProduction) {
      return page.showOn === 'production';
    } else return page;
  });

  const sidebarHiddenPages = ['/reportsV1', '/reportsV2', '/upload', '/pitching'];

  const shouldHideSidebar = useMemo(() => {
    return sidebarHiddenPages.some((page) => location.pathname.toLowerCase().includes(page.toLowerCase()));
  }, [location.pathname]);

  return (
    <IonMenu
      contentId='main'
      type='push'
      className={mergeClassNames(props.className, styles.sidebar, shouldHideSidebar && styles.hidden)}
      {...props}
    >
      <IonHeader className={mergeClassNames(styles.header, 'ion-no-border')}>
        <IonToolbar className={styles.toolbar}>
          <a href='/dashboard' className={styles.logo}>
            <ORYXLogo />
          </a>
        </IonToolbar>
        <Divider />
      </IonHeader>

      {/* Primary menu */}
      <IonContent>
        <IonList className={styles.menu}>
          {filteredPagesByEnvironment.map((page) => (
            <NavItem
              key={page.identifier}
              icon={page.icon}
              isActive={isRouteMatch(page.url, location.pathname)}
              routerLink={page.url}
              disabled={page.status === 'disabled'}
              routerDirection='none'
            >
              {page.title}
            </NavItem>
          ))}
        </IonList>
      </IonContent>

      <Divider />

      <IonFooter className={mergeClassNames(styles.footer, 'ion-no-border')}>
        {/* Language menu */}
        <IonList className={styles.menu}>
          <NavItem detail={true} icon='language' id='trigger-language-popover'>
            English
            <Popover
              trigger='trigger-language-popover'
              triggerAction='click'
              dismissOnSelect={true}
              side='left'
              alignment='end'
              className={styles.popover}
            >
              <IonList className={styles.popoverList}>
                <NavItem href='/dashboard'>English</NavItem>
                <Divider />
                <NavItem href='/nl/dashboard' disabled>
                  Nederlands
                </NavItem>
              </IonList>
            </Popover>
          </NavItem>
          {/* <NavItem icon='settings' href='/settings'> */}
          {isDevelopment && <NavItem icon='settings'>Settings</NavItem>}
        </IonList>

        {/* User menu */}
        <IonList className={styles.menu}>
          <NavItem detail={true} isUser icon='person' id='trigger-user-popover'>
            {userInfo.firstName ?? userInfo.lastName ?? 'User'}

            <Popover
              trigger='trigger-user-popover'
              triggerAction='click'
              dismissOnSelect={true}
              side='top'
              alignment='start'
              className={mergeClassNames(styles.popover, styles.popoverUser)}
            >
              <IonList className={styles.popoverList}>
                <NavItem onClick={() => setIsProfileModalOpen(true)}>Your profile</NavItem>
                <Divider />
                <NavItem
                  isDanger
                  onClick={() => {
                    signOut();
                    history.push('/');
                  }}
                >
                  Sign out
                </NavItem>
              </IonList>
            </Popover>
          </NavItem>
        </IonList>

        {/* Profile modal */}
        <Modal
          isOpen={isProfileModalOpen}
          onDidDismiss={() => setIsProfileModalOpen(false)}
          header='Profile'
          footer={
            <>
              <Button variant='primary' onClick={() => setIsProfileModalOpen(false)}>
                Close
              </Button>
            </>
          }
        >
          <ProfileModalContent />
        </Modal>

        <IonImg src={quickLinkBg} className={styles.background} />
      </IonFooter>
    </IonMenu>
  );
};
