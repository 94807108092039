import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { Suspense, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

// Hooks
import { createWalkReportData } from '../../../hooks/useReports';

//Context & models
import { useReportContext } from '../../../components/reportContext';
import { ReportInfoId } from '../../../model';
import { WalkReportData } from '../../../model/reportV2.model';

// Components
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageTopBar } from '../../../components/PageTopBar/PageTopBar';
import { SegmentedControl, type SegmentButton } from '../../../components/SegmentedControl';

// Utils
import { Loading } from '../../../components/Loading/Loading';
import { getTitleAndDescriptionFromReportData } from '../../../utilities/getTitleAndDescriptionFromReportData';
import { OverviewReportTab } from '../OverviewReportTab';
import { WalkAnalysisReportTab } from './WalkAnalysisReportTab';
import { WalkJointCouplingReportTab } from './WalkJointCouplingReportTab';
import { WalkKinematicsReportTab } from './WalkKinematicsReportTab';
import { WalkMobilityReportTab } from './WalkMobilityStatusReportTab';
import { WalkVariabilityReportTab } from './WalkVariabilityReportTab';

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const WalkReportDetailPage = () => {
  const { id } = useParams<ParamTypes>();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('mobilitystatus');
  const [walkReportData, setWalkReportData] = useState<WalkReportData | undefined>();

  const { getReportData, dataReady, data, setDataReady, resetData } = useReportContext();

  const history = useHistory();

  const { title, description } = getTitleAndDescriptionFromReportData(walkReportData);

  useEffect(() => {
    getReportData(id);
    // Cleanup the data when the component unmounts
    return () => {
      setLoading(true);
      setWalkReportData(undefined);
      setDataReady(false); // Reset dataReady in the context
      resetData();
    };
  }, [id]);

  useEffect(() => {
    if (data) {
      setWalkReportData(createWalkReportData(data));
    } else {
      setWalkReportData(undefined);
    }
  }, [dataReady]);

  useEffect(() => {
    if (walkReportData !== undefined && data) setLoading(false);
  }, [walkReportData]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') console.log('reportData', walkReportData);
  }, [walkReportData]);

  const handleOnSegmentClick = (target: string) => {
    setActiveTab(target);
    // history.push(`/reportsV2/Walk/${id}/${target}`);/
  };

  const segments: SegmentButton[] = [
    { title: 'Mobility status', value: 'mobilitystatus', onClick: () => handleOnSegmentClick('mobilitystatus') },
    { title: 'Analysis', value: 'analysis', onClick: () => handleOnSegmentClick('analysis') },
    { title: 'Kinematics', value: 'kinematics', onClick: () => handleOnSegmentClick('kinematics') },
    { title: 'Variability', value: 'variability', onClick: () => handleOnSegmentClick('variability') },
    { title: 'Joint coupling', value: 'jointcoupling', onClick: () => handleOnSegmentClick('jointcoupling') },
    { title: 'Overview', value: 'overview', onClick: () => handleOnSegmentClick('overview') },
  ];

  return (
    <IonPage>
      <PageTopBar title={title} description={description} />
      <PageHeader backTarget='/reports' isLoading={loading}>
        <SegmentedControl variant='default' value={activeTab} segments={segments} />
      </PageHeader>
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed style={{ height: '100%' }}>
          {loading && <Loading />}
          {walkReportData && (
            <>
              <Suspense fallback={<Loading />}>
                {activeTab === 'mobilitystatus' && (
                  <WalkMobilityReportTab tabData={walkReportData.mobilityStatusData} />
                )}
                {activeTab === 'analysis' && <WalkAnalysisReportTab tabData={walkReportData.analysisData} />}
                {activeTab === 'variability' && (
                  <WalkVariabilityReportTab tabData={walkReportData.walkVariabilityData} />
                )}
                {activeTab === 'kinematics' && <WalkKinematicsReportTab tabData={walkReportData.walkKinematicData} />}
                {activeTab === 'jointcoupling' && (
                  <WalkJointCouplingReportTab tabData={walkReportData.walkJointCouplingData} />
                )}
                {activeTab === 'overview' && (
                  <OverviewReportTab
                    fileInfo={walkReportData.fileInfo}
                    reportType={walkReportData.reportType}
                    id={id}
                  />
                )}
              </Suspense>
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
