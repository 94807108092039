import { PelvisAngularExcursionGraphAnnotations } from '../../../model/reportV2.model';

/**
 * Creates annotations for kinematic graphs.
 *
 * @param annotations_Left - An array of annotations for the left graph.
 * @param annotations_Right - An array of annotations for the right graph.
 * @returns An array of graph annotations or undefined.
 */
export function createPelvisAngularExcursionGraphAnnotations(
  annotations_Left: any[],
  annotations_Right: any[],
): PelvisAngularExcursionGraphAnnotations {
  if (annotations_Left[5] > annotations_Left[3] && annotations_Right[5] < annotations_Right[3]) {
    const Left = [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];
    const Right = [
      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[5],
        borderColor: '#00E396',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];
    const pelvisAngularExcursionGraphAnnotations = {
      Left,
      Right,
    };
    return pelvisAngularExcursionGraphAnnotations;
  } else if (annotations_Left[5] < annotations_Left[3] && annotations_Right[5] > annotations_Right[3]) {
    const Left = [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[5],
        borderColor: '#c2c2c2',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];

    const Right = [
      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];

    const pelvisAngularExcursionGraphAnnotations = {
      Left,
      Right,
    };
    return pelvisAngularExcursionGraphAnnotations;
  } else if (annotations_Left[5] > annotations_Left[3] && annotations_Right[5] > annotations_Right[3]) {
    const Left = [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];

    const Right = [
      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];

    const pelvisAngularExcursionGraphAnnotations = {
      Left,
      Right,
    };
    return pelvisAngularExcursionGraphAnnotations;
  } else {
    const Left = [
      {
        x: annotations_Left[1],
        borderColor: '#c2c2c2',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[2],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[5],
        borderColor: '#c2c2c2',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[3],
        borderColor: '#c2c2c2',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[4],
        borderColor: '#c2c2c2',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Left[0],
        borderColor: '#c2c2c2',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];

    const Right = [
      {
        x: annotations_Right[1],
        borderColor: '#00E396',
        label: {
          text: 'TO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[2],
        borderColor: '#00E396',
        label: {
          text: 'MSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[5],
        borderColor: '#00E396',
        label: {
          text: 'TSw',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[3],
        borderColor: '#00E396',
        label: {
          text: 'IC',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[4],
        borderColor: '#00E396',
        label: {
          text: 'MSt',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
      {
        x: annotations_Right[0],
        borderColor: '#00E396',
        label: {
          text: 'HO',
          borderColor: 'transparent',
          position: 'bottom',
          orientation: 'horizontal',
          offsetY: 15,
          style: {
            background: 'transparent',
            fontSize: '8px',
            fontFamily: 'Montserrat',
          },
        },
      },
    ];
    const pelvisAngularExcursionGraphAnnotations = {
      Left,
      Right,
    };
    return pelvisAngularExcursionGraphAnnotations;
  }
}
