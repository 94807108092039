import { IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react';
import { ApexOptions } from 'apexcharts';
import * as React from 'react';
import Chart from 'react-apexcharts';
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../../components/CardHeader/cardHeader';
import { ReportModal } from '../../../../components/Modal/ReportModal';
import { colors } from '../../../../theme/colors';
import styles from './ApexCharts.module.css';

type StanceLineGraphProps = {
  series: any;
  title: string;
  ICAnnotation: any;
  MStAnnotation: any;
  HOAnnotation: any;
  TOAnnotation: any;
  yAxisMin: number;
  yAxisMax: number;
  subtitle?: string;
  yAxisLabel: string;
};

export const StanceLineGraph = ({
  series,
  title,
  ICAnnotation,
  MStAnnotation,
  HOAnnotation,
  TOAnnotation,
  yAxisMin,
  yAxisMax,
  subtitle,
  yAxisLabel,
}: StanceLineGraphProps) => {
  const [showModal, setShowModal] = React.useState({ show: false });

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      stepSize: 25,
      title: {
        text: yAxisLabel,
        style: {
          color: '#707070',
          fontSize: '8px',
        },
      },
      min: yAxisMin,
      max: yAxisMax,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    // title: {
    //   text: title,
    //   align: 'center',
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: 'bold',
    //     fontFamily: 'Montserrat',
    //     color: '#2066FA',
    //   },
    // },
    subtitle: {
      text: subtitle,
      align: 'center',
      style: {
        fontSize: '10px',
        fontFamily: 'Montserrat',
      },
      offsetY: 10,
    },
    stroke: {
      width: [1],
      dashArray: [0],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    colors: colors,
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: '#c2c2c2',
          label: {
            text: 'TSw',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: ICAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: ICAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: MStAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: MStAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: HOAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: HOAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: TOAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: TOAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: ICAnnotation.x,
          x2: HOAnnotation.x,
          borderColor: '#c2c2c2',
          fillColor: '#ABF263',
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <>
      <ReportModal
        isOpen={showModal.show}
        onDidDismiss={() => {
          setShowModal({ show: false });
        }}
        header={title}
        setShowInfo={() => setShowModal({ show: false })}
      >
        <IonGrid className='' style={{ height: '100%' }}>
          <IonRow className='ion-align-items-center ion-justify-content-center'>
            <IonCol size='11' className=''>
              <div className={styles.reportModalGraph_Wrapper}>
                <Chart options={options} series={series} type='line' width='100%' />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ReportModal>

      <Card
        style={{ height: '100%', margin: 0 }}
        onClick={() => {
          setShowModal({ show: true });
        }}
      >
        <CardHeader title={title} />
        <IonCardContent className='' style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
          <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Chart options={options} series={series} type='line' width='100%' />
          </div>
        </IonCardContent>
      </Card>
    </>
  );
};
