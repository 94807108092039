import React from 'react';
import Chart from 'react-apexcharts';

export const createChartRefs = (keys: string[]) => {
  return keys.reduce(
    (refs, key) => {
      refs[key] = React.createRef<Chart | null>();
      return refs;
    },
    {} as { [key: string]: React.MutableRefObject<Chart | null> },
  );
};

export const chartKeys = {
  Walk: [
    'pelvisKinematicSagittal',
    'pelvisKinematicCoronal',
    'pelvisKinematicTransversal',
    'hipKinematicSagittal',
    'hipKinematicCoronal',
    'hipKinematicTransversal',
    'kneeKinematicSagittal',
    'kneeKinematicCoronal',
    'kneeKinematicTransversal',
    'ankleKinematicSagittal',
    'ankleKinematicCoronal',
    'ankleKinematicTransversal',
  ],
  Run: [
    'footplantAtInitialContact_Left',
    'footplantAtMidStance_Left',
    'footplantAtHeelOff_Left',
    'footplantAtInitialContact_Right',
    'footplantAtMidStance_Right',
    'footplantAtHeelOff_Right',
    'pelvisKinematicSagittal',
    'pelvisKinematicCoronal',
    'pelvisKinematicTransversal',
    'hipKinematicSagittal',
    'hipKinematicCoronal',
    'hipKinematicTransversal',
    'kneeKinematicSagittal',
    'kneeKinematicCoronal',
    'kneeKinematicTransversal',
    'ankleKinematicSagittal',
    'ankleKinematicCoronal',
    'ankleKinematicTransversal',
  ],
  Squat: [
    'pelvisKinematicSagittal',
    'pelvisKinematicCoronal',
    'pelvisKinematicTransversal',
    'hipKinematicSagittal',
    'hipKinematicCoronal',
    'hipKinematicTransversal',
    'kneeKinematicSagittal',
    'kneeKinematicCoronal',
    'kneeKinematicTransversal',
    'ankleKinematicSagittal',
    'ankleKinematicCoronal',
    'ankleKinematicTransversal',
  ],
  SLSquat: [
    'pelvisKinematicSagittal',
    'pelvisKinematicCoronal',
    'pelvisKinematicTransversal',
    'hipKinematicSagittal',
    'hipKinematicCoronal',
    'hipKinematicTransversal',
    'kneeKinematicSagittal',
    'kneeKinematicCoronal',
    'kneeKinematicTransversal',
    'ankleKinematicSagittal',
    'ankleKinematicCoronal',
    'ankleKinematicTransversal',
  ],
};
