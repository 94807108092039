import { IconProps } from '..';

export const IconLanguage = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.00024 14.0001L10.5002 6.50012L14.0002 14.0001M8.00024 12.0001H13.0002M2.00024 3.74746C3.32718 3.58233 4.66308 3.49973 6.00024 3.50012M6.00024 3.50012C6.74691 3.50012 7.48891 3.52546 8.22291 3.57612M6.00024 3.50012V2.00012M8.22291 3.57612C7.45091 7.10546 5.12691 10.0535 2.00024 11.6681M8.22291 3.57612C8.82024 3.61679 9.41291 3.67412 10.0002 3.74746M6.94091 9.41079C5.84755 8.29921 4.981 6.98545 4.38958 5.54279'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
