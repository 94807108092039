import { IonItem, useIonAlert } from '@ionic/react';
import { ChangeEvent, ChangeEventHandler, forwardRef, PropsWithChildren, useState } from 'react';

// Styles
import './index.css';

export interface IFileInputProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  type?: string;
  disabled?: boolean;
}

/** File selection input field
 * Styled to Ionic style as Ionic does not provide a styled file input widget.
 * Note for native IOS/Android this would need to change to the native
 * file selection widgets.
 */
export const FileInput = forwardRef<HTMLInputElement, PropsWithChildren<IFileInputProps>>(
  function FileInput(props, ref) {
    const [file, setFile] = useState('');
    const [present] = useIonAlert();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      // Allowing file type
      const allowedExtensions = /(\.csv)$/i;
      const files = event.target.files;

      if (!files) {
        setFile('');
        return;
      }

      const extension = files[0].type;

      if (extension !== 'text/csv') {
        present({
          cssClass: 'informativeAlert',
          header: 'Filetype not valid',
          message: 'Please upload a .csv file containing the data.',
          buttons: [{ text: 'Ok' }],
        });
        setFile('');
      } else if (files && files.length > 0) {
        const filename = files.length > 1 ? `${files.length} files` : event.target.name;

        setFile(filename);
        props.onChange && props.onChange(event);
      }
    };

    return (
      <IonItem lines='none' className='input-container'>
        <input className='inputfile' type='file' id='file' ref={ref} onChange={handleFileChange} multiple={false} />
        <label htmlFor='file'>{file ? file : 'Upload your file'}</label>
        {props.children}
      </IonItem>
    );
  },
);
