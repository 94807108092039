/** The Run Kinematics report tab */
import { IonCardContent, IonCardTitle, IonCol, IonFabButton, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';

// Components
import { KinematicsGraphV2 } from '../components/Graphs';
import { LegendToast } from '../../../components/LegendToast/LegendToast';
import { InfoToast } from '../../../components/InfoToast/InfoToast';
import { colorPalette } from 'ionicons/icons';

// Hooks
import { RunKinematicData } from '../../../model/reportV2.model';

// Styles
import styles from '../Reports.module.css';

import toggle from '../../../assets/icons/toggle-left.svg';
import { FloatingButton } from '../../../components/FloatingButton/FloatingButton';
import { IconSize } from '../../../components/Icon/types';
import { Icon } from '../../../components/Icon';
import { KinematicsCardHeader } from '../../../components/CardHeader/KinematicsCardHeader';
import { Card } from '../../../components/Card';

type KinematicsReportProps = {
  tabData: RunKinematicData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunKinematicsReportTab = ({ tabData }: KinematicsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [showInfoToast, setShowInfoToast] = useState(false);

  const handleShowToast = () => {
    setShowToast(true);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card style={{ height: 'fit-content', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <KinematicsCardHeader />
              <IonCardContent>
                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px', backgroundColor: 'transparent' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Pelvis</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesPelvis.Sagittal.series}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Sagittal.yAxis}
                          title={'Pelvis - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesPelvis.Coronal.series}
                          subtitle={'Drop (-) / Hike (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Coronal.yAxis}
                          title={'Pelvis - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesPelvis.Transversal.series}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Transversal.yAxis}
                          title={'Pelvis - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Hip</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesHip.Sagittal.series}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Sagittal.yAxis}
                          title={'Hip - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesHip.Coronal.series}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Coronal.yAxis}
                          title={'Hip - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesHip.Transversal.series}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Transversal.yAxis}
                          title={'Hip - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Knee</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesKnee.Sagittal.series}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Sagittal.yAxis}
                          title={'Knee - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesKnee.Coronal.series}
                          subtitle={'Valgus (-) / Varus (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Coronal.yAxis}
                          title={'Knee - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesKnee.Transversal.series}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Transversal.yAxis}
                          title={'Knee - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Ankle</IonCardTitle>
                    </IonRow>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesAnkle.Sagittal.series}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Sagittal.yAxis}
                          title={'Ankle - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesAnkle.Coronal.series}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Coronal.yAxis}
                          title={'Ankle - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesAnkle.Transversal.series}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Transversal.yAxis}
                          title={'Ankle - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>

        <FloatingButton
          slot='fixed'
          horizontal='end'
          vertical='bottom'
          side='top'
          buttonIcon='sliders'
          iconSize={IconSize.large}
        >
          <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
            <IonIcon icon={toggle}></IonIcon>
          </IonFabButton>
          <IonFabButton title={'Show Graph Legend'} onClick={handleShowToast}>
            <IonIcon icon={colorPalette}></IonIcon>
          </IonFabButton>
          <IonFabButton onClick={() => setShowInfoToast(true)} title={'Show Info'}>
            <Icon name={'info'}></Icon>
          </IonFabButton>
        </FloatingButton>

        <LegendToast
          isOpen={showToast}
          onClose={handleCloseToast}
          duration={3000} // 5 seconds
        />
        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='All graphs can be enlarged by clicking / tapping on the graph.'
        />
      </>
    );
  }
};
