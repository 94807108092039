import { IconProps } from '..';

export const IconError = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.2438 2.54011C7.02428 1.1533 8.97582 1.1533 9.75563 2.54011L14.7257 11.3797C15.5055 12.7665 14.5297 14.5 12.9695 14.5H3.02997C1.46968 14.5 0.49459 12.7665 1.2744 11.3797L6.24312 2.54011H6.2438ZM8.00005 6.17842C8.13446 6.17842 8.26337 6.23321 8.35842 6.33074C8.45346 6.42827 8.50686 6.56055 8.50686 6.69847V9.29875C8.50686 9.43668 8.45346 9.56896 8.35842 9.66649C8.26337 9.76402 8.13446 9.81881 8.00005 9.81881C7.86564 9.81881 7.73673 9.76402 7.64168 9.66649C7.54664 9.56896 7.49324 9.43668 7.49324 9.29875V6.69847C7.49324 6.56055 7.54664 6.42827 7.64168 6.33074C7.73673 6.23321 7.86564 6.17842 8.00005 6.17842ZM8.00005 11.899C8.13446 11.899 8.26337 11.8442 8.35842 11.7467C8.45346 11.6492 8.50686 11.5169 8.50686 11.379C8.50686 11.241 8.45346 11.1088 8.35842 11.0112C8.26337 10.9137 8.13446 10.8589 8.00005 10.8589C7.86564 10.8589 7.73673 10.9137 7.64168 11.0112C7.54664 11.1088 7.49324 11.241 7.49324 11.379C7.49324 11.5169 7.54664 11.6492 7.64168 11.7467C7.73673 11.8442 7.86564 11.899 8.00005 11.899Z'
        fill={color}
      />
    </svg>
  );
};
