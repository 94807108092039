import {
  createEuclideanGraphAnnotations,
  createEuclideanGraphData,
  createKinematicYAxis,
  createPelvisAngularExcursionGraphAnnotations,
  createVariabilitySingleSideGraphAnnotations,
  flattenData,
  getThighCoordinationData,
  toGraphData,
  toJointCouplingData,
  toVariabilityData,
} from '.';
import {
  BarGraphData,
  GraphSeriesData,
  HamstringBarGraphData,
  JointCouplingSeriesData,
  KneePositionData,
  LegendEntry,
  RunHamstringFunctionData,
  ScissorsTableData,
  StridesData,
  ThighCoordinationGraphData,
} from '../../../model/reportV2.model';
import { colorCC, colorExtra, colorLeft, colorRight, colorSS, colorST, colorTT } from '../../../theme/colors';
import { uuid } from '../../../utilities';

export function createHamstringFunctionData(
  data: any,
  annotations_Left: number[],
  annotations_Right: number[],
  scissorsTableData: ScissorsTableData,
  HamstringData: HamstringBarGraphData,
  scissorsBarGraphData: BarGraphData,
): RunHamstringFunctionData {
  //#region Pelvis Function
  const seriesPelvisData_All_Left = createAllStridesPelvisData(
    data.stridesLeft.leftPelvis,
    data.stridesCoronalLeft.leftPelvis_Coronal,
    'Left',
  );
  const yAxisLeft = createKinematicYAxis(seriesPelvisData_All_Left);

  const seriesPelvisData_All_Right = createAllStridesPelvisData(
    data.stridesRight.rightPelvis,
    data.stridesCoronalRight.rightPelvis_Coronal,
    'Right',
  );
  const yAxisRight = createKinematicYAxis(seriesPelvisData_All_Right);

  const allStrides = {
    Left: {
      series: seriesPelvisData_All_Left,
      yAxis: yAxisLeft,
    },
    Right: {
      series: seriesPelvisData_All_Right,
      yAxis: yAxisRight,
    },
  };

  const meanDataSagittal_Left = toVariabilityData(data.reportId.kinematicData.col0);
  const meanDataCoronal_Left = toVariabilityData(data.reportId.kinematicData_Coronal.col0);

  const seriesDataLeft = [
    {
      type: 'line',
      name: 'Sagittal',
      data: meanDataSagittal_Left,
      color: colorLeft, // Define your colorLeft variable appropriately
    },
    {
      type: 'line',
      name: 'Frontal',
      data: meanDataCoronal_Left,
      color: '#829696', // Define your colorRight variable appropriately
    },
  ];

  const yAxisData_Left = createKinematicYAxis(seriesDataLeft);

  const meanDataSagittal_Right = toVariabilityData(data.reportId.kinematicData.col7);
  const meanDataCoronal_Right = toVariabilityData(data.reportId.kinematicData_Coronal.col1);

  const seriesDataRight = [
    {
      type: 'line',
      name: 'Sagittal',
      data: meanDataSagittal_Right,
      color: colorRight, // Define your colorLeft variable appropriately
    },
    {
      type: 'line',
      name: 'Frontal',
      data: meanDataCoronal_Right,
      color: '#829696', // Define your colorRight variable appropriately
    },
  ];

  const yAxisData_Right = createKinematicYAxis(seriesDataRight);

  const mean = {
    Left: {
      series: seriesDataLeft,
      yAxis: yAxisData_Left,
    },
    Right: {
      series: seriesDataRight,
      yAxis: yAxisData_Right,
    },
  };

  const pelvisKinematicData = {
    allStrides,
    mean,
  };

  const pelvisAngularExcursionGraphAnnotations = createPelvisAngularExcursionGraphAnnotations(
    annotations_Left,
    annotations_Right,
  );

  //#endregion

  //#region Euclidean Norms
  const proximoDistalSequence_Left = data.reportId.proximoDistalSequence.col0;
  const proximoDistalSequence_Right = data.reportId.proximoDistalSequence.col1;
  const highlights_Left = { x: data.reportId.pointsOfInterests.col0[4], x2: 100 };
  const highlights_Right = {
    x: data.reportId.pointsOfInterests.col1[4],
    x2: 100,
  };
  const proximoDistalSequenceAnnotations_Left = createEuclideanGraphAnnotations(
    proximoDistalSequence_Left,
    [colorLeft, colorRight, colorExtra],
    highlights_Left,
  );
  const proximoDistalSequenceAnnotations_Right = createEuclideanGraphAnnotations(
    proximoDistalSequence_Right,
    [colorLeft, colorRight, colorExtra],
    highlights_Right,
  );

  const proximoDistalSequenceAnnotations = {
    Left: proximoDistalSequenceAnnotations_Left,
    Right: proximoDistalSequenceAnnotations_Right,
  };

  const euclideanNormData = createEuclideanGraphData(data);
  //#endregion

  //#region Knee Positions
  const leftKneeXPositions = data.kneePositions.kneePositionsX_Left;
  const rightKneeXPositions = data.kneePositions.kneePositionsX_Right;
  const leftKneeYPositions = data.kneePositions.kneePositionsY_Left;
  const rightKneeYPositions = data.kneePositions.kneePositionsY_Right;
  const leftKneeXPosition = data.kneePositions.kneePosition_Left.col0;
  const leftKneeYPosition = data.kneePositions.kneePosition_Left.col1;
  const rightKneeXPosition = data.kneePositions.kneePosition_Right.col0;
  const rightKneeYPosition = data.kneePositions.kneePosition_Right.col1;

  const seriesKneePositionsData_Left = toJointCouplingData(leftKneeYPositions, leftKneeXPositions);
  const pointsOfInterestKneePositions_Left: JointCouplingSeriesData[] = [
    {
      type: 'scatter',
      name: 'Left',
      data: [
        {
          x: data.kneePositions.maxKneePositions.col0[1],
          y: data.kneePositions.maxKneePositions.col0[0],
        },
      ],
    },
  ];

  const seriesKneePositionsData_Right = toJointCouplingData(rightKneeYPositions, rightKneeXPositions);
  const pointsOfInterestKneePositions_Right: JointCouplingSeriesData[] = [
    {
      type: 'scatter',
      name: 'Right',
      data: [
        {
          x: data.kneePositions.maxKneePositions.col1[1],
          y: data.kneePositions.maxKneePositions.col1[0],
        },
      ],
    },
  ];

  const seriesKneePositionData_Left = toJointCouplingDataSingleColumn(leftKneeYPosition, leftKneeXPosition, colorLeft);
  const seriesKneePositionData_Right = toJointCouplingDataSingleColumn(
    rightKneeYPosition,
    rightKneeXPosition,
    colorRight,
  );

  const kneePositionsLeft: JointCouplingSeriesData[] = [
    ...seriesKneePositionsData_Left,
    ...pointsOfInterestKneePositions_Left,
  ];
  const kneePositionsRight: JointCouplingSeriesData[] = [
    ...seriesKneePositionsData_Right,
    ...pointsOfInterestKneePositions_Right,
  ];
  const kneePositionLeft: JointCouplingSeriesData[] = [
    ...seriesKneePositionData_Left,
    ...pointsOfInterestKneePositions_Left,
  ];
  const kneePositionRight: JointCouplingSeriesData[] = [
    ...seriesKneePositionData_Right,
    ...pointsOfInterestKneePositions_Right,
  ];

  const maxKneePositions: number[] = data.kneePositions.maxKneePositions;
  const kneePositionsData: KneePositionData = {
    kneePositionsLeft,
    kneePositionsRight,
    kneePositionLeft,
    kneePositionRight,
    maxKneePositions,
  };
  //#endregion

  //#region thighCoordination
  const highlightsThighCoordination_Left = {
    x: data.reportId.pointsOfInterests.col0[5],
    x2: data.reportId.pointsOfInterests.col0[3],
  };
  const highlightsThighCoordination_Right = {
    x: data.reportId.pointsOfInterests.col1[5],
    x2: data.reportId.pointsOfInterests.col1[3],
  };
  const annotationsThighCoordination_Left = createVariabilitySingleSideGraphAnnotations(
    annotations_Left,
    highlightsThighCoordination_Left,
  );
  const annotationsThighCoordination_Right = createVariabilitySingleSideGraphAnnotations(
    annotations_Right,
    highlightsThighCoordination_Right,
  );

  const {
    crpSeriesDataThigh: crpSeriesDataThigh_Left,
    crpSeriesDataThighC: crpSeriesDataThighC_Left,
    crpSeriesDataThighT: crpSeriesDataThighT_Left,
    crpSeriesDataThighST: crpSeriesDataThighST_Left,
    coordinativeVariabilitySeriesThigh: coordinativeVariabilitySeriesThigh_Left,
    coordinativeVariabilitySeriesThighC: coordinativeVariabilitySeriesThighC_Left,
    coordinativeVariabilitySeriesThighT: coordinativeVariabilitySeriesThighT_Left,
    coordinativeVariabilitySeriesThighST: coordinativeVariabilitySeriesThighST_Left,
  } = getThighCoordinationData(data, 'Left', 0);

  const {
    crpSeriesDataThigh: crpSeriesDataThigh_Right,
    crpSeriesDataThighC: crpSeriesDataThighC_Right,
    crpSeriesDataThighT: crpSeriesDataThighT_Right,
    crpSeriesDataThighST: crpSeriesDataThighST_Right,
    coordinativeVariabilitySeriesThigh: coordinativeVariabilitySeriesThigh_Right,
    coordinativeVariabilitySeriesThighC: coordinativeVariabilitySeriesThighC_Right,
    coordinativeVariabilitySeriesThighT: coordinativeVariabilitySeriesThighT_Right,
    coordinativeVariabilitySeriesThighST: coordinativeVariabilitySeriesThighST_Right,
  } = getThighCoordinationData(data, 'Right', 1);

  const crpData_Left = [
    ...crpSeriesDataThighT_Left,
    ...crpSeriesDataThighST_Left,
    ...crpSeriesDataThighC_Left,
    ...crpSeriesDataThigh_Left,
  ];

  const coordinativeVariability_Left = [
    ...coordinativeVariabilitySeriesThighT_Left,
    ...coordinativeVariabilitySeriesThighST_Left,
    ...coordinativeVariabilitySeriesThighC_Left,
    ...coordinativeVariabilitySeriesThigh_Left,
  ];

  const crpData_Right = [
    ...crpSeriesDataThighT_Right,
    ...crpSeriesDataThighST_Right,
    ...crpSeriesDataThighC_Right,
    ...crpSeriesDataThigh_Right,
  ];

  const coordinativeVariability_Right = [
    ...coordinativeVariabilitySeriesThighT_Right,
    ...coordinativeVariabilitySeriesThighST_Right,
    ...coordinativeVariabilitySeriesThighC_Right,
    ...coordinativeVariabilitySeriesThigh_Right,
  ];

  const thighCoordinationData: ThighCoordinationGraphData = {
    Left: {
      crpData: crpData_Left,
      coordinativeVariability: coordinativeVariability_Left,
      annotations: annotationsThighCoordination_Left,
    },
    Right: {
      crpData: crpData_Right,
      coordinativeVariability: coordinativeVariability_Right,
      annotations: annotationsThighCoordination_Right,
    },
  };

  const thighCoordinationLegend = createLegend(crpData_Left);

  //#endregion

  const runHamstringFunctionData: RunHamstringFunctionData = {
    pelvisAngularExcursionGraphAnnotations,
    pelvisKinematicData,
    euclideanNormData,
    proximoDistalSequenceAnnotations,
    kneePositionsData,
    annotations_Left,
    annotations_Right,
    thighCoordinationData,
    thighCoordinationLegend,
    scissorsTableData,
    HamstringData,
    scissorsBarGraphData,
  };

  return runHamstringFunctionData;
}

function toJointCouplingDataSingleColumn(
  yData: number[],
  xData: number[],
  color: string = colorLeft,
): { type: string; name: string; data: { x: number; y: number }[]; color: string }[] {
  const cycleData: { x: number; y: number }[] = [];

  yData.forEach((yValue, index) => {
    const xValue = xData[index];
    cycleData.push({ x: xValue, y: yValue });
  });

  return [
    {
      type: 'line',
      name: 'Mean',
      data: cycleData,
      color: color,
    },
  ];
}

function createAllStridesPelvisData(
  sagittalData: StridesData,
  coronalData: StridesData,
  side: string,
): GraphSeriesData[] {
  const color = side === 'Left' ? colorLeft : colorRight;
  const sagittal = toGraphData(sagittalData);
  const seriesSagittal = [{ type: 'line', name: 'Sagittal', data: flattenData(sagittal), color: color }];
  const coronal = toGraphData(coronalData);
  const seriesCoronal = [{ type: 'line', name: 'Frontal', data: flattenData(coronal), color: '#829696' }];
  const series = [...seriesSagittal, ...seriesCoronal];
  return series;
}

function createLegend(crpData_Left: GraphSeriesData[]): LegendEntry[] {
  // const crpLegendSS = {
  //   id: uuid(),
  //   name: 'Thigh (s) / Thigh (s)',
  //   color: colorSS,
  // };
  // const crpLegendCC = {
  //   id: uuid(),
  //   name: 'Thigh (c) / Thigh (c)',
  //   color: colorCC,
  // };
  // const crpLegendTT = {
  //   id: uuid(),
  //   name: 'Thigh (t) / Thigh (t)',
  //   color: colorTT,
  // };

  // const crpLegendST = {
  //   id: uuid(),
  //   name: 'Thigh (s) / Thigh (t)',
  //   color: colorST,
  // };
  const legends = [
    { id: uuid(), name: 'Thigh (s) / Thigh (s)', color: colorSS, key: 'SS' },
    { id: uuid(), name: 'Thigh (c) / Thigh (c)', color: colorCC, key: 'CC' },
    { id: uuid(), name: 'Thigh (t) / Thigh (t)', color: colorTT, key: 'TT' },
    { id: uuid(), name: 'Thigh (s) / Thigh (t)', color: colorST, key: 'ST' },
  ];

  // return [crpLegendSS, crpLegendCC, crpLegendTT, crpLegendST];
  return legends.filter((legend) => {
    const series = crpData_Left.find((item) => item.name.includes(legend.name));
    return series && series.data.length > 0;
  });
}
