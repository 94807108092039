import { IonCol, IonGrid, IonImg, IonRow, IonText } from '@ionic/react';

import { QuickLinkCard, QuickLinkCardProps } from '../QuickLinkCard';

import { mergeClassNames } from '../../utilities/mergeClassNames';

import quickLinkBg from '../../assets/bg-pattern-grid.png';

import styles from './index.module.css';
import { isProduction } from '../../utilities/environment';

export const QuickLinkGrid = () => {
  const content = [
    {
      id: '5dbd1879-cb83-439b-8e93-95d85bc2d5c5',
      icon: 'document',
      color: 'green',
      text: 'View reports',
      routerLink: '/reports',
      routerDirection: 'forward',
      showOn: 'production',
    },
    {
      id: '5ad49038-4eae-452b-b686-1997e983220c',
      icon: 'upload',
      color: 'yellow',
      text: 'Upload new file',
      routerLink: '/upload',
      routerDirection: 'forward',
      showOn: 'production',
    },
    {
      id: '6494d3eb-761b-49df-816e-4cbdac2b87e7',
      icon: 'chart',
      color: 'teal',
      text: 'Add new measurements',
      onClick: () => {
        console.log('add new measurements');
      },
      showOn: 'development',
    },
  ] satisfies Array<{ id: string; text: string } & QuickLinkCardProps>;

  const filteredItemsByEnvironment = content.filter((item) => {
    if (isProduction) {
      return item.showOn === 'production';
    } else return item;
  });

  return (
    <IonGrid className={styles.wrapper}>
      <IonRow>
        <IonCol size='12' className={mergeClassNames(styles.col, styles.header)}>
          <IonText className={styles.heading}>Quicklinks</IonText>
        </IonCol>
      </IonRow>

      <IonRow className={styles.row}>
        {filteredItemsByEnvironment.map(({ id, text, ...item }) => (
          <IonCol key={id} className={styles.col}>
            <QuickLinkCard {...item}>{text}</QuickLinkCard>
          </IonCol>
        ))}
      </IonRow>
      <IonImg src={quickLinkBg} className={styles.background} />
    </IonGrid>
  );
};
