import {
  SLSquatAnalysisData,
  varusValgusLabels_FunctionalMovement,
  SingleLegSquatReportData,
  QScore,
  singleLegSquatLabels,
  SingleLegSquatHipLabels,
  SingleLegSquatAnkleLabels,
  SingleLegSquatKinematicData,
  SingleLegSquatVariabilityData,
} from '../../model/reportV2.model';
import {
  transpose,
  getPelvisVariabilityData_FunctionalMovement,
  transposeToStatusDot,
  createLabel,
  getAllKinematicData,
  getKneeVariabilityData_FunctionalMovement,
  getAnkleVariabilityData_FunctionalMovement,
  createVariabilityLegend,
  calculateQScoreSLS,
} from './dataFunctions';

export const createSingleLegSquatReportData = (data: any): SingleLegSquatReportData => {
  //#region Analysis
  const romData: number[][] = transpose(data?.reportId.rom_Squats);
  const qualityData: number[][] = transposeToStatusDot(data?.reportId.keyPerfomanceIndicators_SLS);
  const repTime_Left: number = data?.reportId.repetitionTime_SLS.col0[0].toFixed(2);
  const stdRepTime_Left: number = data?.reportId.repetitionTime_SLS.col0[1].toFixed(2);
  const repTime_Right: number = data?.reportId.repetitionTime_SLS.col1[0].toFixed(2);
  const stdRepTime_Right: number = data?.reportId.repetitionTime_SLS.col1[1].toFixed(2);
  const qScore_Left: number = data?.reportId.repetitionTime_SLS.col0[4];
  const qScore_Right: number = data?.reportId.repetitionTime_SLS.col1[4];
  const qScoreLabel_Left: QScore = calculateQScoreSLS(data?.reportId.repetitionTime_SLS.col0[4]);
  const qScoreLabel_Right: QScore = calculateQScoreSLS(data?.reportId.repetitionTime_SLS.col1[4]);
  const varusValgusLabel_Left: string = createLabel(
    varusValgusLabels_FunctionalMovement[0],
    data?.reportId.keyPerfomanceIndicators_SLS.col0[6],
  );
  const varusValgusLabel_Right: string = createLabel(
    varusValgusLabels_FunctionalMovement[0],
    data?.reportId.keyPerfomanceIndicators_SLS.col1[6],
  );

  const externalRotationLabel_Left = createLabel(
    singleLegSquatLabels[2],
    data?.reportId.keyPerfomanceIndicators_SLS.col0[2],
  );
  const externalRotationLabel_Right = createLabel(
    singleLegSquatLabels[2],
    data?.reportId.keyPerfomanceIndicators_SLS.col1[2],
  );
  const internalRotationLabel_Left = createLabel(
    singleLegSquatLabels[3],
    data?.reportId.keyPerfomanceIndicators_SLS.col0[3],
  );
  const internalRotaionLabel_Right = createLabel(
    singleLegSquatLabels[3],
    data?.reportId.keyPerfomanceIndicators_SLS.col1[3],
  );

  const abductionLabel_Left = createLabel(singleLegSquatLabels[4], data?.reportId.keyPerfomanceIndicators_SLS.col0[4]);
  const abductionLabel_Right = createLabel(singleLegSquatLabels[4], data?.reportId.keyPerfomanceIndicators_SLS.col1[4]);
  const adductionLabel_Left = createLabel(singleLegSquatLabels[5], data?.reportId.keyPerfomanceIndicators_SLS.col0[5]);
  const adductionLabel_Right = createLabel(singleLegSquatLabels[5], data?.reportId.keyPerfomanceIndicators_SLS.col1[5]);

  const hipLabels: SingleLegSquatHipLabels = {
    externalRotationLabel_Left,
    externalRotationLabel_Right,
    internalRotationLabel_Left,
    internalRotaionLabel_Right,
    abductionLabel_Left,
    abductionLabel_Right,
    adductionLabel_Left,
    adductionLabel_Right,
  };

  const earlyHeelRaiseLabel_Left = createLabel(
    singleLegSquatLabels[0],
    data?.reportId.keyPerfomanceIndicators_SLS.col0[0],
  );
  const earlyHeelRaiseLabel_Right = createLabel(
    singleLegSquatLabels[0],
    data?.reportId.keyPerfomanceIndicators_SLS.col1[0],
  );
  const ankleLabels: SingleLegSquatAnkleLabels = {
    earlyHeelRaiseLabel_Left,
    earlyHeelRaiseLabel_Right,
  };

  const squatAnalysisData: SLSquatAnalysisData = {
    romData,
    qualityData,
    repTime_Left,
    stdRepTime_Left,
    repTime_Right,
    stdRepTime_Right,
    qScore_Left,
    qScore_Right,
    qScoreLabel_Left,
    qScoreLabel_Right,
    hipLabels,
    ankleLabels,
    varusValgusLabel_Left,
    varusValgusLabel_Right,
  };
  //#endregion

  //#region Kinematics
  const pelvisKinematicData = getAllKinematicData(data, 'Pelvis');
  const hipKinematicData = getAllKinematicData(data, 'Hip');
  const kneeKinematicData = getAllKinematicData(data, 'Knee');
  const ankleKinematicData = getAllKinematicData(data, 'Ankle');

  const squatKinematicData: SingleLegSquatKinematicData = {
    romData,
    hipLabels,
    ankleLabels,
    varusValgusLabel_Left,
    varusValgusLabel_Right,
    pelvisKinematicData,
    hipKinematicData,
    kneeKinematicData,
    ankleKinematicData,
    qualityData,
  };
  //#endregion

  //#region Variability
  const {
    crpSeriesDataPelvisThighT: crpSeriesDataPelvisThighT_Left,
    crpSeriesDataPelvisCThighT: crpSeriesDataPelvisCThighT_Left,
    crpSeriesDataPelvisCThigh: crpSeriesDataPelvisCThigh_Left,
    crpSeriesDataPelvisThigh: crpSeriesDataPelvisThigh_Left,
    coordinativeVariabilitySeriesAllHip: coordinativeVariabilitySeriesAllHip_Left,
    coordinativeVariabilitySeriesPelvisThigh: coordinativeVariabilitySeriesPelvisThigh_Left,
    coordinativeVariabilitySeriesPelvisThighT: coordinativeVariabilitySeriesPelvisThighT_Left,
    coordinativeVariabilitySeriesPelvisCThighT: coordinativeVariabilitySeriesPelvisCThighT_Left,
    coordinativeVariabilitySeriesPelvisCThigh: coordinativeVariabilitySeriesPelvisCThigh_Left,
  } = getPelvisVariabilityData_FunctionalMovement(data, 'Left');
  const {
    crpSeriesDataPelvisThighT: crpSeriesDataPelvisThighT_Right,
    crpSeriesDataPelvisCThighT: crpSeriesDataPelvisCThighT_Right,
    crpSeriesDataPelvisCThigh: crpSeriesDataPelvisCThigh_Right,
    crpSeriesDataPelvisThigh: crpSeriesDataPelvisThigh_Right,
    coordinativeVariabilitySeriesAllHip: coordinativeVariabilitySeriesAllHip_Right,
    coordinativeVariabilitySeriesPelvisThigh: coordinativeVariabilitySeriesPelvisThigh_Right,
    coordinativeVariabilitySeriesPelvisThighT: coordinativeVariabilitySeriesPelvisThighT_Right,
    coordinativeVariabilitySeriesPelvisCThighT: coordinativeVariabilitySeriesPelvisCThighT_Right,
    coordinativeVariabilitySeriesPelvisCThigh: coordinativeVariabilitySeriesPelvisCThigh_Right,
  } = getPelvisVariabilityData_FunctionalMovement(data, 'Right');
  const {
    crpSeriesDataThighCalfT: crpSeriesDataThighCalfT_Left,
    crpSeriesDataThighCCalfT: crpSeriesDataThighCCalfT_Left,
    crpSeriesDataThighCalf: crpSeriesDataThighCalf_Left,
    coordinativeVariabilitySeriesAllKnee: coordinativeVariabilitySeriesAllKnee_Left,
    coordinativeVariabilitySeriesThighCalf: coordinativeVariabilitySeriesThighCalf_Left,
    coordinativeVariabilitySeriesThighCalfT: coordinativeVariabilitySeriesThighCalfT_Left,
    coordinativeVariabilitySeriesThighCCalfT: coordinativeVariabilitySeriesThighCCalfT_Left,
  } = getKneeVariabilityData_FunctionalMovement(data, 'Left');
  const {
    crpSeriesDataThighCalfT: crpSeriesDataThighCalfT_Right,
    crpSeriesDataThighCCalfT: crpSeriesDataThighCCalfT_Right,
    crpSeriesDataThighCalf: crpSeriesDataThighCalf_Right,
    coordinativeVariabilitySeriesAllKnee: coordinativeVariabilitySeriesAllKnee_Right,
    coordinativeVariabilitySeriesThighCalf: coordinativeVariabilitySeriesThighCalf_Right,
    coordinativeVariabilitySeriesThighCalfT: coordinativeVariabilitySeriesThighCalfT_Right,
    coordinativeVariabilitySeriesThighCCalfT: coordinativeVariabilitySeriesThighCCalfT_Right,
  } = getKneeVariabilityData_FunctionalMovement(data, 'Right');
  const {
    crpSeriesDataCalfTFootC: crpSeriesDataCalfTFootC_Left,
    crpSeriesDataCalfFoot: crpSeriesDataCalfFoot_Left,
    coordinativeVariabilitySeriesAllAnkle: coordinativeVariabilitySeriesAllAnkle_Left,
    coordinativeVariabilitySeriesCalfFoot: coordinativeVariabilitySeriesCalfFoot_Left,
    coordinativeVariabilitySeriesCalfTFootC: coordinativeVariabilitySeriesCalfTFootC_Left,
  } = getAnkleVariabilityData_FunctionalMovement(data, 'Left');
  const {
    crpSeriesDataCalfTFootC: crpSeriesDataCalfTFootC_Right,
    crpSeriesDataCalfFoot: crpSeriesDataCalfFoot_Right,
    coordinativeVariabilitySeriesAllAnkle: coordinativeVariabilitySeriesAllAnkle_Right,
    coordinativeVariabilitySeriesCalfFoot: coordinativeVariabilitySeriesCalfFoot_Right,
    coordinativeVariabilitySeriesCalfTFootC: coordinativeVariabilitySeriesCalfTFootC_Right,
  } = getAnkleVariabilityData_FunctionalMovement(data, 'Right');

  const crpSeriesPelvisThigh_Left = [
    ...crpSeriesDataPelvisThighT_Left,
    ...crpSeriesDataPelvisCThighT_Left,
    ...crpSeriesDataPelvisCThigh_Left,
    ...crpSeriesDataPelvisThigh_Left,
  ];

  const coordinativeVariabilityOverviewHip_Left = [...coordinativeVariabilitySeriesAllHip_Left];
  const coordinativeVariabilitySeriesHip_Left = [
    ...coordinativeVariabilitySeriesAllHip_Left,
    ...coordinativeVariabilitySeriesPelvisThigh_Left,
    ...coordinativeVariabilitySeriesPelvisThighT_Left,
    ...coordinativeVariabilitySeriesPelvisCThighT_Left,
    ...coordinativeVariabilitySeriesPelvisCThigh_Left,
  ];

  const crpSeriesPelvisThigh_Right = [
    ...crpSeriesDataPelvisThighT_Right,
    ...crpSeriesDataPelvisCThighT_Right,
    ...crpSeriesDataPelvisCThigh_Right,
    ...crpSeriesDataPelvisThigh_Right,
  ];

  const coordinativeVariabilityOverviewHip_Right = [...coordinativeVariabilitySeriesAllHip_Right];
  const coordinativeVariabilitySeriesHip_Right = [
    ...coordinativeVariabilitySeriesAllHip_Right,
    ...coordinativeVariabilitySeriesPelvisThigh_Right,
    ...coordinativeVariabilitySeriesPelvisThighT_Right,
    ...coordinativeVariabilitySeriesPelvisCThighT_Right,
    ...coordinativeVariabilitySeriesPelvisCThigh_Right,
  ];

  const crpSeriesThighCalf_Left = [
    ...crpSeriesDataThighCalfT_Left,
    ...crpSeriesDataThighCCalfT_Left,
    ...crpSeriesDataThighCalf_Left,
  ];

  const coordinativeVariabilityOverviewKnee_Left = [...coordinativeVariabilitySeriesAllKnee_Left];

  const coordinativeVariabilitySeriesKnee_Left = [
    ...coordinativeVariabilitySeriesAllKnee_Left,
    ...coordinativeVariabilitySeriesThighCalf_Left,
    ...coordinativeVariabilitySeriesThighCalfT_Left,
    ...coordinativeVariabilitySeriesThighCCalfT_Left,
  ];

  const crpSeriesThighCalf_Right = [
    ...crpSeriesDataThighCalfT_Right,
    ...crpSeriesDataThighCCalfT_Right,
    ...crpSeriesDataThighCalf_Right,
  ];

  const coordinativeVariabilityOverviewKnee_Right = [...coordinativeVariabilitySeriesAllKnee_Right];

  const coordinativeVariabilitySeriesKnee_Right = [
    ...coordinativeVariabilitySeriesAllKnee_Right,
    ...coordinativeVariabilitySeriesThighCalf_Right,
    ...coordinativeVariabilitySeriesThighCalfT_Right,
    ...coordinativeVariabilitySeriesThighCCalfT_Right,
  ];

  const crpSeriesCalfFoot_Left = [...crpSeriesDataCalfTFootC_Left, ...crpSeriesDataCalfFoot_Left];

  const coordinativeVariabilityOverviewAnkle_Left = [...coordinativeVariabilitySeriesAllAnkle_Left];

  const coordinativeVariabilitySeriesAnkle_Left = [
    ...coordinativeVariabilitySeriesAllAnkle_Left,
    ...coordinativeVariabilitySeriesCalfFoot_Left,
    ...coordinativeVariabilitySeriesCalfTFootC_Left,
  ];

  const crpSeriesCalfFoot_Right = [...crpSeriesDataCalfTFootC_Right, ...crpSeriesDataCalfFoot_Right];

  const coordinativeVariabilityOverviewAnkle_Right = [...coordinativeVariabilitySeriesAllAnkle_Right];

  const coordinativeVariabilitySeriesAnkle_Right = [
    ...coordinativeVariabilitySeriesAllAnkle_Right,
    ...coordinativeVariabilitySeriesCalfFoot_Right,
    ...coordinativeVariabilitySeriesCalfTFootC_Right,
  ];

  const pelvisLegend = createVariabilityLegend(
    crpSeriesPelvisThigh_Left,
    coordinativeVariabilityOverviewHip_Left,
    coordinativeVariabilityOverviewHip_Right,
  );

  const kneeLegend = createVariabilityLegend(
    crpSeriesThighCalf_Left,
    coordinativeVariabilityOverviewKnee_Left,
    coordinativeVariabilityOverviewKnee_Right,
  );

  const ankleLegend = createVariabilityLegend(
    crpSeriesCalfFoot_Left,
    coordinativeVariabilityOverviewAnkle_Left,
    coordinativeVariabilityOverviewAnkle_Right,
  );

  const squatVariabilityData: SingleLegSquatVariabilityData = {
    crpSeriesPelvisThigh_Left,
    coordinativeVariabilityOverviewHip_Left,
    coordinativeVariabilitySeriesHip_Left,
    crpSeriesPelvisThigh_Right,
    coordinativeVariabilityOverviewHip_Right,
    coordinativeVariabilitySeriesHip_Right,
    crpSeriesThighCalf_Left,
    coordinativeVariabilityOverviewKnee_Left,
    coordinativeVariabilitySeriesKnee_Left,
    crpSeriesThighCalf_Right,
    coordinativeVariabilityOverviewKnee_Right,
    coordinativeVariabilitySeriesKnee_Right,
    crpSeriesCalfFoot_Left,
    coordinativeVariabilityOverviewAnkle_Left,
    coordinativeVariabilitySeriesAnkle_Left,
    crpSeriesCalfFoot_Right,
    coordinativeVariabilityOverviewAnkle_Right,
    coordinativeVariabilitySeriesAnkle_Right,
    pelvisLegend,
    kneeLegend,
    ankleLegend,
  };
  //#endregion

  // Overview Data
  const fileInfo = data.fileId;
  const reportType = data.reportId.reportType;

  const singleLegSquatReportData: SingleLegSquatReportData = {
    squatAnalysisData,
    squatKinematicData,
    squatVariabilityData,
    fileInfo,
    reportType,
  };

  return singleLegSquatReportData;
};
