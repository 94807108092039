import 'firebase/compat/firestore';
import { setCache, getCache } from '../../utilities/CacheController';
import { useMemo, useState } from 'react';
import {
  FirebaseTimestamp,
  Measurement,
  MeasurementsCollection,
  ORYXGOCollection,
  ORYXGOInstance,
  ORYXGOType,
  ORYXKSCollection,
  ORYXKSInstance,
  ORYXKSType,
  UserId,
  UserInfo,
} from '../../model';
import { myFirebase } from '../../config/firebaseConfig';
import { CACHE_KEYS } from '../../utilities/cacheKeys';

const db = myFirebase.firestore();
const storage = myFirebase.storage();

type UserStruct = {
  organization_id: string;
  role: string;
  firstName: string;
  lastName: string;
  dateCreated: FirebaseTimestamp;
  status: string;
};

type UseHardwareHook = {
  ORYXGOCollection: ORYXGOCollection;
  ORYXKSCollection: ORYXKSCollection;
  measurementsCollection: MeasurementsCollection;
  isLoading: boolean;
  isError: Error | undefined;
  errorMessage: string;
  clearError: () => void;
  getAvailableORYXGOSystems: (userId: UserId) => Promise<void>;
  getAvailableORYXKSSystems: (userId: UserId) => Promise<void>;
  getAvailableMeasurements: (userId: UserId) => Promise<void>;
};

export const useHardware = (userId: UserId): UseHardwareHook => {
  const [isError, setIsError] = useState<Error | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ORYXGOCollection, setORYXGOCollection] = useState<ORYXGOCollection>({});
  const [ORYXKSCollection, setORYXKSCollection] = useState<ORYXKSCollection>({});
  const [measurementsCollection, setMeasurementsCollection] = useState<MeasurementsCollection>({});

  // Called to close the error Toast
  const clearError = () => {
    setIsError(undefined);
  };

  async function getAvailableMeasurements(userId: UserId) {
    const userCache = (await getCache('userinfo_cache')) as UserInfo;
    const hasORYXGO = userCache.organization.hasORYXGO;
    const hasORYXKS = userCache.organization.hasORYXKS;
    const cachedValue = await getCache<MeasurementsCollection>(CACHE_KEYS.MEASUREMENTSCOLLECTION_CACHE_KEY);

    if (
      !cachedValue ||
      (typeof cachedValue === 'object' && Object.keys(cachedValue).length === 0) ||
      (Array.isArray(cachedValue) && cachedValue.length === 0)
    ) {
      const measurementsRef = db.collection('measurements');

      // Get the measurements collection
      const fetchMeasurementsList = async () => {
        try {
          const querySnapshot = await measurementsRef.get();
          // const ORYXGOList = querySnapshot.docs.map((doc) => doc.data());
          // Assuming your HardwareInstance data has an 'id' field, you can add it to each object like this:
          const measurementsList = querySnapshot.docs.map((doc) => ({ id: doc.id }));
          return measurementsList;
        } catch (error) {
          setIsError(new Error('Error getting measurementsList'));
          return [];
        }
      };

      const MeasurementsList = await fetchMeasurementsList();

      // Create a dict of measurements
      const createMeasurementsDict = async () => {
        if (MeasurementsList == null) {
          setIsError(new Error('measurementsDocs is null'));
          return {};
        }
        const dict: any = {};
        for (const measurement of MeasurementsList) {
          dict[measurement.id] = measurement;
        }
        return dict;
      };

      const MeasurementsDict = await createMeasurementsDict();

      // Get the data for each measurement
      const createMeasurementDocsList = async () => {
        if (MeasurementsDict == null) {
          setIsError(new Error('MeasurementsDict is null'));
          return {};
        }
        const MeasurementDocsList: MeasurementsCollection = {};
        for (const measurementId in MeasurementsDict) {
          const measurementRef = measurementsRef.doc(measurementId);
          const measurementDoc = await measurementRef
            .get()
            .then(async (doc) => {
              if (doc.exists) {
                const measurementData = doc.data() as Measurement;
                const results = await getAvailableTests(measurementData, userId)
                  .then((data) => {
                    return data;
                  })
                  .catch((error) => {
                    if (import.meta.env.MODE) console.log('Error getting Measurement data: ', error);
                    setIsError(error);
                    setErrorMessage(error.message);
                  });

                MeasurementDocsList[results.title] = results;
              }
            })
            .catch((error) => {
              setIsError(error);
              setErrorMessage(error.message);
            });
        }
        return MeasurementDocsList;
      };

      const measurementDocs = await createMeasurementDocsList();

      // Only set the measurements collection if the user has ORYXGO or ORYXKS
      if (hasORYXGO && hasORYXKS) {
        setMeasurementsCollection(measurementDocs);
        setCache<MeasurementsCollection>(measurementDocs, CACHE_KEYS.MEASUREMENTSCOLLECTION_CACHE_KEY);
      } else if (hasORYXGO && !hasORYXKS) {
        // only allow the measurement with measurement.title === "ORYX Movement Analysis" to be displayed
        const filteredMeasurements = Object.keys(measurementDocs)
          .filter((key) => key === 'ORYX Movement Analysis')
          .reduce((obj: any, key) => {
            obj[key] = measurementDocs[key];
            return obj;
          }, {});
        setMeasurementsCollection(filteredMeasurements);
        setCache<MeasurementsCollection>(filteredMeasurements, CACHE_KEYS.MEASUREMENTSCOLLECTION_CACHE_KEY);
      } else if (!hasORYXGO && hasORYXKS) {
        // only allow the measurement with measurement.title === "ORYX Knee Stability" to be displayed
        const filteredMeasurements = Object.keys(measurementDocs)
          .filter((key) => key === 'ORYX Knee Stability')
          .reduce((obj: any, key) => {
            obj[key] = measurementDocs[key];
            return obj;
          }, {});
        setMeasurementsCollection(filteredMeasurements);
        setCache<MeasurementsCollection>(filteredMeasurements, CACHE_KEYS.MEASUREMENTSCOLLECTION_CACHE_KEY);
      }
      setIsLoading(false);
    } else {
      setMeasurementsCollection(cachedValue);
      setIsLoading(false);
    }
  }

  async function getAvailableORYXGOSystems(userId: UserId) {
    const userDocRef = db.collection('users').doc(userId);
    // const [userDoc, userDocLoading, userDocError] = useDocumentData<UserStruct>(userDocRef);
    //Attempt to load cache first, and proceed to pulling from firebase if not present
    const cachedValue = await getCache<ORYXGOCollection>(CACHE_KEYS.ORYXGO_CACHE_KEY);

    if (
      !cachedValue ||
      (typeof cachedValue === 'object' && Object.keys(cachedValue).length === 0) ||
      (Array.isArray(cachedValue) && cachedValue.length === 0)
    ) {
      if (import.meta.env.MODE) console.log('cachedValue is null');
      const userDoc = await userDocRef.get().then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          setIsError(new Error('No User Document Found'));
          return null;
        }
      });

      // Get the organization ID from the user document
      const organizationId = userDoc?.organization_id;

      // Get the ORYXGO collection from the organization ID
      const OrganizationORYXGOCollectionRef = db.collection('organizations').doc(organizationId).collection('ORYXGO');

      // Get a list of ORYXGOs from the ORYXGO collection
      const fetchORYXGOList = async () => {
        try {
          const querySnapshot = await OrganizationORYXGOCollectionRef.get();
          // const ORYXGOList = querySnapshot.docs.map((doc) => doc.data());
          // Assuming your HardwareInstance data has an 'id' field, you can add it to each object like this:
          const ORYXGOList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          return ORYXGOList;
        } catch (error) {
          setIsError(new Error('Error getting ORYXGO list'));
          return [];
        }
      };

      const ORYXGOList = await fetchORYXGOList();

      // transform the ORYXGO list into a dictionary
      const createORYXGODict = async () => {
        if (ORYXGOList == null) {
          setIsError(new Error('ORYXGOList is null'));
          return {};
        }
        const dict: any = {};
        for (const ORYXGO of ORYXGOList) {
          dict[ORYXGO.id] = ORYXGO;
        }
        return dict;
      };

      const ORYXGODict = await createORYXGODict();

      // For eacht ORYXGO in the ORYXGO dict, get the ORYXGO document from the ORYXGO collection and also retrieve the rest of the information
      const ORYXGOCollectionRef = db.collection('oryxGOSystems');

      // for each ID in the ORYXGODict get the ORYX GO document from the ORYX GO collection
      const createORYXGODocsList = async () => {
        if (ORYXGODict == null) {
          setIsError(new Error('ORYXGODict is null'));
          return {};
        }
        const ORYXGODocsList: ORYXGOCollection = {};

        for (const ORYXGOId in ORYXGODict) {
          const ORYXGODocRef = ORYXGOCollectionRef.doc(ORYXGOId);
          const ORYXGODoc = ORYXGODocRef.get()
            .then(async (doc) => {
              if (doc.exists) {
                const ORYXGODocData = doc.data() as ORYXGOInstance;
                ORYXGODocData!.dateAssigned = ORYXGODict[ORYXGOId].dateAssigned;
                ORYXGODocData!.alias = ORYXGODict[ORYXGOId].alias;
                const results = await getORYXGOData(ORYXGODocData)
                  .then((data) => {
                    return data;
                  })
                  .catch((error) => {
                    if (import.meta.env.MODE) console.log('Error getting ORYXGO data: ', error);
                    setIsError(error);
                    setErrorMessage(error.message);
                  });

                ORYXGODocsList[ORYXGOId] = results;
              }
            })
            .catch((error) => {
              setIsError(error);
              setErrorMessage(error.message);
            });
        }
        return ORYXGODocsList;
      };

      const ORYXGODocsList = await createORYXGODocsList();

      setORYXGOCollection(ORYXGODocsList);

      setTimeout(() => {
        // For each ORYXKS Instance in the ORYXKSDocsList create a instance in the cache with the Cache Key of the ORYXKS ID
        for (const id in ORYXGODict) {
          // Get the instance of the ORYXKSDocsList with the same id.
          const ORYXKSInstance = ORYXGODocsList[id];
          setCache<ORYXGOType>(ORYXKSInstance, id);
        }

        // await setCache<ORYXKSCollection>(ORYXKSDocsList, CACHE_KEYS.ORYXKS_CACHE_KEY);
        setIsLoading(false);
      }, 2000);
    } else {
      setORYXGOCollection(cachedValue);
      setIsLoading(false);
    }
  }

  async function getAvailableORYXKSSystems(userId: UserId) {
    const userDocRef = db.collection('users').doc(userId);
    // const [userDoc, userDocLoading, userDocError] = useDocumentData<UserStruct>(userDocRef);
    const cachedValue = await getCache<ORYXKSCollection>(CACHE_KEYS.ORYXKS_CACHE_KEY);

    if (
      !cachedValue ||
      (typeof cachedValue === 'object' && Object.keys(cachedValue).length === 0) ||
      (Array.isArray(cachedValue) && cachedValue.length === 0)
    ) {
      if (import.meta.env.MODE) console.log('KScachedValue is null');

      const userDoc = await userDocRef.get().then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          setIsError(new Error('No User Document Found'));
          return null;
        }
      });

      // Get the organization ID from the user document
      const organizationId = userDoc?.organization_id;

      // Get the ORYXGO collection from the organization ID
      const OrganizationORYXKSCollectionRef = db.collection('organizations').doc(organizationId).collection('ORYXKS');

      // Get a list of ORYXGOs from the ORYXGO collection

      const fetchORYXKSList = async () => {
        try {
          const querySnapshot = await OrganizationORYXKSCollectionRef.get();
          // const ORYXGOList = querySnapshot.docs.map((doc) => doc.data());
          // Assuming your HardwareInstance data has an 'id' field, you can add it to each object like this:
          const ORYXKSList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          return ORYXKSList;
        } catch (error) {
          setIsError(new Error('Error getting ORYXKS list'));
          return [];
        }
      };

      const ORYXKSList = await fetchORYXKSList();

      // transform the ORYXGO list into a dictionary
      const createORYXKSDict = async () => {
        if (ORYXKSList == null) {
          setIsError(new Error('ORYXKSList is null'));
          return {};
        }
        const dict: any = {};
        for (const ORYXKS of ORYXKSList) {
          dict[ORYXKS.id] = ORYXKS;
        }
        return dict;
      };

      const ORYXKSDict = await createORYXKSDict();

      // For eacht ORYXKS in the ORYXKS dict, get the ORYXKS document from the ORYXKS collection and also retrieve the rest of the information
      const ORYXKSCollectionRef = db.collection('oryxKSSystems');

      // for each ID in the ORYXGODict get the ORYX GO document from the ORYX GO collection
      const createORYXKSDocsList = async () => {
        if (ORYXKSDict == null) {
          setIsError(new Error('ORYXKSDict is null'));
          return {};
        }
        const ORYXKSDocsList: ORYXKSCollection = {};
        for (const ORYXKSId in ORYXKSDict) {
          const ORYXKSDocRef = ORYXKSCollectionRef.doc(ORYXKSId);
          const ORYXKSDoc = ORYXKSDocRef.get()
            .then(async (doc) => {
              if (doc.exists) {
                const ORYXKSDocData = doc.data() as ORYXKSInstance;
                ORYXKSDocData!.dateAssigned = ORYXKSDict[ORYXKSId].dateAssigned;
                ORYXKSDocData!.alias = ORYXKSDict[ORYXKSId].alias;
                const results = await getORYXGOData(ORYXKSDocData)
                  .then((data) => {
                    return data;
                  })
                  .catch((error) => {
                    if (import.meta.env.MODE) console.log('Error getting ORYXKS data: ', error);
                    setIsError(error);
                    setErrorMessage(error.message);
                  });

                ORYXKSDocsList[ORYXKSId] = results;
              }
            })
            .catch((error) => {
              setIsError(error);
              setErrorMessage(error.message);
            });
        }
        return ORYXKSDocsList;
      };

      const ORYXKSDocsList = await createORYXKSDocsList();

      setORYXKSCollection(ORYXKSDocsList);

      setTimeout(() => {
        // For each ORYXKS Instance in the ORYXKSDocsList create a instance in the cache with the Cache Key of the ORYXKS ID
        for (const id in ORYXKSDict) {
          // Get the instance of the ORYXKSDocsList with the same id.
          const ORYXKSInstance = ORYXKSDocsList[id];
          if (import.meta.env.MODE) console.log('ORYXKSInstance: ', ORYXKSInstance);
          setCache<ORYXKSType>(ORYXKSInstance, id);
        }

        // await setCache<ORYXKSCollection>(ORYXKSDocsList, CACHE_KEYS.ORYXKS_CACHE_KEY);
        setIsLoading(false);
      }, 2000);
    } else {
      setORYXKSCollection(cachedValue);
      setIsLoading(false);
    }
  }

  const hook: UseHardwareHook = {
    ORYXGOCollection: ORYXGOCollection,
    ORYXKSCollection: ORYXKSCollection,
    measurementsCollection: measurementsCollection,
    isLoading: isLoading,
    isError: isError,
    errorMessage: errorMessage,
    clearError: clearError,
    getAvailableORYXGOSystems: getAvailableORYXGOSystems,
    getAvailableORYXKSSystems: getAvailableORYXKSSystems,
    getAvailableMeasurements: getAvailableMeasurements,
  };
  return useMemo(
    () => hook,
    [
      ORYXGOCollection,
      ORYXKSCollection,
      measurementsCollection,
      isLoading,
      isError,
      errorMessage,
      clearError,
      getAvailableORYXGOSystems,
      getAvailableORYXKSSystems,
      getAvailableMeasurements,
    ],
  );
};

async function getORYXGOData(docData: ORYXGOInstance) {
  // extract the base information from the docData (dateCreated, lastUpdated, status)
  const baseData = {
    dateCreated: docData!.dateCreated,
    lastUpdated: docData!.lastUpdated,
    status: docData!.status,
    assignments: docData!.assignments,
    alias: docData!.alias,
    dateAssigned: docData!.dateAssigned,
  };

  const promises = [];
  const sensors: any = {};
  const collectionMapping: any = {
    gateway: 'Gateways',
    iPad: 'iPads',
    sensors: 'sensors',
    dataConnection: 'dataConnections',
    measurements: 'measurements',
  };
  for (const collection in collectionMapping) {
    const collectionName = collectionMapping[collection];

    if (collection === 'gateway') {
      const gatewayRef = db.collection(collectionName).doc(docData!.gateway);
      const gatewayDoc = await gatewayRef.get();
      promises.push({ gateway: gatewayDoc.data() });
    } else if (collection === 'iPad') {
      const iPadRef = db.collection(collectionName).doc(docData!.iPad);
      const iPadDoc = await iPadRef.get();
      promises.push({ iPad: iPadDoc.data() });
    } else if (collection === 'sensors') {
      // docData.sensors has the following structure:
      // sensors: [
      //   'sensor serialNumber 1',
      //   'sensor serialNumber 2',
      //   'sensor serialNumber 3',
      //     ]
      //   For each sensor serial number, get the sensor document from the sensors collection
      for (const sensor of docData!.sensors) {
        const sensorRef = db.collection(collectionName).doc(sensor);
        const sensorDoc = await sensorRef.get();

        // create a dictionary of sensors
        sensors[sensor] = sensorDoc.data();
      }
      promises.push({ sensors: sensors });
    } else if (collection === 'dataConnection') {
      const dataConnectionRef = db.collection(collectionName).doc(docData!.dataConnection);
      const dataConnectionDoc = await dataConnectionRef.get();
      promises.push({ dataConnection: dataConnectionDoc.data() });
    } else if (collection === 'measurements') {
      const measurementsRef = db.collection(collectionName).doc(docData!.measurements);
      const measurementsDoc = await measurementsRef.get();
      promises.push({ measurements: measurementsDoc.data() });
    }
  }
  // Push the base data into the promises array
  promises.push(baseData);

  // Wait for all promises to resolve
  // const results = await Promise.all(promises);
  const results = Object.assign({}, ...promises);
  return results;
}

async function getAvailableTests(docData: Measurement, userId: UserId) {
  const measurementId = docData.id;
  const baseData = {
    status: docData!.status,
    availableOn: docData!.availableOn,
    numberOfSensors: docData!.numberOfSensors,
    id: docData!.id,
    title: docData!.title,
    url: docData!.url,
  };

  const promises = [];
  const tests: any = {};

  for (const index in docData.Tests) {
    const testId = docData.Tests[index] as unknown as string;
    const testRef = db.collection('tests').doc(testId);
    const testDoc = await testRef.get();
    // Get the test document from the testDict
    tests[testId] = testDoc.data();
  }

  promises.push({ Tests: tests });
  promises.push(baseData);

  const results = Object.assign({}, ...promises);
  return results;
}
