import { IconProps } from '..';

export const IconFile = ({ width = 14, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 9.39571e-07C1.10218 9.39571e-07 0.720644 0.158036 0.43934 0.439341C0.158035 0.720645 0 1.10218 0 1.5V14.5C0 14.8978 0.158035 15.2794 0.43934 15.5607C0.720644 15.842 1.10218 16 1.5 16H12.5C12.8978 16 13.2794 15.842 13.5607 15.5607C13.842 15.2794 14 14.8978 14 14.5V5.621C13.9997 5.22331 13.8414 4.84204 13.56 4.561L9.44 0.439001C9.30052 0.299615 9.13494 0.189096 8.95271 0.113768C8.77048 0.0384399 8.57518 -0.000219753 8.378 9.39571e-07H1.5ZM3.75 8.5C3.55109 8.5 3.36032 8.57902 3.21967 8.71967C3.07902 8.86032 3 9.05109 3 9.25C3 9.44891 3.07902 9.63968 3.21967 9.78033C3.36032 9.92098 3.55109 10 3.75 10H10.25C10.4489 10 10.6397 9.92098 10.7803 9.78033C10.921 9.63968 11 9.44891 11 9.25C11 9.05109 10.921 8.86032 10.7803 8.71967C10.6397 8.57902 10.4489 8.5 10.25 8.5H3.75ZM3.75 11.5C3.55109 11.5 3.36032 11.579 3.21967 11.7197C3.07902 11.8603 3 12.0511 3 12.25C3 12.4489 3.07902 12.6397 3.21967 12.7803C3.36032 12.921 3.55109 13 3.75 13H10.25C10.4489 13 10.6397 12.921 10.7803 12.7803C10.921 12.6397 11 12.4489 11 12.25C11 12.0511 10.921 11.8603 10.7803 11.7197C10.6397 11.579 10.4489 11.5 10.25 11.5H3.75Z'
        fill={color}
      />
    </svg>
  );
};
