import { IonCol, IonGrid, IonRow } from '@ionic/react';

// Utils
import styles from './DashboardAppInfoTable.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Components
import { SimpleTable } from '../SimpleTable/SimpleTable';
import { SimpleColumn } from '../SimpleTable/SimpleColumn';

// Hooks
import { useAuthContext } from '../authContext';

export const DashboardAppInfoTable = () => {
  const user = useAuthContext().state.userState?.user;
  const role = useAuthContext().state.userState?.role;

  return import.meta.env.MODE === 'development' ? (
    <>
      <IonGrid className='ion-no-padding'>
        <IonRow className={mergeClassNames('ion-no-padding', styles.dashboard_DevTable)}>
          <IonCol>
            <SimpleTable>
              <IonRow>
                <SimpleColumn type='key'>User</SimpleColumn>
                <SimpleColumn type='value'>{user?.displayName}</SimpleColumn>
              </IonRow>
              <IonRow>
                <SimpleColumn type='key'>UID</SimpleColumn>
                <SimpleColumn type='value'>{user?.uid}</SimpleColumn>
              </IonRow>
              <IonRow>
                <SimpleColumn type='key'>Permissions</SimpleColumn>
                <SimpleColumn type='value'>{role}</SimpleColumn>
              </IonRow>
            </SimpleTable>
          </IonCol>
          <IonCol>
            <SimpleTable>
              <IonRow>
                <SimpleColumn type='key'>Node environment</SimpleColumn>
                <SimpleColumn type='value'>{import.meta.env.MODE}</SimpleColumn>
              </IonRow>
              <IonRow>
                <SimpleColumn type='key'>Project ID</SimpleColumn>
                <SimpleColumn type='value'>{import.meta.env.VITE_FIREBASE_PROJECT_ID}</SimpleColumn>
              </IonRow>
            </SimpleTable>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  ) : null;
};
