// ActionSheet.tsx
import { IonActionSheet, IonContent, IonModal } from '@ionic/react';
import { closeOutline, cloudDownloadOutline, trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { ReportInfo } from '../../model';
import { PDFDownloadModalContent } from '../PDFDownload/PDFDownloadModal';

import styles from './ActionSheet.module.css';

interface ActionSheetProps {
  isOpen: boolean;
  onClose: () => void;
  reportInfo: ReportInfo;
  doDelete: (id: string) => void;
  showDeleteError: (error: boolean) => void;
  showPDFError: (error: boolean) => void;
}

export const ActionSheet: React.FC<ActionSheetProps> = ({
  isOpen,
  onClose,
  reportInfo,
  doDelete,
  showDeleteError,
  showPDFError,
}) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const handleDelete = () => {
    if (reportInfo.id) {
      doDelete(reportInfo.id);
    } else {
      showDeleteError(true);
    }
  };

  return (
    <>
      <IonActionSheet
        isOpen={isOpen}
        onDidDismiss={onClose}
        buttons={[
          {
            text: 'Generate PDF',
            icon: cloudDownloadOutline,
            handler: () => {
              setShowDownloadModal(true);
            },
          },
          {
            text: 'Delete',
            role: 'destructive',
            icon: trashOutline,
            handler: handleDelete,
          },
          {
            text: 'Cancel',
            icon: closeOutline,
            role: 'cancel',
          },
        ]}
      />

      <IonModal isOpen={showDownloadModal} onDidDismiss={() => setShowDownloadModal(false)} className={styles.modal}>
        <IonContent className=''>
          <PDFDownloadModalContent
            PDFError={showPDFError}
            reportInfo={reportInfo}
            onClose={() => setShowDownloadModal(false)}
          />
        </IonContent>
      </IonModal>
    </>
  );
};

export default ActionSheet;
