import { IonFooter } from '@ionic/react';

// Styles
import styles from './ModalFooter.module.css';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';

type ModalFooterProps = ComponentProps<typeof IonFooter>;

export const ModalFooter = ({ children, ...props }: PropsWithChildren<ModalFooterProps>) => {
  return (
    <IonFooter className={styles.footer} {...props}>
      {children}
    </IonFooter>
  );
};
