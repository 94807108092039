import { IonCardContent, IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import { useState } from 'react';

// Components
import { Card } from '../../../../components/Card';
import { CardHeader } from '../../../../components/CardHeader/cardHeader';
import { ReportModal } from '../../../../components/Modal/ReportModal';
import { NameField, VarusValgusField } from '../Fields/Fields';

// Images

// Hooks & Models

// Styles
import styles from './Cards.module.css';
import { mergeClassNames } from '../../../../utilities/mergeClassNames';

type AttractorCardProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  img: string;
  labels?: string[];
  attractorRow: number[];
  kpiLabels?: string[];
  varusValgus?: string[];
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
export const AttractorCard = ({
  title,
  subtitle,
  info,
  img,
  labels,
  kpiLabels,
  attractorRow,
  varusValgus,
}: AttractorCardProps) => {
  const kpiLabel = (labels: string[], kpi: number) => {
    const label = Number.isInteger(kpi) ? labels[kpi - 1] : labels[Math.round(kpi) - 1];
    return label;
  };
  const [showModal, setShowModal] = useState({
    show: false,
  });

  return (
    <>
      <ReportModal
        isOpen={showModal.show}
        onDidDismiss={() => setShowModal({ show: false })}
        header={subtitle ? `${title} - ${subtitle}` : title}
        setShowInfo={() => setShowModal({ show: false })}
      >
        {info}
      </ReportModal>

      <Card style={{ height: 'fit-content', margin: 0 }}>
        <CardHeader title={title} info={true} subtitle={subtitle} setShowInfo={setShowModal} />

        <IonCardContent className={styles.full_height}>
          <IonGrid class='ion-no-padding' fixed>
            <IonRow className={mergeClassNames(styles.full_width, 'ion-justify-content-center')}>
              <IonCol>
                <IonImg src={img} class={styles.avatar} />
              </IonCol>
            </IonRow>
            {labels && (
              <IonRow className={mergeClassNames(styles.full_width, 'ion-justify-content-center')}>
                <IonCol style={{ marginRight: '4px' }}>
                  <NameField name='Left' value={labels[0]} status={attractorRow[0]} />
                </IonCol>
                <span style={{ width: '1px', backgroundColor: '#e0e0e0' }} />
                <IonCol style={{ marginLeft: '4px' }}>
                  <NameField name='Right' value={labels[1]} status={attractorRow[1]} />
                </IonCol>
              </IonRow>
            )}
            {kpiLabels && !varusValgus && (
              <IonRow className={mergeClassNames(styles.full_width, 'ion-justify-content-center')}>
                <IonCol style={{ marginRight: '4px' }}>
                  <NameField name='Left' value={kpiLabel(kpiLabels, attractorRow[0])} status={attractorRow[0]} />
                </IonCol>
                <span style={{ width: '1px', backgroundColor: '#e0e0e0' }} />
                <IonCol style={{ marginLeft: '4px' }}>
                  <NameField name='Right' value={kpiLabel(kpiLabels, attractorRow[1])} status={attractorRow[1]} />
                </IonCol>
              </IonRow>
            )}
            {varusValgus && kpiLabels && (
              <IonRow className={mergeClassNames(styles.full_width, 'ion-justify-content-center')}>
                <IonCol style={{ marginRight: '4px' }}>
                  <VarusValgusField
                    name='Left'
                    value={kpiLabel(kpiLabels, attractorRow[0])}
                    label={varusValgus[0]}
                    status={attractorRow[0]}
                  />
                </IonCol>
                <span style={{ width: '1px', backgroundColor: '#e0e0e0' }} />
                <IonCol style={{ marginLeft: '4px' }}>
                  <VarusValgusField
                    name='Right'
                    value={kpiLabel(kpiLabels, attractorRow[1])}
                    label={varusValgus[1]}
                    status={attractorRow[1]}
                  />
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </IonCardContent>
      </Card>
    </>
  );
};
