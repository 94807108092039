import { IconProps } from '..';

export const IconEducation = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.80012 1.86978C7.8632 1.84224 7.93129 1.82803 8.00012 1.82803C8.06895 1.82803 8.13704 1.84224 8.20012 1.86978C10.6655 2.94738 13.0172 4.26837 15.2201 5.81311C15.2978 5.86755 15.3583 5.94295 15.3947 6.03051C15.4311 6.11806 15.4419 6.21417 15.4257 6.3076C15.4096 6.40104 15.3672 6.48797 15.3035 6.55823C15.2399 6.62849 15.1575 6.6792 15.0661 6.70444C12.779 7.3361 10.566 8.2104 8.46479 9.31244L8.46279 9.31378C8.38679 9.35378 8.31146 9.39311 8.23612 9.43378C8.16361 9.47253 8.08267 9.4928 8.00046 9.4928C7.91824 9.4928 7.8373 9.47253 7.76479 9.43378C6.86356 8.95281 5.94106 8.51281 5.00012 8.11511V7.96578C5.00012 7.87844 5.04479 7.80044 5.11479 7.75844C6.12104 7.14865 7.15625 6.58799 8.21679 6.07844C8.33631 6.02098 8.42811 5.91839 8.472 5.79324C8.51588 5.66809 8.50825 5.53063 8.45079 5.41111C8.39333 5.29158 8.29073 5.19978 8.16559 5.1559C8.04044 5.11202 7.90298 5.11965 7.78346 5.17711C6.69398 5.70063 5.63051 6.27664 4.59679 6.90311C4.44972 6.99233 4.32285 7.1112 4.22426 7.25215C4.12566 7.3931 4.0575 7.55303 4.02412 7.72178C3.01159 7.33217 1.98058 6.99243 0.934789 6.70378C0.843386 6.67854 0.761044 6.62783 0.69737 6.55756C0.633696 6.4873 0.591315 6.40038 0.575171 6.30694C0.559027 6.2135 0.569786 6.1174 0.606193 6.02984C0.6426 5.94229 0.703153 5.86689 0.780789 5.81244C2.98358 4.26798 5.33501 2.94722 7.80012 1.86978Z'
        fill={color}
      />
      <path
        d='M8.70647 10.3153C10.344 9.44049 12.0539 8.70845 13.8171 8.12735C13.9065 9.07001 13.9638 10.0227 13.9871 10.9833C13.9897 11.0841 13.9618 11.1832 13.9071 11.2678C13.8524 11.3523 13.7734 11.4184 13.6805 11.4573C11.7907 12.2466 9.98119 13.216 8.27714 14.352C8.19501 14.4068 8.09851 14.436 7.9998 14.436C7.9011 14.436 7.8046 14.4068 7.72247 14.352C6.01863 13.216 4.20939 12.2467 2.3198 11.4573C2.22687 11.4186 2.14781 11.3526 2.09297 11.2682C2.03814 11.1837 2.01008 11.0847 2.01247 10.984C2.0358 10.0227 2.09314 9.07068 2.18247 8.12668C2.79647 8.32935 3.40247 8.54935 3.9998 8.78668V9.63335C3.85576 9.71634 3.73468 9.83393 3.64751 9.97549C3.56035 10.117 3.50985 10.2781 3.50058 10.4441C3.49132 10.6101 3.52358 10.7757 3.59445 10.9261C3.66532 11.0765 3.77256 11.2068 3.90647 11.3053C3.84647 11.5587 3.75847 11.8073 3.6418 12.0453C3.94314 12.1873 4.24247 12.3347 4.53914 12.4853C4.70636 12.1452 4.82979 11.7852 4.90647 11.414C5.07506 11.3391 5.21963 11.2189 5.32417 11.0669C5.42871 10.9149 5.48916 10.7369 5.49882 10.5526C5.50848 10.3684 5.46698 10.185 5.37891 10.0229C5.29084 9.86079 5.15963 9.72619 4.9998 9.63401V9.20401C5.77854 9.54421 6.54358 9.91494 7.29314 10.3153C7.51058 10.4315 7.75329 10.4922 7.9998 10.4922C8.24632 10.4922 8.48903 10.4315 8.70647 10.3153Z'
        fill={color}
      />
      <path
        d='M2.97484 12.9745C3.25484 12.6952 3.47684 12.3812 3.6415 12.0445C3.9435 12.1872 4.24284 12.3345 4.5395 12.4858C4.32137 12.9289 4.03171 13.333 3.68217 13.6818C3.58739 13.7701 3.46202 13.8182 3.33249 13.8159C3.20295 13.8137 3.07936 13.7612 2.98776 13.6696C2.89615 13.578 2.84367 13.4544 2.84139 13.3248C2.8391 13.1953 2.88718 13.0699 2.9755 12.9752L2.97484 12.9745Z'
        fill={color}
      />
    </svg>
  );
};
