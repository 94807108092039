import { ComponentProps, useMemo } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';

// Components
import { ReportCard } from './ReportCard';

// Types
import { ReportInfoDict } from '../../model';

// Styles
import styles from './index.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';
import { sortReportInfosDict } from '../../utilities';
import { isDevelopment } from '../../utilities/environment';

interface ReportCardGridProps extends Omit<ComponentProps<typeof IonGrid>, 'class'> {
  data: ReportInfoDict;
}

export const ReportCardGrid = ({ data, className, ...props }: ReportCardGridProps) => {
  // Show  4 or 6 reportCards depending on the environment
  const limit = isDevelopment ? 4 : 6;

  const list = useMemo(() => {
    const sortedList = sortReportInfosDict(data);
    // Limit the number of items based on the environment
    const limitedList = Object.fromEntries(Object.entries(sortedList).slice(0, limit));
    return limitedList;
  }, [data, limit]);

  return (
    <IonGrid {...props} className={mergeClassNames(styles.grid, className)}>
      <IonRow className={styles.row}>
        {Object.keys(list).map((key) => (
          <IonCol size='12' sizeXl='4' sizeLg='6' key={key} className={styles.col}>
            <ReportCard
              id={list[key].id ?? ''}
              name={list[key].reportName}
              date={list[key].uploadTimestamp}
              majorVersion={list[key].version?.col0}
              reportType={list[key].reportType}
            />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};
