import { IonCol, IonRow, IonText } from '@ionic/react';

import styles from './DataTable.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface DataTableHeaderProps {
  titles: string[];
}

export const DataTableHeader = ({ titles }: DataTableHeaderProps) => {
  return (
    <IonRow className='ion-justify-content-end ion-align-items-center'>
      {titles.map((title, index) => (
        <IonCol key={index} className={mergeClassNames(styles.column, 'ion-text-center')}>
          <IonText className={styles.title}>{title}</IonText>
        </IonCol>
      ))}
    </IonRow>
  );
};
