import { IonRow, IonText } from '@ionic/react';
import { Spinner } from '../Spinner/Spinner';

// Style
import styles from './Loading.module.css';

interface LoadingProps {
  message?: string;
}

export const Loading = ({ message }: LoadingProps) => {
  return (
    <>
      <IonRow className='ion-justify-content-center'>
        <div>
          <Spinner />
          <IonText className={styles['sub-title']}>{message ? message : 'Loading data please wait...'}</IonText>
        </div>
      </IonRow>
    </>
  );
};
