import { IonContent, IonModal } from '@ionic/react';

// Styles
import styles from './Modal.module.css';

// Components
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { ModalFooter } from '../ModalFooter/ModalFooter';

// Types
import type { ComponentProps, PropsWithChildren, ReactNode, Ref } from 'react';

interface ModalProps extends ComponentProps<typeof IonModal> {
  ref?: Ref<HTMLIonModalElement>;
  trigger?: string;
  header?: ReactNode;
  footer?: ReactNode;
}

/**
 * A reusable modal component that leverages the IonModal component from Ionic.
 * It includes a header, content area, and footer.
 *
 * @param {React.ReactNode} children - The content to be displayed inside the modal.
 * @param {React.Ref<HTMLIonModalElement>} ref - A reference to the IonModal element.
 * @param {React.ReactNode} header - The content to be displayed in the modal header.
 * @param {React.ReactNode} footer - The content to be displayed in the modal footer.
 * @param {ModalProps} props - Additional properties to be passed to the IonModal component.
 *
 * @returns {JSX.Element} The rendered modal component.
 *
 * @example
 * ```tsx
 * <Modal isOpen={isOpen} onDidDismiss={handleDismiss} header="Modal title">
 *  <p>Modal content</p>
 * </Modal>
 */
export const Modal = ({ children, ref, header, footer, ...props }: PropsWithChildren<ModalProps>) => {
  return (
    <IonModal className={styles.modal} {...props} ref={ref}>
      <ModalHeader>{header ?? 'Modal title'}</ModalHeader>
      <IonContent className={styles.content}>
        <div className={styles.inner}>{children}</div>
      </IonContent>
      <ModalFooter>{footer}</ModalFooter>
    </IonModal>
  );
};
