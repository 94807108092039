import { IonCol, IonText } from '@ionic/react';
import { ComponentProps, PropsWithChildren } from 'react';

// Styles
import styles from './DataTable.module.css';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface DataTableColumnProps extends ComponentProps<typeof IonCol> {
  type?: 'key' | 'value';
  label?: string;
  indent?: boolean;
  subHeader?: string;
}

export const DataTableColumn = ({
  children,
  type = 'value',
  label,
  indent = false,
  subHeader,
}: PropsWithChildren<DataTableColumnProps>) => {
  return (
    <IonCol className={mergeClassNames(styles.column, styles[type], indent && styles.indent)}>
      <IonText className={label && styles[label]}>{children}</IonText>
      {subHeader && (
        <>
          <br />
          <IonText className={styles.subHeader}>{subHeader}</IonText>
        </>
      )}
    </IonCol>
  );
};
