import { IonContent, IonPage, IonRow, IonText } from '@ionic/react';

// Components
import { QuickLinkGrid } from '../components/QuickLinkGrid';
import { SectionHeader } from '../components/SectionHeader';
import { ReportCardGrid } from '../components/ReportCardGrid';
import { DashboardAppInfoTable } from '../components/DashboardAppInfoTable/DashboardAppInfoTable';

// Hooks
import { useReportContext } from '../components/reportContext';
import { useUserInfoContext } from '../components/userInfoContext';

// Styles
import styles from './Dashboard.module.css';
import { Spinner } from '../components/Spinner/Spinner';

export const Dashboard: React.FC = () => {
  const { userInfo } = useUserInfoContext();
  const { reportInfoDict, isLoading } = useReportContext();

  return (
    <IonPage id='main'>
      <IonContent className={styles.content} id='main' color='light'>
        <SectionHeader
          title={`Welcome back, ${userInfo?.firstName}`}
          // subTitle='Tempor do adipisicing anim nulla excepteur.'
        />

        <QuickLinkGrid />

        {isLoading ? (
          <>
            <IonRow className='ion-justify-content-center'>
              <div>
                <Spinner />
                <IonText className={styles['sub-title']}>Loading data please wait...</IonText>
              </div>
            </IonRow>
          </>
        ) : (
          <ReportCardGrid data={reportInfoDict} />
        )}

        <DashboardAppInfoTable />
      </IonContent>
    </IonPage>
  );
};
