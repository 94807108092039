import { StyleSheet } from '@react-pdf/renderer';
import { primaryColor } from '../../../theme/colors';

export const PDFTextStyles = StyleSheet.create({
  containerTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: primaryColor,
    marginBottom: 10,
  },
  title: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    color: '#0A1414',
  },
  subtitle: {
    fontSize: 6,
    fontFamily: 'Montserrat',
    paddingBottom: 10,
    color: '#767676',
  },
});
