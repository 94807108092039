// Ionic imports
import { IonCol, IonImg, IonRow } from '@ionic/react';

// Custom imports
import { MappedSegmentImages } from './SensorImages';
import { SensorBatteryInd } from './SensorBatteryInd';
import './Sensor.css';

interface SensorProps {
  sensorOrientation: number;
  batteryLevel: number;
  charging: boolean;
  mappedSegment: string;
}
export const Sensor = ({ sensorOrientation, batteryLevel, charging, mappedSegment }: SensorProps) => {
  return (
    <IonRow className='ion-no-padding'>
      {batteryLevel !== 0 && (
        <IonCol size='6' className='content_center_col ion-justify-content-center Test'>
          <SensorBatteryInd batteryLevel={batteryLevel} charging={charging} />
        </IonCol>
      )}
      <IonCol size='6' className='animate-sensor-pop-in'>
        <IonImg
          src={MappedSegmentImages[mappedSegment]}
          className='sensorImage'
          style={{ transform: `rotate(${sensorOrientation}deg)` }}
        />
      </IonCol>
    </IonRow>
  );
};

interface ScannedSensorProps {
  mappedSegment: string;
}

export const ScannedSensor = ({ mappedSegment }: ScannedSensorProps) => {
  return (
    <IonCol size='3' className='animate-sensor-pop-in ion-no-padding'>
      <IonImg src={MappedSegmentImages[mappedSegment]} className='scannedSensorImage' />
    </IonCol>
  );
};
