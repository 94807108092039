import { IonContent, IonPopover } from '@ionic/react';

// Styles
import styles from './Popover.module.css';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';

interface PopoverProps extends ComponentProps<typeof IonPopover> {
  className?: string;
  trigger: string;
}

export const Popover = ({ children, className, ...props }: PropsWithChildren<PopoverProps>) => (
  <IonPopover className={mergeClassNames(styles.popover, className)} {...props}>
    <IonContent>{children}</IonContent>
  </IonPopover>
);
