import React from 'react';

import { View, Text, Image } from '@react-pdf/renderer';
import { AttractorField, VarusValgusField } from './PDFFields';
import hipExtenstionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';
import { PDFFormatting, PDFTextStyles } from '../styles';

type AttractorLabels = string[][];

type AttractorCardProps = {
  title: string;
  subtitle?: string;
  img: string;
  attractorRow: number[];
  kpiLabels: string[];
  varusValgus?: string[];
};

/** Report card for kpi data
 * @param img - The avatar
 * @param kpiRow - Tuple of (Left, Right) kpi values
 * @param labels - rows of kpi labels, row 0 is Left, row 1 is Right
 */
const AttractorCard = ({ title, subtitle, img, attractorRow, kpiLabels, varusValgus }: AttractorCardProps) => {
  const kpiLabel = (labels: string[], kpi: number) => {
    return labels[kpi - 1];
  };

  return (
    <View style={PDFFormatting.attractorCardContainer} debug={false}>
      <Text style={PDFTextStyles.title}>{title}</Text>
      <Text style={PDFTextStyles.subtitle}>{subtitle}</Text>
      <Image style={PDFFormatting.attractorCardAvatar} src={img} />
      <View style={PDFFormatting.cardResultContainer}>
        {kpiLabels && !varusValgus && (
          <>
            <AttractorField name={'Left'} value={kpiLabel(kpiLabels, Math.round(attractorRow[0]))} />
            <AttractorField name={'Right'} value={kpiLabel(kpiLabels, Math.round(attractorRow[1]))} />
          </>
        )}
        {varusValgus && kpiLabels && (
          <>
            <VarusValgusField
              name={'Left'}
              value={kpiLabel(kpiLabels, Math.round(attractorRow[0]))}
              label={varusValgus[0]}
            />
            <VarusValgusField
              name={'Right'}
              value={kpiLabel(kpiLabels, Math.round(attractorRow[1]))}
              label={varusValgus[1]}
            />
          </>
        )}
      </View>
    </View>
  );
};

type WalkAnalysisCardProps = {
  kpiLabels: string[][];
  kpiData: number[][];
  romData: number[][];
};

export const WalkAnalysisCards = ({ kpiLabels, kpiData, romData }: WalkAnalysisCardProps) => {
  const varusValgusLabel_Left = romData[6][0] < 0 ? 'Valgus' : 'Varus';
  const varusValgusLabel_Right = romData[6][1] < 0 ? 'Valgus' : 'Varus';
  return (
    <>
      <View style={PDFFormatting.KpiCardscontainer}>
        <AttractorCard
          title={'Hip extension'}
          subtitle={'Terminal stance'}
          img={hipExtenstionAtTerminalStance}
          kpiLabels={kpiLabels[0]}
          attractorRow={kpiData[1]}
        />
        <AttractorCard
          title={'Knee extension'}
          subtitle={'Terminal stance'}
          img={kneeExtensionAtTerminalStance}
          kpiLabels={kpiLabels[2]}
          attractorRow={kpiData[0]}
        />
        <AttractorCard
          title={'Knee flexion'}
          subtitle={'Midswing'}
          img={kneeFlexionAtMidswing}
          kpiLabels={kpiLabels[0]}
          attractorRow={kpiData[6]}
        />
        <AttractorCard
          title={'Hip extension'}
          subtitle={'Terminal swing'}
          img={hipExtensionAtTerminalSwing}
          kpiLabels={kpiLabels[0]}
          attractorRow={kpiData[2]}
        />
      </View>
      <View style={PDFFormatting.KpiCardscontainer}>
        <AttractorCard
          title={'Knee stability'}
          subtitle={'Loading response'}
          img={kneeStabilityAtLoadingResponse}
          kpiLabels={kpiLabels[0]}
          attractorRow={kpiData[3]}
        />
        <AttractorCard
          title={'Knee flexion'}
          subtitle={'Midstance'}
          img={kneeFlexionAtMidstance}
          kpiLabels={kpiLabels[0]}
          attractorRow={kpiData[5]}
        />
        <AttractorCard
          title={'Varus / Valgus'}
          subtitle={'Midstance'}
          img={varusValgus}
          kpiLabels={kpiLabels[0]}
          attractorRow={kpiData[7]}
          varusValgus={[varusValgusLabel_Left, varusValgusLabel_Right]}
        />
        <AttractorCard
          title={'Pelvic obliquity'}
          subtitle={'Midstance'}
          img={pelvicObliquity}
          kpiLabels={kpiLabels[1]}
          attractorRow={kpiData[4]}
        />
      </View>
    </>
  );
};
