import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimageAnklestiffness1 from './assets/IBimageAnklestiffness1.png';
import IBimageAnklestiffness2 from './assets/IBimageAnklestiffness2.png';
import IBimageAnklestiffness3 from './assets/IBimageAnklestiffness3.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/Swiper';

// Styles
import styles from './ModalContent.module.css';

export const AnkleStiffnessModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageAnklestiffness1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  Throughout the entire stance phase, the ankle should remain in a relatively neutral position before
                  going into plantar flexion at pre-swing. This can only be established when the ankle is ‘stiff’
                  enough.
                  <br></br>
                  <br></br>
                  After landing (initial contact) the <i>m. soleus</i> processes the counter acting forces of the
                  landing and stores the released kinetic energy elastically (stretch).
                  <br></br>
                  <br></br>
                  In preparation to take off for floating phase this energy is returned to the ankle via the Achilles
                  tendon. At the same time the <i>m. gastrocnemius</i> transports energy released from knee extension to
                  the ankle using the same route.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <IonGrid>
                  <IonRow>
                    <div>
                      <IonImg src={IBimageAnklestiffness2} class={styles.avatar_small} />
                    </div>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div>
                        The pennate angle of the <i>m. soleus</i> is larger than the pennate angle of the{' '}
                        <i>m. gastrocnemius</i>. Both pull the same Achilles tendon, which requires a synchronized
                        timing of activation and force sharing of <i>m. soleus</i> and <i>m/ gastrocnemius</i> to
                        prevent shear forces.
                      </div>
                    </IonCol>
                    <IonCol>
                      <div>
                        <IonImg src={IBimageAnklestiffness3} class={styles.avatar_small} />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  The <i>m. triceps</i> surae has two possible ways to process sudden acting ground reaction forces.
                  Either the muscle fibers are active within their optimal length (keeping the ankle in neutral position
                  before hitting into plantar flexion in pre-swing), or the ankle sinks in deep dorsal flexion,
                  lengthening the soleus fibers far from optimum length. A deep dorsal flexion after midstance is
                  discouraged because:
                  <ul>
                    <li>
                      stored kinetic energy at landing can not be re-used properly (the spring mass mechanism will not
                      work)
                    </li>
                    <li>
                      stored energy will be converted into warmth, which can have a damaging effect on tendon quality
                    </li>
                    <li>ground contact time will be longer</li>
                    <li>
                      the tibiotalar joint locks due to the wedge-shaped talus, limiting endo- and exo-rotation of the
                      lower leg, which is an important correctional mechanism
                    </li>
                    <li>it hampers the possibility of a good hip lock.</li>
                  </ul>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
