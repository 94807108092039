import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import calendaroutline from './Assets/calendar-outline.png';
import pricetagoutline from './Assets/pricetag-outline.png';
import { ReportInfo, ReportType } from '../../../model';
import Runner from '../../../assets/icons/Running.png';
import Walker from '../../../assets/icons/Walking.png';
import Squatter from '../../../assets/icons/Squat.png';
import Slsquatter from '../../../assets/icons/SingleLegSquat.png';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#E2E2E2',
    borderRadius: 4,
    borderStyle: 'solid',
    alignItems: 'stretch',
    width: '500px',
    padding: 20,
    marginHorizontal: 50,
  },
  csvNameRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E2E2E2',
    padding: 10,
    width: '500px',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  csvName: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  Text: {
    fontSize: 12,
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
  },
  detailRow: {
    flexDirection: 'row',
    padding: 10,
    width: '500px',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  iconColumn: {
    flexDirection: 'column',
    width: '20px',
    marginRight: '20px',
  },
});

export interface IReportCardProps {
  reportInfo: ReportInfo;
}

const getActivity = (reportType: ReportType | undefined) => {
  switch (reportType) {
    case 1:
      return 'Walk';
    case 2:
      return 'Run';
    case 3:
      return 'Squat';
    case 4:
      return 'Single Leg Squat';
    default:
      return 'Walk';
  }
};
export const PDFReportCard = ({ reportInfo }: IReportCardProps) => {
  const activity = getActivity(reportInfo.reportType);
  const activityImages = {
    Walk: Walker,
    Run: Runner,
    Squat: Squatter,
    'Single Leg Squat': Slsquatter,
  };

  const validTags = reportInfo.tags.filter((tag) => tag && tag.trim() !== '');

  return (
    <View style={styles.container}>
      <View style={styles.csvNameRow}>
        <Text style={styles.Text}>{reportInfo.reportName}</Text>
      </View>
      <View style={styles.detailRow}>
        <Image src={calendaroutline} style={styles.iconColumn} />
        <Text style={styles.Text}>{reportInfo.uploadTimestamp.toLocaleString()}</Text>
      </View>
      <View style={styles.detailRow}>
        <Image src={activityImages[activity]} style={styles.iconColumn} />
        <Text style={styles.Text}>Activity: {activity}</Text>
      </View>
      {reportInfo.tags[0] != '' && (
        <View style={styles.detailRow}>
          <Image src={pricetagoutline} style={styles.iconColumn} />
          <Text style={styles.Text}>{validTags.join(' - ')}</Text>
        </View>
      )}
    </View>
  );
};
