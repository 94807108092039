import { IconProps } from '..';

export const IconTest = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.99994 2.532V5.87867C6.99983 6.40906 6.78904 6.91769 6.41394 7.29267L4.82928 8.87734C6.00065 8.79478 7.17273 9.02799 8.22328 9.55267C9.40205 10.1423 10.7524 10.2926 12.0319 9.97667L12.2219 9.92934L9.58594 7.29267C9.21084 6.91769 9.00006 6.40906 8.99994 5.87867V2.532C8.33395 2.48961 7.66594 2.48961 6.99994 2.532ZM9.99994 2.624C10.128 2.62517 10.2515 2.57718 10.3452 2.48994C10.4389 2.4027 10.4956 2.28284 10.5035 2.15507C10.5115 2.0273 10.4701 1.90134 10.3879 1.80316C10.3058 1.70498 10.1891 1.64205 10.0619 1.62734C9.37793 1.54201 8.68926 1.49949 7.99994 1.5C7.31064 1.49985 6.62199 1.54238 5.93794 1.62734C5.81077 1.64205 5.69409 1.70498 5.61194 1.80316C5.52978 1.90134 5.48842 2.0273 5.49637 2.15507C5.50432 2.28284 5.56097 2.4027 5.65466 2.48994C5.74835 2.57718 5.87193 2.62517 5.99994 2.624V5.87867C5.99971 6.1438 5.8942 6.39798 5.70661 6.58534L1.51128 10.7813C0.431277 11.862 0.909277 13.7647 2.49194 14.0347C4.28261 14.3413 6.12328 14.5 7.99994 14.5C9.87728 14.5 11.7173 14.3407 13.5079 14.0353C15.0899 13.7647 15.5693 11.862 14.4879 10.7813L10.2933 6.586C10.2003 6.49316 10.1266 6.3829 10.0762 6.26153C10.0259 6.14016 9.99997 6.01006 9.99994 5.87867V2.624Z'
        fill={color}
      />
    </svg>
  );
};
