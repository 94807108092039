/** Walk analysis report tab */
import { IonCol, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { useState } from 'react';

// Components
import { MobilityStatusCard } from '../components/Cards';
import { Card } from '../../../components/Card';
import { CardHeader } from '../../../components/CardHeader/cardHeader';

// Hooks
import { MobilityStatusData } from '../../../model/reportV2.model';

// Images
import ankle from '../../../assets/avatars/walk/analysis/Ankle.png';
import hip from '../../../assets/avatars/walk/analysis/Hip.png';
import knee from '../../../assets/avatars/walk/analysis/Knee.png';
import pelvis from '../../../assets/avatars/walk/analysis/Pelvis.png';

import {
  AnkleMobilityStatusModalContent,
  HipMobilityStatusModalContent,
  KneeMobilityStatusModalContent,
  PelvisMobilityStatusModalContent,
} from '../components/Modals';

// Styles
import styles from '../Reports.module.css';

type WalkReportProps = {
  tabData: MobilityStatusData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkMobilityReportTab = ({ tabData }: WalkReportProps) => {
  const [showModal, setShowModal] = useState({
    show: false,
  });

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow style={{ height: '100%', padding: 0, alignContent: 'space-evenly' }}>
          <Card style={{ height: 'fit-content', marginLeft: 0, marginRight: 0 }}>
            <CardHeader
              title={
                <>
                  Stride Duration: {tabData.tableData[0][0].toFixed(2)}s.
                  <span
                    className={
                      tabData.tableData[0][4] === 1
                        ? styles.qualityLabel_succes
                        : tabData.tableData[0][4] === 2
                          ? styles.qualityLabel_danger
                          : styles.qualityLabel_warning
                    }
                    id='showSpeedLabel'
                    style={{ marginLeft: '10px' }}
                  >
                    {' '}
                    {tabData.speedLabel}{' '}
                  </span>
                </>
              }
            />
          </Card>

          <IonCol size='3'>
            <MobilityStatusCard
              title={'Pelvis'}
              info={<PelvisMobilityStatusModalContent />}
              img={pelvis}
              romRow={tabData.romData[0]}
              statusRow={tabData.statusData[0]}
            />
          </IonCol>
          <IonCol size='3'>
            <MobilityStatusCard
              title={'Hip'}
              info={<HipMobilityStatusModalContent />}
              img={hip}
              romRow={tabData.romData[1]}
              statusRow={tabData.statusData[1]}
            />
          </IonCol>
          <IonCol size='3'>
            <MobilityStatusCard
              title={'Knee'}
              info={<KneeMobilityStatusModalContent />}
              img={knee}
              romRow={tabData.romData[4]}
              statusRow={tabData.statusData[2]}
            />
          </IonCol>
          <IonCol size='3'>
            <MobilityStatusCard
              title={'Ankle'}
              info={<AnkleMobilityStatusModalContent />}
              img={ankle}
              romRow={tabData.romData[7]}
              statusRow={tabData.statusData[3]}
            />
          </IonCol>
        </IonRow>
      </>
    );
  }
};
