/** Walk analysis report tab */
import { IonCol, IonRow, IonText } from '@ionic/react';

// Components
import { AttractorCard } from '../../ReportV2/components/Cards';
import {
  HipExtensionTerminalStanceModalContent,
  HipExtensionTerminalSwingModalContent,
  KneeExtensionTerminalStanceModalContent,
  KneeFlexionMidstanceModalContent,
  KneeFlexionMidswingModalContent,
  KneeStabilityLoadingResponseModalContent,
  PelvicObliquityModalContent,
  VarusValgusModalContent,
} from '../components/Modals';

// Images
import hipExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';
import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';

// Hooks & Models
import { AnalysisData } from '../../../model/reportV2.model';

// Styles

type WalkReportProps = {
  tabData: AnalysisData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkAnalysisReportTab = ({ tabData }: WalkReportProps) => {
  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Hip extension'
              subtitle='Terminal stance'
              img={hipExtensionAtTerminalStance}
              kpiLabels={tabData.kpiLabels[0]}
              attractorRow={tabData.kpiData[1]}
              info={<HipExtensionTerminalStanceModalContent />}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Knee extension'
              subtitle='Terminal stance'
              img={kneeExtensionAtTerminalStance}
              kpiLabels={tabData.kpiLabels[2]}
              attractorRow={tabData.kpiData[0]}
              info={<KneeExtensionTerminalStanceModalContent />}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Knee flexion'
              subtitle='Midswing'
              img={kneeFlexionAtMidswing}
              kpiLabels={tabData.kpiLabels[0]}
              attractorRow={tabData.kpiData[6]}
              info={<KneeFlexionMidswingModalContent />}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Hip extension'
              subtitle='Terminal swing'
              img={hipExtensionAtTerminalSwing}
              kpiLabels={tabData.kpiLabels[0]}
              attractorRow={tabData.kpiData[2]}
              info={<HipExtensionTerminalSwingModalContent />}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Knee stability'
              subtitle='Loading response'
              img={kneeStabilityAtLoadingResponse}
              kpiLabels={tabData.kpiLabels[0]}
              attractorRow={tabData.kpiData[3]}
              info={<KneeStabilityLoadingResponseModalContent />}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Knee flexion'
              subtitle='Midstance'
              img={kneeFlexionAtMidstance}
              kpiLabels={tabData.kpiLabels[0]}
              attractorRow={tabData.kpiData[5]}
              info={<KneeFlexionMidstanceModalContent />}
            />
          </IonCol>

          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Varus / Valgus'
              subtitle='Midstance'
              img={varusValgus}
              kpiLabels={tabData.kpiLabels[0]}
              attractorRow={tabData.kpiData[7]}
              info={<VarusValgusModalContent />}
              varusValgus={[tabData.varusValgusLabel_Left, tabData.varusValgusLabel_Right]}
            />
          </IonCol>
          <IonCol size-sm='12' size-md='6' size-lg='3'>
            <AttractorCard
              title='Pelvic obliquity'
              subtitle='Midstance'
              img={pelvicObliquity}
              kpiLabels={tabData.kpiLabels[1]}
              attractorRow={tabData.kpiData[4]}
              info={<PelvicObliquityModalContent />}
            />
          </IonCol>
        </IonRow>
      </>
    );
  }
};
