/** Squat analysis report tab */
import { IonCardContent, IonCol, IonGrid, IonIcon, IonImg, IonRow, IonText, IonThumbnail } from '@ionic/react';

// Components
import { helpCircleOutline } from 'ionicons/icons';
import { BulletList } from '../../../components/BulletList/BulletList';
import { Card } from '../../../components/Card';
import { CardHeader } from '../../../components/CardHeader/cardHeader';
import { DataTable } from '../../../components/DataTable/DataTable';
import { DataTableColumn } from '../../../components/DataTable/DataTableColumn';
import { DataTableHeader } from '../../../components/DataTable/DataTableHeader';
import { DataTableRow } from '../../../components/DataTable/DataTableRow';
import { DataTableSection } from '../../../components/DataTable/DataTableSection';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

// Hooks
import { SquatAnalysisData } from '../../../model/reportV2.model';

// Images
import doubleLegSquatAvatar from '../../../assets/avatars/squat/doubleLegSquatAvatar.png';
import hipTransversalMovement from '../../../assets/icons/kinematicsIcons/hipTransversalMovement.png';
import kneeCoronalMovement from '../../../assets/icons/kinematicsIcons/kneeCoronalMovement.png';

// Styles

type SquatReportProps = {
  tabData: SquatAnalysisData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const SquatAnalysisReportTab = ({ tabData }: SquatReportProps) => {
  const stdRepTime = tabData.repetitionData.col1[0].toFixed(2);

  const colors = ['tableLabel_succes', 'tableLabel_warning', 'tableLabel_danger', 'tableLabel_succes'];
  function getColor(index: number) {
    return colors[index % colors.length];
  }

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow style={{ height: '100%', padding: 0, alignContent: 'space-evenly' }}>
          <Card style={{ height: 'fit-content', marginLeft: 0, marginRight: 0 }}>
            <CardHeader
              title={
                <>
                  Rep Time: &nbsp; <b>{tabData.repetitionData.col0[0].toFixed(2)}</b>&nbsp; (± {stdRepTime}) sec.
                </>
              }
            />
          </Card>

          <Card style={{ marginLeft: 0, marginRight: 0 }}>
            <IonCardContent>
              <IonRow
                style={{
                  height: '100%',
                }}
                className='ion-align-items-center ion-justify-content-center ion-no-padding'
              >
                <IonCol size='5'>
                  <IonImg src={doubleLegSquatAvatar} style={{ width: '50%' }} />
                </IonCol>
                <IonCol size='7'>
                  <DataTable>
                    <DataTableHeader titles={['', 'Left', 'Right', 'Symmetry']} />

                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Pelvis</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[0][0].toFixed(1) + '°'} ± {tabData.romData[0][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[0][2].toFixed(1) + '°'} ± {tabData.romData[0][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[0][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                    </DataTableSection>
                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Hip</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[1][0].toFixed(1) + '°'} ± {tabData.romData[1][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[1][2].toFixed(1) + '°'} ± {tabData.romData[1][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[1][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          External rotation <IonIcon src={helpCircleOutline} id='trigger-hip-external-rotation' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[2][0])}>
                          {tabData.hipLabels.externalRotationLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[2][1])}>
                          {tabData.hipLabels.externalRotationLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Internal rotation <IonIcon src={helpCircleOutline} id='trigger-hip-internal-rotation' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[3][0])}>
                          {tabData.hipLabels.internalRotationLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[3][1])}>
                          {tabData.hipLabels.internalRotationLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                    </DataTableSection>

                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Knee</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[2][0].toFixed(1) + '°'} ± {tabData.romData[2][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[2][2].toFixed(1) + '°'} ± {tabData.romData[2][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[2][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Varus / Valgus <IonIcon src={helpCircleOutline} id='trigger-knee-valgus' />
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[4][0])}>
                          {tabData.varusValgusLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[4][1])}>
                          {tabData.varusValgusLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                    </DataTableSection>

                    <DataTableSection>
                      <DataTableRow>
                        <DataTableColumn type='key'>Ankle</DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[3][0].toFixed(1) + '°'} ± {tabData.romData[3][1].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>
                          {tabData.romData[3][2].toFixed(1) + '°'} ± {tabData.romData[3][3].toFixed(1) + '°'}
                        </DataTableColumn>
                        <DataTableColumn type='value'>{tabData.romData[3][4].toFixed(0) + '%'}</DataTableColumn>
                      </DataTableRow>
                      <DataTableRow>
                        <DataTableColumn type='key' indent>
                          Early heel raise
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[0][0])}>
                          {tabData.ankleLabels.earlyHeelRaiseLabel_Left}
                        </DataTableColumn>
                        <DataTableColumn type='value' label={getColor(tabData.qualityData[0][1])}>
                          {tabData.ankleLabels.earlyHeelRaiseLabel_Right}
                        </DataTableColumn>
                        <DataTableColumn />
                      </DataTableRow>
                    </DataTableSection>
                  </DataTable>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </Card>
        </IonRow>

        <Tooltip
          trigger='trigger-hip-external-rotation'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={hipTransversalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Rotation <0°: Hip endorotates',
                    'Fair: Rotation between 10° & 20°',
                    'Poor: Rotation more than 20°',
                    'Rotation <10°: Rotation is less than 10°',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
        <Tooltip
          trigger='trigger-hip-internal-rotation'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={hipTransversalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Rotation <-10°: Rotation is less than 10°',
                    'Fair: Rotation between -10° & -20°',
                    'Poor: Rotation more than -20°',
                    'Rotation >0°: Hip exorotates°',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
        <Tooltip
          trigger='trigger-knee-valgus'
          triggerAction='click'
          dismissOnSelect={true}
          side='bottom'
          alignment='center'
          reference='trigger'
        >
          <IonGrid>
            <IonRow className='ion-align-items-center'>
              <IonCol size='auto' className='ion-padding-end'>
                <IonThumbnail>
                  <IonImg src={kneeCoronalMovement} />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <BulletList
                  listItems={[
                    'Valgus <10°',
                    'Fair: Valgus between 10° and 20°',
                    'Poor: Valgus more than 20°',
                    'Varus: Knee move outward',
                  ]}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </Tooltip>
      </>
    );
  }
};
