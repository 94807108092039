/** Load (and keep listening) all reportInfo entries for a given user from the DB */
import 'firebase/compat/firestore';
import { useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  ReportId,
  ReportInfo,
  ReportInfoDict,
  ReportInfoId,
  ReportStatus,
  ReportType,
  UserId,
  Version,
} from '../../model';
import { myFirebase } from '../../config/firebaseConfig';

const db = myFirebase.firestore();

type FileInfoId = string;

type FileInfo = {
  id: FileInfoId;
  filename: string;
  tags: string[];
  reportName: string;
  uploadTimestamp: number;
};

type AreasOfConcern = {
  [key: string]: number[];
  col0: number[]; // Left
  col1: number[]; // Right
};

type FileInfoDict = {
  [id in FileInfoId]: FileInfo;
};

type ReportInfoDoc = ReportInfo & {
  id: ReportInfoId;
  reportId: ReportId | undefined;
  fileId: FileInfoId;
  status: ReportStatus;
  errorMessage: string | undefined;
  reportType: ReportType;
  version: Version;
  areasOfConcern: AreasOfConcern;
};

export type UseReportInfoHook = {
  reportInfoDict: ReportInfoDict;
  isLoading: boolean;
  isError: Error | undefined;
  clearError: () => void;
};

export const useReportInfo = (userId: UserId): UseReportInfoHook => {
  const userRef = db.collection('users').doc(userId);

  const options = { idField: 'id' };
  const filesRef = userRef.collection('kinematicsFiles');
  const [fileInfoDocs, fileInfosLoading, fileInfosError] = useCollectionData<FileInfo>(filesRef, options);

  const reportInfosRef = userRef.collection('reportInfo');
  const [reportInfoDocs, reportInfosLoading, reportInfosError] = useCollectionData<ReportInfoDoc>(
    reportInfosRef,
    options,
  );

  const [isError, setIsError] = useState<Error | undefined>();

  // Called to close the error Toast
  const clearError = () => {
    setIsError(undefined);
  };

  if (fileInfosError) {
    setIsError(fileInfosError);
  } else if (reportInfosError) {
    setIsError(reportInfosError);
  }

  const convertReportInfos = (): ReportInfoDict => {
    if (!fileInfoDocs || !reportInfoDocs) {
      return {};
    }
    const fileInfoDict: FileInfoDict = fileInfoDocs.reduce((fileInfoDict: FileInfoDict, fileInfo: FileInfo) => {
      fileInfoDict[fileInfo.id] = fileInfo;
      return fileInfoDict;
    }, {});

    return reportInfoDocs.reduce((reportInfoDict: ReportInfoDict, reportInfoDoc: ReportInfoDoc) => {
      const fileInfo = fileInfoDict[reportInfoDoc.fileId];
      const notDeleted = reportInfoDoc.status != ReportStatus.DELETED;
      if (!fileInfo) {
        return reportInfoDict;
      }
      if (!notDeleted) {
        return reportInfoDict;
      }
      // Timestamps in the database are unix timestamps in seconds;
      // Javascript uses unix timestamps in milliseconds
      const uploadTimestamp = new Date(fileInfo.uploadTimestamp * 1000);
      reportInfoDict[reportInfoDoc.id] = {
        ...reportInfoDoc,
        tags: fileInfo.tags,
        filename: fileInfo.filename,
        reportName: fileInfo.reportName,
        uploadTimestamp: uploadTimestamp,
      };
      return reportInfoDict;
    }, {});
  };

  const reportInfoDict = useMemo(convertReportInfos, [userId, reportInfoDocs]);

  // const [reportInfoDict, setReportInfoDict] = useState(() => {
  //   // const cachedData = getCache<ReportInfoDict>(CACHE_KEYS.REPORTINFODICT_CACHE_KEY);
  //   const cachedData = sessionStorage.getItem('reportInfoDict');
  //   return cachedData ? JSON.parse(cachedData) : {};
  // });

  // useEffect(() => {
  //   if (!fileInfosLoading && !reportInfosLoading) {
  //     const newReportInfoDict = convertReportInfos();
  //     setReportInfoDict(newReportInfoDict);
  //     sessionStorage.setItem('reportInfoDict', JSON.stringify(newReportInfoDict));
  //   }
  // }, [fileInfosLoading, reportInfosLoading, fileInfoDocs, reportInfoDocs]);

  const loading = fileInfosLoading || reportInfosLoading;
  const hook: UseReportInfoHook = {
    reportInfoDict: reportInfoDict,
    isLoading: loading,
    isError: isError,
    clearError: clearError,
  };
  return useMemo(() => hook, [reportInfoDict, loading, isError]);
};
