import { IconRotate, IconSize, iconComponents } from './types';

export interface IconProps {
  color?: string;
  size?: number;
  width?: number;
  height?: IconSize;
  className?: string;
  direction?: IconRotate;
}

export type IconName = keyof typeof iconComponents;

function isIconName(name: string): name is IconName {
  return Object.keys(iconComponents).includes(name);
}

const iconElements = ({ size, direction, ...props }: IconProps) => {
  const result = {} as Record<IconName, JSX.Element>;

  for (const [name, Component] of Object.entries(iconComponents)) {
    if (isIconName(name)) {
      result[name] = (
        <Component
          {...props}
          {...(size && {
            width: size,
            height: size,
          })}
          {...(direction && {
            style: {
              transform: `rotate(${direction})`,
            },
          })}
        />
      );
    }
  }

  return result;
};

export const Icon = ({ name, ...props }: IconProps & { name: IconName }) => {
  return <>{iconElements(props)[name]}</>;
};
