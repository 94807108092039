import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimageKneeExtensionTerminalStance1 from './assets/IBimageKneeExtensionTerminalStance1.png';
import IBimageKneeExtensionTerminalStance2 from './assets/IBimageKneeExtensionTerminalStance2.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/Swiper';

// Styles
import styles from './ModalContent.module.css';

export const KneeExtensionTerminalStanceModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='12' size-lg='5' size-xl='5' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageKneeExtensionTerminalStance1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='12' size-lg='6' size-xl='6'>
                <div>
                  After midstance the bodyweight will be in front of the foot. The changing direction of ground reaction
                  forces initiate a knee-extensor moment. How large this moment (and therefore knee extension) is, to a
                  large extent depends on the amount of hip extension.
                  <br></br>
                  <br></br>
                  Knee extension at the end of terminal stance is part of the proximodistal sequence (also see: hip
                  extension at terminal stance) and is an important prerequisite initiating the push off by ankle
                  plantar flexion.
                  <br></br>
                  <br></br>
                  This will also activate the abductor muscles which stabilize the pelvis in preparation to initial
                  contact of the opposite leg when the bodyweight will be shifted laterally.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid style={{ height: '100%' }}>
            <IonRow class='ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='12' size-lg='5' size-xl='5' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageKneeExtensionTerminalStance2} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='12' size-lg='6' size-xl='6'>
                <div>
                  If the knee does not extend sufficiently, forward propulsion will not be powerful. As a result the
                  knee angle at toe off can become too low (&lt; 40°) which will negatively effect the entire leg swing.
                  <br></br>
                  <br></br>
                  Poor knee extension can be due to:
                  <ul>
                    <li>Slow walking speed</li>
                    <li>Limited knee mobility</li>
                  </ul>
                  <br></br>
                  When not extending properly, the hamstrings will not be brought to optimal length. Therefor they can
                  become tight, resulting in problems around the lower back area.
                  <br></br>
                  <br></br>
                  <strong>Additional analysis</strong>
                  <br></br>
                  Poor knee extension can lead to poor pelvic control (perturbated) and or high range of motion of
                  pelvic tilt (&gt; 10°), poor plantar flexion range at toe off (&gt; -10° - i.e. less than ten degrees
                  plantar flexion).
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};
