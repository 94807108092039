import { createKinematicYAxis, toVariabilityData } from '.';
import { Joint, GraphSeriesData, KinematicYAxis } from '../../../model/reportV2.model';
import { colorLeft, colorRight } from '../../../theme/colors';

/**
 * Calculates the mean kinematic data for a given segment.
 *
 * @param data - The input data object.
 * @param segment - The segment for which to calculate the mean kinematic data.
 * @returns An object containing the mean kinematic data for each plane.
 */

export function getMeanKinematicData(
  data: any,
  segment: Joint,
): { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } } {
  const planes = ['Sagittal', 'Coronal', 'Transversal'];

  // Mapping of segment indices for different planes
  const indicesMap: { [key: string]: { [key: string]: number[] } } = {
    Sagittal: {
      Pelvis: [0, 1],
      Hip: [2, 3],
      Knee: [6, 7],
      Ankle: [10, 11],
    },
    Coronal: {
      Pelvis: [0, 1],
      Hip: [2, 3],
      Knee: [6, 7],
      Ankle: [10, 11],
    },
    Transversal: {
      Pelvis: [0, 1],
      Hip: [2, 3],
      Knee: [6, 7],
      Ankle: [10, 11],
    },
  };

  const result: { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } } = {};

  // Iterate over each plane to extract kinematic data
  planes.forEach((plane) => {
    const indices = indicesMap[plane][segment];
    const dataKey = plane === 'Sagittal' ? 'kinematicData' : `kinematicData_${plane}`;

    const seriesDataLeft = toVariabilityData(data.reportId[dataKey][`col${indices[0]}`]);
    const seriesDataRight = toVariabilityData(data.reportId[dataKey][`col${indices[1]}`]);

    const seriesData = [
      {
        type: 'line',
        name: 'Left',
        data: seriesDataLeft,
        color: colorLeft, // Define your colorLeft variable appropriately
      },
      {
        type: 'line',
        name: 'Right',
        data: seriesDataRight,
        color: colorRight, // Define your colorRight variable appropriately
      },
    ];

    const yAxisData = createKinematicYAxis(seriesData);
    result[plane] = {
      series: seriesData,
      yAxis: yAxisData,
    };
  });
  return result;
}
