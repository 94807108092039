import { IconArm } from './icons/Arm';
import { IconBall } from './icons/Ball';
import { IconChart } from './icons/Chart';
import { IconChevron } from './icons/Chevron';
import { IconCrossCircle } from './icons/CrossCircle';
import { IconDocument } from './icons/Document';
import { IconEducation } from './icons/Education';
import { IconError } from './icons/Error';
import { IconFile } from './icons/File';
import { IconHome } from './icons/Home';
import { IconLanguage } from './icons/Language';
import { IconNewFile } from './icons/NewFile';
import { IconPerson } from './icons/Person';
import { IconSearch } from './icons/Search';
import { IconSettings } from './icons/Settings';
import { IconSpinner } from './icons/Spinner';
import { IconTest } from './icons/Test';
import { IconUpload } from './icons/Upload';
import { IconSliders } from './icons/Sliders';
import { IconPlus } from './icons/Plus';
import { IconInfo } from './icons/Info';

export enum IconRotate {
  North = '180deg',
  NorthEast = '-135deg',
  NorthWest = '135deg',
  East = '-90deg',
  South = '0deg',
  SouthEast = '-45deg',
  SouthWest = '45deg',
  West = '90deg',
}

export enum IconSize {
  extraSmall = 10,
  small = 14,
  medium = 16,
  large = 18,
  extraLarge = 20,
}

export const iconComponents = {
  chevron: IconChevron,
  arm: IconArm,
  ball: IconBall,
  chart: IconChart,
  'cross-circle': IconCrossCircle,
  document: IconDocument,
  education: IconEducation,
  error: IconError,
  file: IconFile,
  home: IconHome,
  language: IconLanguage,
  newFile: IconNewFile,
  spinner: IconSpinner,
  person: IconPerson,
  search: IconSearch,
  settings: IconSettings,
  test: IconTest,
  upload: IconUpload,
  sliders: IconSliders,
  plus: IconPlus,
  info: IconInfo,
};
