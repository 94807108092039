import { IonContent, IonPopover } from '@ionic/react';

// Styles
import styles from './Tooltip.module.css';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';

interface TooltipProps extends ComponentProps<typeof IonPopover> {
  className?: string;
  trigger: string;
}

export const Tooltip = ({ children, className, ...props }: PropsWithChildren<TooltipProps>) => (
  <IonPopover className={mergeClassNames(styles.tooltip, className)} {...props}>
    <IonContent className={styles.content}>{children}</IonContent>
  </IonPopover>
);
