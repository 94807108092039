import { IconProps } from '..';

export const IconCrossCircle = ({ width = 16, height = 16, color = 'currentColor', ...props }: IconProps) => {
  return (
    <svg {...props} width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 1.5C4.41 1.5 1.5 4.41 1.5 8C1.5 11.59 4.41 14.5 8 14.5C11.59 14.5 14.5 11.59 14.5 8C14.5 4.41 11.59 1.5 8 1.5ZM6.85333 6.14667C6.80756 6.09754 6.75236 6.05814 6.69103 6.03081C6.62969 6.00348 6.56348 5.98879 6.49635 5.98761C6.42921 5.98642 6.36253 5.99877 6.30027 6.02392C6.23801 6.04907 6.18145 6.0865 6.13398 6.13398C6.0865 6.18145 6.04907 6.23801 6.02392 6.30027C5.99877 6.36253 5.98642 6.42921 5.98761 6.49635C5.98879 6.56348 6.00348 6.62969 6.03081 6.69103C6.05814 6.75236 6.09754 6.80756 6.14667 6.85333L7.29333 8L6.14667 9.14667C6.09754 9.19244 6.05814 9.24764 6.03081 9.30897C6.00348 9.37031 5.98879 9.43652 5.98761 9.50365C5.98642 9.57079 5.99877 9.63747 6.02392 9.69973C6.04907 9.76199 6.0865 9.81855 6.13398 9.86603C6.18145 9.9135 6.23801 9.95093 6.30027 9.97608C6.36253 10.0012 6.42921 10.0136 6.49635 10.0124C6.56348 10.0112 6.62969 9.99652 6.69103 9.96919C6.75236 9.94186 6.80756 9.90246 6.85333 9.85333L8 8.70667L9.14667 9.85333C9.19244 9.90246 9.24764 9.94186 9.30897 9.96919C9.37031 9.99652 9.43652 10.0112 9.50365 10.0124C9.57079 10.0136 9.63747 10.0012 9.69973 9.97608C9.76199 9.95093 9.81855 9.9135 9.86603 9.86603C9.9135 9.81855 9.95093 9.76199 9.97608 9.69973C10.0012 9.63747 10.0136 9.57079 10.0124 9.50365C10.0112 9.43652 9.99652 9.37031 9.96919 9.30897C9.94186 9.24764 9.90246 9.19244 9.85333 9.14667L8.70667 8L9.85333 6.85333C9.90246 6.80756 9.94186 6.75236 9.96919 6.69103C9.99652 6.62969 10.0112 6.56348 10.0124 6.49635C10.0136 6.42921 10.0012 6.36253 9.97608 6.30027C9.95093 6.23801 9.9135 6.18145 9.86603 6.13398C9.81855 6.0865 9.76199 6.04907 9.69973 6.02392C9.63747 5.99877 9.57079 5.98642 9.50365 5.98761C9.43652 5.98879 9.37031 6.00348 9.30897 6.03081C9.24764 6.05814 9.19244 6.09754 9.14667 6.14667L8 7.29333L6.85333 6.14667Z'
        fill={color}
      />
    </svg>
  );
};
