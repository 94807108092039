import {
  IonCardHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import ORYX_MagnifyingGlassIcon from '../../assets/icons/ORYX_MagnifyingGlassIcon.svg';

import styles from './cardHeader.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface ORYXCardHeaderProps {
  title?: React.ReactNode;
  subtitle?: string;
  info?: boolean;
  zoom?: boolean;
  setShowInfo?: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
    }>
  >;
  setShowZoom?: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
    }>
  >;
  children?: React.ReactNode;
}

export const CardHeader = ({
  title,
  subtitle,
  info,
  setShowInfo,
  zoom,
  setShowZoom,
  children,
}: ORYXCardHeaderProps) => {
  return (
    <IonCardHeader className={styles.cardHeader}>
      <IonGrid fixed className='ion-no-padding'>
        <IonRow className={mergeClassNames('ion-align-items-center', styles['header'])}>
          <IonCol size={!zoom && !info ? '12' : '9'} className={styles['content']}>
            <IonCardTitle className={styles.title}>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className={styles.subTitle}>{subtitle}</IonCardSubtitle>}
          </IonCol>
          {zoom ||
            (info && (
              <IonCol size='3' className='ion-justify-content-center ion-text-end'>
                {zoom ||
                  (info && (
                    <IonButtons className='ion-justify-content-end'>
                      {zoom && setShowZoom && (
                        <IonButton
                          slot='icon-only'
                          fill='clear'
                          className={styles.button}
                          onClick={() => setShowZoom({ show: true })}
                        >
                          <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                        </IonButton>
                      )}
                      {info && setShowInfo && (
                        <IonButton
                          slot='icon-only'
                          fill='clear'
                          className={styles.button}
                          onClick={() => setShowInfo({ show: true })}
                        >
                          <IonIcon icon={helpCircleOutline} />
                        </IonButton>
                      )}
                    </IonButtons>
                  ))}
                {children}
              </IonCol>
            ))}
        </IonRow>
      </IonGrid>
    </IonCardHeader>
  );
};
